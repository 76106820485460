import { AxiosResponse } from "axios";
import { create } from "zustand";
import { api } from "../api-client";
import { Contract } from "../api-client/modules/common.module";

export type TimeSlotsType = {
  className: string;
  end: string;
  id: string;
  start: string;
  title: string;
  type: string;
};

export type AppointmentType = {
  id: number;
  attributes: {
    status: string;
    meetingId: string;
    meetingJoinLink: string;
    meetingStartLink: string;
    timeSlot: {
      data: {
        id: number;
        attributes: {
          startDate: string;
          endDate: string;
          serviceProvider: {
            data: {
              id: number;
              attributes: {
                type: string;
                user: {
                  data: {
                    id: number;
                    attributes: {
                      firstName: string;
                      lastName: string;
                      email: string;
                      profilePicture: string;
                    };
                  };
                };
              };
            };
          };
        };
      };
    };
  };
};

export type ServiceTypeType = {
  id: number;
  attributes: {
    name: string;
    createdAt: string;
    updatedAt: string;
  };
};

type EvaluationTemplate = {
  id: number;
  template: any;
  uiSchema: any;
  createdAt: string;
  updatedAt: string;
  type: string;
};

export type MemberService = {
  id: number;
  attributes: {
    createdAt: string;
    updatedAt: string;
    status: string;
    length: number;
    serviceProviderType: string;
    referral?: { data: MemberReferral };
    memberReport: {
      data: {
        id: number;
        attributes: {
          response: any;
          template: {
            id: number;
            data: {
              attributes: Omit<EvaluationTemplate, "id">;
            };
          };
        };
      };
    };
    serviceType: {
      data: ServiceTypeType;
    };
  };
};

export type MemberReferral = {
  id: number;
  attributes: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    dateOfBirth: string;
    spokenLanguage: string;
    createdAt: string;
    updatedAt: string;
    paymentType: string;
    referringDoctor?: string;
    serviceProviderType: string | null;
    notes?: string;
    state: {
      data: {
        id: number;
        attributes: {
          name: string;
        };
      };
    };
  };
};

type CommonState = {
  contentTemplate: any;
  clinicUser: any;
  paymentMethod: any;
  canScheduleAppointment: boolean;
  setScheduleAppointmentCheck(check: boolean): void;
  fetchContentTemplate(slug: string): Promise<AxiosResponse>;
  addPaymentMethod(): Promise<AxiosResponse>;
  fetchClinic(user: string): Promise<AxiosResponse>;
  addContract(data: Contract): Promise<AxiosResponse>;
};

export const useCommonStore = create<CommonState>()((set, get) => {
  const data = {
    appointment: [],
    appointments: [],
    contentTemplate: [],
    clinicUser: [],
    paymentMethod: [],
    canScheduleAppointment: false,

    async fetchContentTemplate(slug: string) {
      return api.common.fetchContentTemplate(slug).then((response: any) => {
        const { data } = response.data;
        set({ contentTemplate: data });
        return response;
      });
    },
    async addPaymentMethod() {
      return api.common.addPaymentMethod().then((response: any) => {
        return response;
      });
    },

    async fetchClinic(user: string) {
      return api.common.fetchClinic(user).then((response: any) => {
        const { data } = response.data;
        set({ clinicUser: data });
        return response;
      });
    },

    async addContract(data: Contract) {
      return api.common.addContract(data).then((response: any) => {
        return response;
      });
    },

    setScheduleAppointmentCheck(check: boolean) {
      set({ canScheduleAppointment: check });
    }
  };

  return data;
});
