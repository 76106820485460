import {survey_translation} from "@/locales/es/surveys_translation"
//import * from '@/locales/es';



export class SurveyTranslator {

    static create() {
    
      }

      static createENtoESMap() {
        console.debug('createENtoESMap')
        const _map = new Map()
        for (const i in survey_translation) {
          //Object 
          //console.debug (JSON.stringify(en_es[i]))
          const key: string = Object.keys(survey_translation[i])[0];
          //console.debug (key)
          const value: string = Object.values(survey_translation[i])[0];
          //console.debug (key + ' - ' + value)
          _map.set(key, value)
        }
        return _map
      }

      static createEStoENMap() {
        console.debug('createEStoENMap')
        const _map = new Map()
        for (const i in survey_translation) {
          //Object 
          //console.debug (JSON.stringify(en_es[i]))
          const key: string = Object.keys(survey_translation[i])[0];
          //console.debug (key)
          const value: string = Object.values(survey_translation[i])[0];
          //console.debug (key + ' - ' + value)
          _map.set(value, key)
        }
        return _map
      }


      static  _translateToES(obj: any, translationMap: Map<string, string>) {

        //let text = "";
        for (const key in obj) {
      
          //console.debug (key)
          if (typeof obj[key] === "object" && obj[key] !== null) {
            // If the value is another object, recursively call the function
      
            SurveyTranslator._translateToES(obj[key], translationMap)
            console.debug (key + ' - ' + JSON.stringify(obj[key]))
          } else {
            // If the value is not an object, it's a leaf node, so you can do something with it
            //console.log (key)
            console.debug (key + ' - ' + JSON.stringify(obj[key]))
            const val = obj[key]
            if (translationMap.get(val))
              {
                obj[key] = translationMap.get(val);
              }
          }
        }
      }

      static translateToES(obj: any) {
        const translationMap = SurveyTranslator.createENtoESMap()
        SurveyTranslator._translateToES(obj, translationMap)
        console.debug ('Translating responses to spanish - ' + JSON.stringify(obj))
      }

      static _translateTemplateToES(obj: any, translationMap: Map<string, string>) {
        //let text = "";
        for (const key in obj) {
      
          //console.debug (key)
          if (typeof obj[key] === "object" && obj[key] !== null) {
            // If the value is another object, recursively call the function
            if (key == "enum") {
              //console.log (JSON.stringify(obj[key]))
              const enumVal = obj[key];
              //console.log ( JSON.stringify(enumVal))
              for (const i in enumVal) {
                //console.log (enumVal[i])
                const enVal = enumVal[i].trim()
      
                if (isNaN(enVal)) {
                  //console.log (enVal)
                  //text = text.concat(enumVal[i].trim() + "\n");
                  //const _keys = Object.keys(es);
                  // if (_keys.find((element) => element == enumVal[i].trim())) {
                  //   enumVal[i] = es[enumVal[i].trim()];
                  //   //console.log ( es[enumVal[i].trim()] )
                  // }
      
                  if (translationMap.has(enVal)) {
                    enumVal[i] = translationMap.get(enVal);
                    //console.log ( es[enumVal[i].trim()] )
                  }
                }
                //console.log (JSON.stringify(enumVal))
                obj[key] = enumVal;
              }
            }
            SurveyTranslator._translateTemplateToES(obj[key], translationMap)
            //text = text.concat(translateTemplate(obj[key]));
          } else {
            // If the value is not an object, it's a leaf node, so you can do something with it
            //console.log (key)
            if (key == "title" || key == "description" || key == "ui:description") {
      
              const _text = obj[key].replace(/(\r\n|\n|\r)/gm, "").trim();
              if (_text.length > 0) {
                //text =  text.concat (_text.trim() + '\n')
      
                //const _keys = Object.keys(es);
                // if (_keys.find((element) => element == _text)) {
                //   obj[key] = es[_text];
                //   //console.log (_text)
                // }
      
                if (translationMap.has(_text)) {
                  obj[key] = translationMap.get(_text);
                  //console.log(key + ': ' + obj[key]);
                  //console.log (_text)
                }
              }
            }
          }
        }
      
        //console.debug (text)
        //return text;
      }

      static translateTemplateToES(obj: any) {
        const translationMap = SurveyTranslator.createENtoESMap()
        SurveyTranslator._translateTemplateToES(obj, translationMap)
        console.debug ('Translating template to spanish - ' + JSON.stringify(obj))
      }

}


