import { AxiosInstance } from "axios";
import { BaseApiModule } from "./base.module";
import { unstrapiMany, unstrapiOne } from "../../helpers/strapi";
import { InvoiceType, TelehealthLocationType } from "../../types/appointment-types";

export class InvoiceApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  async submitInvoice(data:any) {
    return await this.client
      .post(`api/invoice/submitInvoice`, {
          data  
      })
      .then((res) => {}                
      );
  }


  async update(
    id: number,
    data: any
  ): Promise<InvoiceType> {
    return await this.client.put(`api/invoices/${id}`, { data });
  }

  async delete(
    id: number
    
  ): Promise<any> {
    return await this.client.delete(`api/invoices/${id}`);
  }

}
