import { AxiosResponse } from "axios";
import { get, isArray, set } from "lodash-es";
import {
  BaseContentType,
  StrapiContent,
  StrapiManyResponse,
  StrapiSingleResponse
} from "../api-client/types";

export type StrapiRelationshipMap = {
  [key: string]: boolean | StrapiRelationshipMap;
};

function createRelationshipHierarchy() {
  const invoiceReport = {};

  const invoice = {
    invoiceReport: true,
  };

  const account = {
    users: {}
  };

  const language = {};

  const service_provider_type = {};

  const serviceType = {};

  const admin_note = {};

  const paymentMethod = {
    id: true,
    stripeId: true,
    brand: true,
    last4: true,
    default: true,
    expMonth: true,
    expYear: true
  };

  const paymentLog = {
    paymentMethod: true
  };

  const event = {
    event: true,
    data: true,
    type: true,
    level: true
  };

  const insurance_provider = {
    name: true,
    description: true,
    state: true
  };

  const telehealth_location = {
    state: true
  };

  const health_insurance = {};

  const user = {
    state: true,
    profilePicture: true,
    admin_notes: true,
    account: true
  };

  const profilePicture = {};

  const evaluationTemplate = {
    serviceType: true
  };

  const evaluationReport = {
    template: evaluationTemplate,
    document: true,
    memberDocument: true,
    cpt_codes: true
  };

  const memberService = {
    clinic: true,
    appointment: {
      timeSlot: {
        serviceProvider: {
          user: true
        }
      }
    },

    referral: {
      state: true,
      serviceType: true,
      clinic: true,
      serviceProvider: {
        user: true
      }
    },

    user: {
      insurance_provider: true,
      telehealth_location: {
        state: true
      },
      health_insurance: true,
      state: true,
      paymentProfile: true,     
    },

    serviceType: true,
    memberReport: evaluationReport,
    providerReport: evaluationReport,
    paymentLogs: {
      paymentMethod: true
    },
    serviceProvider: {
      user
    },
    events: true,
    telehealth_location: true,
    health_insurance: true,
    invoice: {
      invoiceReport
    }
  };

  const serviceProvider = {
    user: {
      profilePicture: {},
      state: {}
    },
    states: true,
    serviceTypes: {},
    service_provider_types: {},
    languages: {}
  };

  const referral = {
    state: true,
    serviceType: true,
    memberService: {
      ...memberService,
      appointment: {
        timeSlot: {
          serviceProvider
        }
      }
    },
    clinic: true
  };

  const paymentProfile = {};

  return {
    invoiceReport,
    invoice,
    account,
    language,
    service_provider_type,
    serviceProvider,
    appointment: {
      memberService,
      timeSlot: {
        serviceProvider
      }
    },
    memberService,
    logs: {
      memberService,
      paymentMethod: true
    },
    evaluationReport,
    evaluationTemplate,
    coupon: {},
    referral,
    event,
    paymentLog: {
      paymentMethod: true
    },
    paymentMethod: {},
    state: {},
    qualification: {},
    serviceType: {},
    timeSlot: {},
    configuration: {},
    memberEvent: {},
    paymentProfile,
    cptCode: {},
    insurance_provider: {},
    telehealth_location: {
      state: true
    },
    user: {},
    health_insurance: {},
    admin_note: {},
    appointment_transcript: {}
  };
}

const RELATIONSHIP_HIERARCHY = createRelationshipHierarchy();

function unstrapiModel<T extends BaseContentType>(data: StrapiContent<T>): T {
  if (data === undefined || data === null) {
    return data;
  }

  const { id, attributes } = data as StrapiContent<T>;

  return {
    id,
    ...attributes
  } as T;
}

function unstrapiEntry<T extends BaseContentType>(
  entry: StrapiContent<T>,
  relationMap: StrapiRelationshipMap
) {
  const unstrapiData = unstrapiModel(entry);
  for (const key in relationMap) {
    let value = get(unstrapiData, key);
    if (value) {
      value = unstrapi(value.data, relationMap[key] as StrapiRelationshipMap);
      set(unstrapiData, key, value);
    }
  }

  return unstrapiData as T;
}

export function unstrapi<T extends BaseContentType>(
  data: StrapiContent<T> | Array<StrapiContent<T>>,
  relationMap: StrapiRelationshipMap
) {
  if (isArray(data)) {
    return data.map((item: StrapiContent<T>) => {
      return unstrapiEntry(item, relationMap);
    });
  }

  return unstrapiEntry(data, relationMap);
}

export function unstrapiOne<T extends BaseContentType>(
  response: AxiosResponse<StrapiSingleResponse<StrapiContent<T>>>["data"],
  entityType: keyof typeof RELATIONSHIP_HIERARCHY
) {
  return unstrapi(
    response.data,
    RELATIONSHIP_HIERARCHY[entityType] as StrapiRelationshipMap
  ) as T;
}

export function unstrapiMany<T extends BaseContentType>(
  response: AxiosResponse<StrapiManyResponse<StrapiContent<T>>>["data"],
  entityType: keyof typeof RELATIONSHIP_HIERARCHY
) {
  return unstrapi(
    response.data,
    RELATIONSHIP_HIERARCHY[entityType] as StrapiRelationshipMap
  ) as T[];
}
