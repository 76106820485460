import { Col, Row, Spinner } from "react-bootstrap";
import { api } from "@/apps/common/api-client";
import { useEffect, useState, useMemo } from "react";
import { useRequest } from "ahooks";
import { MemberServiceType, ReferralType } from "@/apps/common/types/appointment-types";
//import { useAuthStore } from "@/apps/common/store/useAuthStore";
import Table from "@/apps/common/components/Table";
import { dateSort } from "@/apps/common/helpers/date";
import API_BASE_URL from "@/config"
import { useNavigate } from "react-router-dom"
import FiltersBar from "./filtersBar";

// components
import PageTitle from "@/apps/common/components/PageTitle";

export default function MemberService() {

  //let showTable = false;
  type rowData = {
    //id: number
    memberServiceId: string
    referralId: string
    type: string
    createdAt: string
    name: string
    email: string
    phoneNumber: string
    serviceType: string
    paymentType: string
    status: string
    //apptStatus: string
    appointmentDate: string
  }

  // const setDefaultMemberServiceMap = () => {
  //   console.debug('initializing memberServiceMap')
  //   return new Map<number, any>()
  // }
  // const setDefaultReferralsMap = () => {
  //   return new Map<number, any>()
  // }

  const [selectedService, setSelectedService] = useState<MemberServiceType | null>(null)
  const [memberServicesMap, setMemberServicesMap] = useState<Map<number, Partial<MemberServiceType>>>(() => {
    //console.debug('initialize memberServicesMap')
    return new Map<number, any>()
  })
  const [referralsMap, setReferralsMap] = useState<Map<number, Partial<ReferralType>>>(
    () => {
      //console.debug('initialize referralsServiceMap')
      return new Map<number, any>()
    }
  )

  const [memberServices, setMemberServices] = useState<MemberServiceType[]>()
  //const [DtCMemberServices, setDtCMemberServices] = useState<MemberServiceType[]>()
  const [displayLoadingSpinner, setDisplayLoadingSpinner] = useState<boolean>(true);
  //const [localDomains, setLocalDomains] = useState<boolean>(false)
  const [referrals, setReferrals] = useState<ReferralType[]>()
  const [memberServiceFilters, setMemberServiceFilters] = useState<any>({
  });
  const [referralFilters, setReferralFilters] = useState<any>({
  });
  const [formFilters, setFormFilters] = useState<any>({
    admin_follow_up: false,
    referrals: true
  });
  const a: rowData[] = [];
  const [rows, setRows] = useState(a);

  const navigate = useNavigate();


  async function fetchAllReferrals(filters: string) {

    //console.debug(filters)
    api.referrals.findMany({ filters }).then((res) => {

      console.debug('Total Referrals - ' + res.length)
      setReferrals(res)
      let i = 0;
      res.forEach((x) => {

        //console.debug('serviceProvider - ' + JSON.stringify(x))

        if (filterEmail(x?.email) == false) {

          i = i + 1
          if (!referralsMap.has(x?.id)) {
            const row = {
              type: "referral",
              memberServiceId: "Pending",
              referralId: x?.id.toString(),
              createdAt: x?.createdAt ? new Date(x?.createdAt)?.toDateString() : 'ERROR',
              name: x?.firstName + ' ' + x?.lastName,
              email: x?.email ? x?.email : "N/A",
              phoneNumber: x?.phoneNumber ? x?.phoneNumber : "N/A",
              serviceType: x?.serviceType ? x?.serviceType.name : "N/A",
              paymentType: x?.paymentType ? x?.paymentType : "N/A",
              status: x?.status ? x?.status : "N/A",
              appointmentDate: "N/A"

            }

            referralsMap.set(x?.id, x)
            setRows(prev => [...prev, row])

          }
        }

      })

      console.debug('Referrals Map Size - ' + referralsMap.size)
      console.debug('referral count - ' + i)
      return res
    })
  }

  const fetchedMemberService = () => {
    if (formFilters?.referrals === true) {
      _fetchReferrals()
    }
    setDisplayLoadingSpinner(false)
  }

  async function fetchAllMemberServices(filters: string) {

    const _filters = JSON.stringify(memberServiceFilters)
    console.debug('fetchAllMemberService with following filters : ' + _filters)
    //console.debug(filters)
    // const _filters = filters.admin_follow_up ?
    await api.memberServices.findManyforAdmin({ filters: memberServiceFilters }).then((res) => {

      console.debug('Total MemberServices - ' + res.length)
      setMemberServices(res)
      console.debug('Table size ' + rows.length)
      setRows(a)
      console.debug('Reset Table size ' + rows.length)

      let i = 0
      let filteredMemberService = 0
      res.forEach((x) => {

        //console.debug('serviceProvider - ' + JSON.stringify(x?.user?.email))

        if (filterEmail(x?.user?.email) == false) {

          i = i + 1
          const row = {
            memberServiceId: x?.id.toString(),
            referralId: x?.referral?.id ? x?.referral?.id.toString() : "DTC",
            type: "memberService",
            createdAt: x?.createdAt ? new Date(x?.createdAt)?.toDateString() : 'ERROR',
            name: x?.user?.firstName + ' ' + x?.user?.lastName,
            email: x?.user?.email ? x?.user?.email : "N/A",
            phoneNumber: x?.user?.phoneNumber ? x?.user?.phoneNumber : "N/A",
            serviceType: x?.serviceType ? x?.serviceType.name : "N/A",
            paymentType: x?.paymentType ? x?.paymentType : "N/A",
            status: x?.status ? x?.status : "N/A",
            appointmentDate: x?.appointment?.timeSlot?.startDate ? new Date(x?.appointment?.timeSlot?.startDate).toLocaleString() : "N/A",
            apptStatus: x?.appointment?.status ? x?.appointment?.status : 'N/A'
          }

          memberServicesMap.set(x?.id, x)

          if ((x?.referral?.id) && (!referralsMap.has(x?.referral?.id)))
            referralsMap.set(x?.referral?.id, x?.referral)

          //showTable = true;

          setRows(prev => [...prev, row])
        }
        else {
          //console.debug ('Filtering memberservice with email ' + x?.user?.email)
          filteredMemberService = filteredMemberService + 1
        }

      })
      console.debug('memberServicesMap ' + JSON.stringify(memberServicesMap.size))
      console.debug('count ' + JSON.stringify(i))
      console.debug('Filtered Member Service count ' + JSON.stringify(filteredMemberService))

      return memberServicesMap
    })


  }

  const { data: __memberServicesMap, run: _fetchMemberServices } = useRequest(
    () =>
      fetchAllMemberServices(memberServiceFilters),
    {
      refreshDeps: [memberServiceFilters],
      //debounceWait: 300,
      onSuccess: fetchedMemberService,
      //manual: true
    }
  );

  const _setFilters = (formData: any) => {

    console.debug('FILTERS - ' + JSON.stringify(formData))

    setRows(a)
    memberServicesMap.clear()
    referralsMap.clear()
    setMemberServices([])
    setReferrals([])
    //setDtCMemberServices([])

    let memberServiceFilters = {}
    let formFilters = {}



    if (formData.admin_follow_up === true) {

      memberServiceFilters = {
        ...memberServiceFilters,
        user: {
          admin_follow_up: true
        }
      }

      formFilters = {
        ...formFilters,
        admin_follow_up: true
      }

    }
    else if ((formData.admin_follow_up === false) || (formData?.admin_follow_up)) {
      memberServiceFilters = {
        ...memberServiceFilters
      }
      formFilters = {
        admin_follow_up: false
      };
    }

    switch (formData?.filter) {
      case 'All':
        formFilters = {
          ...formFilters,
          referrals: true
        }

        break;
      case 'D2C':
        console.debug('Show D2C')
        memberServiceFilters = {
          ...memberServiceFilters,
          referral: {
            id: {
              $null: true
            }
          }
        }
        formFilters = {
          ...formFilters,
          referrals: false
        }
        break;
      case 'Referrals':
        console.debug('Show referrals')
        memberServiceFilters = {
          ...memberServiceFilters,
          referral: {
            id: {
              $notNull: true
            }
          }
        }

        formFilters = {
          ...formFilters,
          referrals: true
        }

        break;
    }

    if (formData?.memberServiceStatus?.status?.length > 0) {
      formFilters = {
        ...formFilters,
        referrals: false
      }
      // Add the appointment status for filtering
      memberServiceFilters = {
        ...memberServiceFilters,
        status: formData?.memberServiceStatus?.status
      }
    }
    if ((formData?.appointmentDateRange?.startDate) && (formData?.appointmentDateRange?.endDate)) {
      formFilters = {
        ...formFilters,
        referrals: false
      }
      // Add the appointment status for filtering
      memberServiceFilters = {
        ...memberServiceFilters,
        appointment: {
          $and:
            [
              {
                timeSlot: {
                  startDate: {
                    $gte: new Date(formData?.appointmentDateRange?.startDate)
                  }
                }
              },
              {
                timeSlot: {
                  startDate: {
                    $lte: new Date(formData?.appointmentDateRange?.endDate)
                  }
                }
              },
            ]
        }
      }
    }

    else if (formData?.appointmentDateRange?.endDate) {
      formFilters = {
        ...formFilters,
        referrals: false
      }
      // Add the appointment status for filtering
      memberServiceFilters = {
        ...memberServiceFilters,
        appointment: {
          timeSlot: {
            startDate: {
              $lte: new Date(formData?.appointmentDateRange?.endDate)
            }

          }
        }
      }
    }
    else if (formData?.appointmentDateRange?.startDate) {
      formFilters = {
        ...formFilters,
        referrals: false
      }
      // Add the appointment status for filtering
      memberServiceFilters = {
        ...memberServiceFilters,
        appointment: {
          timeSlot: {
            startDate: {
              $gte: new Date(formData?.appointmentDateRange?.startDate)
            }

          }
        }
      }
    }



    // if (formData?.referrals === true) {

    //   console.debug('Show referrals')

    //   memberServiceFilters = {
    //     ...memberServiceFilters,
    //     referral: {
    //       id: {
    //         $notNull: true
    //       }
    //     }
    //   }

    //   formFilters = {
    //     ...formFilters,
    //     referrals: true
    //   }
    // }
    // else if ((formData?.referrals === false) || (!formData?.referrals)) {
    //   console.debug('DO Not Show referrals')

    //   memberServiceFilters = {
    //     ...memberServiceFilters,
    //     referral: {
    //       id: {
    //         $null: true
    //       }
    //     }
    //   }
    //   formFilters = {
    //     ...formFilters,
    //     referrals: false
    //   }
    // }



    setMemberServiceFilters(memberServiceFilters)
    setFormFilters(formFilters)

  }

  const handleRowClick = (row: any) => {
    //console.debug('ID ' + JSON.stringify(row.values.id))
    const memberService = memberServices?.find((x) => {
      return (x.id == row.values.memberServiceId)

    })
    //console.debug ('selectedMemberService -' + JSON.stringify(memberService))

    if (memberService) {
      setSelectedService(memberService!)
      //setDisplayDetails(true)
      navigate(`/memberServiceDetails/${memberService?.id}`)
    }

    else {
      const referral = referrals?.find((x) => {
        return (x.id == row.values.referralId)

      })

      if (referral) {
        //setDisplayDetails(true)
        navigate(`/memberReferralDetails/${referral.id}`)
      }
    }


  };

  const columns = [
    {
      Header: "MemberService Id",
      accessor: "memberServiceId",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        <div className={row.values.referralId == 'DTC' ? 'text-dark' : 'text-primary'} >
          < a
            onClick={() => handleRowClick(row)}
          >
            {row.values.memberServiceId}
          </a>
        </div>
      )
    },
    {
      Header: "Referral Id",
      accessor: "referralId",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        <div className={row.values.referralId == 'DTC' ? 'text-dark' : 'text-primary'} >
          < a
            onClick={() => handleRowClick(row)}
          >
            {row.values.referralId}
          </a>
        </div>
      )
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      sort: true,
      sortType: dateSort,
      Cell: ({ row }: { row: any }) => (
        <div className={row.values.referralId == 'DTC' ? 'text-dark' : 'text-primary'} >
          < a
            onClick={() => handleRowClick(row)}
          >
            {row.values.createdAt}
          </a>
        </div>
      )
    },
    {
      Header: "Name",
      accessor: "name",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        <div className={row.values.referralId == 'DTC' ? 'text-dark' : 'text-primary'} >
          < a
            onClick={() => handleRowClick(row)}
          >
            {row.values.name}
          </a>
        </div>
      )
    },
    {
      Header: "email",
      accessor:
        "email",
      sort: false,
      Cell: ({ row }: { row: any }) => (
        <div className={row.values.referralId == 'DTC' ? 'text-dark' : 'text-primary'} >
          < a
            onClick={() => handleRowClick(row)}
          >
            {row.values.email}
          </a>
        </div>
      )
    },
    {
      Header: "phoneNumber",
      accessor:
        "phoneNumber",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        <div className={row.values.referralId == 'DTC' ? 'text-dark' : 'text-primary'} >
          < a
            onClick={() => handleRowClick(row)}
          >
            {row.values.phoneNumber}
          </a>
        </div>
      )
    },
    {
      Header: "serviceType",
      accessor:
        "serviceType",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        <div className={row.values.referralId == 'DTC' ? 'text-dark' : 'text-primary'} >
          < a
            onClick={() => handleRowClick(row)}
          >
            {row.values.serviceType}
          </a>
        </div>
      )
    },
    {
      Header: "paymentType",
      accessor:
        "paymentType",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        <div className={row.values.referralId == 'DTC' ? 'text-dark' : 'text-primary'} >
          < a
            onClick={() => handleRowClick(row)}
          >
            {row.values.paymentType}
          </a>
        </div>
      )
    },
    {
      Header: "Member Service Status",
      accessor:
        "status",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        <div className={row.values.referralId == 'DTC' ? 'text-dark' : 'text-primary'} >
          < a
            onClick={() => handleRowClick(row)}
          >
            {row.values.status}
          </a>
        </div>
      )
    },
    // {
    //   Header: "Appointment Status",
    //   accessor:
    //     "apptStatus",
    //   sort: true,
    //   Cell: ({ row }: { row: any }) => (
    //     <div className={row.values.referralId == 'DTC' ? 'text-dark' : 'text-primary'} >
    //       < a
    //         onClick={() => handleRowClick(row)}
    //       >
    //         {row.values.apptStatus}
    //       </a>
    //     </div>
    //   )
    // },
    {
      Header: "appointment Date",
      accessor:
        "appointmentDate",
      sort: true,
      sortingFn: dateSort,
      Cell: ({ row }: { row: any }) => (
        <div className={row.values.referralId == 'DTC' ? 'text-dark' : 'text-primary'} >
          < a
            onClick={() => handleRowClick(row)}
          >
            {row.values.appointmentDate}
          </a>
        </div>
      )
    }

  ];
  //const { user } = useAuthStore();

  const filterEmail = (email: string | undefined) => {

    // If the env is production then exclude the these subdomains for the list

    // if (localDomains === true) {
    //   //console.debug ('LOCAL DOMAIN. NO EMAIL FILTERING - ' + email )      
    //   return false;
    // }

    if (
      (email?.includes("skylerhealth") && !email?.includes("care+")) ||
      email?.includes("welcome")
    )
      return true;
    else return false;

  }

  const fetchedReferrals = () => {
    setDisplayLoadingSpinner(false)
  }

  const { data: _referrals = [], run: _fetchReferrals } = useRequest(
    () =>
      fetchAllReferrals(referralFilters),
    {
      //refreshDeps: [referralFilters],
      debounceWait: 300,
      onSuccess: fetchedReferrals,
      manual: true
    }
  );

  // useEffect(() => {
  //   if (API_BASE_URL.API_URL.includes('localhost') ||
  //     API_BASE_URL.API_URL.includes('dev') ||
  //     API_BASE_URL.API_URL.includes('beta')) {
  //     setLocalDomains(true)
  //   }

  //   //_fetchMemberServices()
  //   //_fetchReferrals()

  // }, []);


  const initialState = { hiddenColumns: ['Id'] };

  return (
    <>
      <PageTitle
        title={"Member Services"}
      />
      <Row>
        <Col>
          <FiltersBar values={{
            admin_follow_up: false,
            //referrals: true,
            filter: 'All'
          }} onChange={_setFilters} />
        </Col>
      </Row>
      {displayLoadingSpinner ?
        (
          <div className="d-flex justify-content-center">
            <Spinner></Spinner>
          </div>
        )
        : (
          <>
            <p><strong>Dates displayed in {Intl.DateTimeFormat().resolvedOptions().timeZone} TimeZone</strong></p>
            <div>
              <Table
                columns={columns}
                data={rows}
                pageSize={20}
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </div>
          </>)}
    </>
  );
}


