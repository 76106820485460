import React, { useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getPortalLink, getPortalType } from "../helpers";
import { PortalTypes } from "@/constants/portals";

const Footer = ({ showLinks = false }) => {
  const currentYear = new Date().getFullYear();

  const portalType = getPortalType();
  const otherPortals = useMemo(
    () =>
      [
        {
          type: PortalTypes.MEMBER,
          label: "Member Sign-in / Create an account",
        },
        {
          type: PortalTypes.CLINICAL,
          label: "Clinic Sign-in / Create an account",
        },
      ].filter(({ type }) => type !== portalType),
    [portalType]
  );

  return (
    <footer className="footer">
      <div className="container-fluid">
        <Row style={{ gap: "2rem" }}>
          <Col lg={3} className="d-flex flex-column align-items-center">
            {showLinks
              ? otherPortals.map(({ type, label }) => (
                  <Link
                    key={type}
                    to={getPortalLink(type)}
                    className="btn btn-link mb-2 mb-sm-0 mx-1"
                  >
                    {label}
                  </Link>
                ))
              : null}
            <div className="h4">Contact Skyler Health</div>
            care@skylerhealth.com
          </Col>
          <Col>
            If you're having an emergency, <b>please call 988</b>. If you're in
            crisis and looking for immediate support, the following resources
            are free and confidential.
            <br />
            Suicide Prevention Lifeline: call 1-800-273-8255
            <br />
            Crisis Text Line: text HOME to 741741
            <br />
            <br />
            {currentYear} &copy; Skyler Health
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
