import { generateForm } from "@modules/react-jsonschema-form-bootstrap";
//import { IChangeEvent } from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
//import { useNavigate } from "react-router-dom";
import { api } from "../../api-client";
import { TeleHealthLocationData } from "../../api-client/modules/common.module";
import { useTeleHealthLocationFormConfig } from "../../forms/TeleHealthForm";
// import {
//   formatDateOfBirth,
//   reverseFormatDateOfBirth
// } from "../../helpers/date";
//import { useAuthStore } from "../../store/useAuthStore";
import { TelehealthLocationType, UserType } from "../../types/appointment-types";
//import MemberService from "@/apps/admin/pages/MemberService/MemberService";

const Form = generateForm<TeleHealthLocationData>();

export default function TeleHealthLocationForm({
  mode,
  teleHealthLocation,
  display,
  user = null
}: {
  mode: "create" | "edit" | "preview";
  teleHealthLocation: TelehealthLocationType;
  user: Partial<UserType> | null | undefined
  display: (value: boolean) => void;
}) {

  //console.debug ('teleHealthLocation Props- ' + JSON.stringify(teleHealthLocation))
  //const navigate = useNavigate();
  //const { user } = useAuthStore();
  // console.debug ('User - ' + JSON.stringify(user))
  //

  const [submitMessage, setSubmitMessage] = useState<string>();
  const [displaySubmitMessage, setDisplaySubmitMessage] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [teleHealthLocationFormData, setTeleHealthLocationFormData] = useState<any>();
  //const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showAlert = () => {

    setDisplaySubmitMessage(true)
    const timeoutId = setTimeout(() => {
      setDisplaySubmitMessage(false);
    }, 2000);

  }

  //console.debug('user?.state?.id ' + user?.state?.id)
  const setValues = () => {
    const data = {
      ...(teleHealthLocation
        ? {
          ...teleHealthLocation,
          state: user?.state?.id,
        }
        : {
          streetAddress1: '',
          state: user?.state?.id,
          streetAddress2: '',
          city: '',
          zip: '',
          description: ''

        })
    };

    // console.debug ('Setting defaultValue ' + JSON.stringify(data))

    return data
  }


  const [defaultValues, setDefaultValues] =
    useState<Partial<TeleHealthLocationData>>();

  useEffect(() => {

    setDefaultValues(setValues())

  }, [teleHealthLocation, user]);

  const { schema, uiSchema, customValidate, transformErrors } =
    useTeleHealthLocationFormConfig({
      mode,
    });

  const onSubmit = (form: any) => {
    //console.debug ('submitting telehealth location data - ' + JSON.stringify(form.for))

    const formData = form?.formData;
    // console.debug('formData ' + JSON.stringify(formData))
    //setTeleHealthLocationFormData(form?.formData);
    const _telehealthLoc = {
      streetAddress1: formData.streetAddress1,
      streetAddress2: formData.streetAddress2 ? formData.streetAddress2 : '',
      city: formData.city,
      zip: formData.zip,
      description: formData.description,
      state: formData.state2
    }

    try {
      setLoading(true);
      if (teleHealthLocation?.id) {
        // console.debug('Updating an existing form')
        const data = {
          ..._telehealthLoc
        }

        api.telehealthLoc.update(teleHealthLocation.id, data).then((res) => {

          //setShowTeleHealthLocation(false)
          setSubmitMessage("Telehealth Location changes were saved successfully !")
          display(false)
          showAlert()
        },
          (err) => {
            setSubmitMessage("An Error occurred while saving Telehealth Location changes, Please try again.")
            showAlert()
          });
      }
      else {
        const _telehealthLoc = {
          streetAddress1: formData.streetAddress1,
          streetAddress2: formData.streetAddress2 ? formData.streetAddress2 : '',
          city: formData.city,
          zip: formData.zip,
          description: formData.description,
          state: formData.state,
          user: user?.id
        }
        api.telehealthLoc.create(_telehealthLoc).then((res) => {

          //setShowTeleHealthLocation(false)
          setSubmitMessage("Telehealth Location changes were saved successfully !")
          display(false)
          showAlert()
        }, (err) => {
          setSubmitMessage("An Error occurred while saving Telehealth Location changes, Please try again.")
          showAlert()
        })

      }

      setLoading(false);

    } catch (err) {
      setLoading(false);
      console.log(err);
    }

  };

  //console.debug('defaultValues -' + JSON.stringify(defaultValues))

  return (
    <>
      {defaultValues ? (
        <>
          We need to know where you will be located for your audio video telehealth session to submit a claim to your health insurance.
          <br />
          <br />
          <Form
            //formContext={formContext}
            formData={defaultValues as TeleHealthLocationData}
            schema={schema as any}
            uiSchema={uiSchema as any}
            validator={validator}
            showErrorList={false}
            customValidate={customValidate}
            noHtml5Validate
            transformErrors={transformErrors}
            onSubmit={onSubmit}
          ></Form>
          {/* <ConfirmReferralModal
        show={showConfirmModal}
        referralDetails={referralFormData}
        onDismiss={() => setShowConfirmModal(false)}
        onSubmit={submitReferral}
        loading={loading}
      /> */}
          <br />
          <br />
          <Alert variant="secondary" className="mb-0 d-md-block d-sm-none" show={displaySubmitMessage} >
            {submitMessage}
          </Alert>
        </>
      ) : null
      }
    </>

  );
  
}
