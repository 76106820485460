import { Button, Card } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { useCommonStore } from "@/apps/common/store/useCommonStore";
import { Link, useSearchParams } from "react-router-dom";
import { PAYMENT_STATUS } from "@/apps/common/constants";

const PaymentStatus = () => {
  const [loading, setLoading] = useState(false);
  const { addPaymentMethod } = useCommonStore();
  const [searchParams] = useSearchParams();

  const addPayment = async () => {
    try {
      setLoading(true);
      const { data } = await addPaymentMethod();
      location.replace(data.url);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Card className="mt-2">
        <Card.Body>
          {searchParams.get("status") === PAYMENT_STATUS.SUCCESS ? (
            <div className="text-center mt-2">
              <FeatherIcon size="100px" color="#155724" icon="check-circle" />
              <h4>Payment method added successfully</h4>
              <p>Thank you for updating your payment information</p>
              <Link to="/referrals/new-referrals">
                <Button className="rounded-pill px-4 mt-2">
                  Send new Referral <i className="uil uil-plus"></i>
                </Button>
              </Link>
            </div>
          ) : (
            <div className="text-center mt-2">
              <FeatherIcon size="100px" color="#721c24" icon="alert-octagon" />
              <h4>Payment method failed</h4>
              <p>
                Payment Failed: Please check your payment information and try
                again
              </p>
              <p>
                If the issue persists, contact our support team for assistance
              </p>
              <Button
                disabled={loading}
                onClick={addPayment}
                className="rounded-pill px-4 mt-2"
              >
                {loading ? "loading..." : "Try again"}
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default PaymentStatus;
