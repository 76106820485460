import { AxiosInstance, AxiosResponse } from "axios";
import { BaseApiModule } from "./base.module";
import { UserType } from "../../types/appointment-types";

//import { unstrapiMany, unstrapiOne } from "../../helpers/strapi";

export class UserApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }


  async updateMember( memberId:number, data: any ) {
    return await this.client.put(`/api/users/${memberId}/`, {data})
      .then((res) => {
        console.debug ('updateMember SUCCESS - ' + JSON.stringify(res))
        return res.data
      },
      (err) => {
        console.debug ('updateMember FAILURE - ' + JSON.stringify(err))}
      )
    }

  async fetchAllMembers( {
    filters,
    populate
  }: { filters?: any; populate?: string[] } = {filters:null, populate:[]}) {

    const DEFAULT_POPULATE = [
      "state",
      "admin_notes"
    ];

    const { followUp, ...restFilters } = filters ?? {};

    return await this.client
      .get("/api/users", {
        params: {
          populate: populate ?? DEFAULT_POPULATE,
           filters:{
            role:{
              name:'Member'
            },
            admin_notes:{
              followUp
            }
           },
           sort: "createdAt:DESC",
         },  
          })
      .then((res) => {
        return res.data
      }
      )
    }
  
}
    //   const { loading: saving, runAsync: updateUser } = useRequest(
    //     api.user.updateMember,
    //     {
    //       onSuccess: (result, params) => {
    //         try {
    //           console.debug("user update succeeded - " + JSON.stringify(result));
    //           setUser(result)
            
    //         } catch (error) {
    //           console.debug("user update failed - " + JSON.stringify(error));
    //           console.error(error);
    //         }
    //       },
    //       manual: true
    //     }
    //   );