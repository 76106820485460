import { generateForm } from "@modules/react-jsonschema-form-bootstrap";
import validator from "@rjsf/validator-ajv8";
import { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { api } from "../../api-client";
import { useMemberProfileFormConfig } from "../../forms/MemberProfile";
import { UserType } from "../../types/appointment-types";
import { showAlert } from "../../helpers/alertUtils";
import { useRequest } from "ahooks";


const Form = generateForm<UserType>();

// function showAlert (setDisplayAlert:(display:boolean)=>void, time:number) {
//   setDisplayAlert(true)
//   setTimeout(() => {
//     setDisplayAlert(false);
//   }, time);
// }

export default function MemberProfileForm({
  mode,
  display,
  user = null
}: {
  mode: "create" | "edit" | "preview" | "admin";
  user: Partial<UserType> | null | undefined
  display: (value: boolean) => void;
}) {

  //console.debug ('teleHealthLocation Props- ' + JSON.stringify(teleHealthLocation))
  //const navigate = useNavigate();
  //const { user } = useAuthStore();
  // console.debug ('User - ' + JSON.stringify(user))
  //

  const [submitMessage, setSubmitMessage] = useState<string>();
  const [displaySubmitMessage, setDisplaySubmitMessage] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  //const [teleHealthLocationFormData, setTeleHealthLocationFormData] = useState<any>();
  //const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { loading: saving, runAsync: updateUser } = useRequest(
    api.auth.updateUser.bind(api.auth),
    {
      onSuccess: (result, params) => {
        try {
          console.debug("user update succeeded - " + JSON.stringify(result));
          setSubmitMessage('Member Details were updated successfully')
          showAlert (setDisplaySubmitMessage, 2000)

        } catch (error) {
          console.debug("user update failed - " + JSON.stringify(error));
          console.error(error);
          setSubmitMessage('ERROR occurred while updating Member Details. Please try again.')
          showAlert (setDisplaySubmitMessage, 2000)
        }
      },
      manual: true
    }
  );

  //console.debug('user?.state?.id ' + user?.state?.id)
  const setValues = () => {
    const data = {
      ...(user
        ? {
          ...user,
          state: user?.state?.id,
          spokenLanguage: user?.spokenLanguage === 'en' ? "English" : user?.spokenLanguage === 'english' ? 'English' : user?.spokenLanguage === 'es' ? 'Spanish' : 'Unknown',
          employer: user?.employer ? user?.employer : ''
        }
        : {

          //state: user?.state?.id,

        })
    };

    //console.debug('Setting defaultValue ' + JSON.stringify(data))

    return data
  }

  const [defaultValues, setDefaultValues] =
    useState<any>();

  useEffect(() => {

    setDefaultValues(setValues())

  }, [user]);

  const { schema, uiSchema, customValidate, transformErrors } =
    useMemberProfileFormConfig({
      mode,
    });

  const onSubmit = (form: any) => {
    //console.debug('Updating member profile - ' + JSON.stringify(form?.formData))

    const formData = form?.formData;
    // console.debug('formData ' + JSON.stringify(formData))
    //setTeleHealthLocationFormData(form?.formData);
    const _memberProfile = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      employer: formData.employer,
      email: formData.email,
      phoneNumber: formData.phoneNumber
    }

    try {
      setLoading(true);
      if (user?.id) {
        updateUser(user?.id, _memberProfile)
      }
      setLoading(false);
    }

   catch (err) {
    setLoading(false);
    console.log(err);
  }

};

//console.debug('defaultValues -' + JSON.stringify(defaultValues))

return (
  <>
    {defaultValues ? (
      <>
        <Form
          //formContext={formContext}
          formData={defaultValues as UserType}
          schema={schema as any}
          uiSchema={uiSchema as any}
          validator={validator}
          showErrorList={false}
          customValidate={customValidate}
          noHtml5Validate
          transformErrors={transformErrors}
          onSubmit={onSubmit}
        ></Form>
        {/* <ConfirmReferralModal
        show={showConfirmModal}
        referralDetails={referralFormData}
        onDismiss={() => setShowConfirmModal(false)}
        onSubmit={submitReferral}
        loading={loading}
      /> */}
        <br />
        <br />
        <Alert variant="secondary" className="mt-1 mb-0 d-md-block d-sm-none" show={displaySubmitMessage} >
          {submitMessage}
        </Alert>
      </>
    ) : null
    }
  </>

);

}
