import { AxiosInstance } from "axios";
import { unstrapiOne } from "../../helpers/strapi";
import { EvaluationReportType } from "../../types/appointment-types";
import { BaseApiModule } from "./base.module";

export class EvaluationApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  async getReportById(id: number): Promise<EvaluationReportType> {
    return this.client
      .get(`api/evaluation-reports/${id}`, {
        params: {
          populate: "document"
        }
      })
      .then((res) =>
        unstrapiOne<EvaluationReportType>(res.data, "evaluationReport")
      );
  }

  async updateReport(
    id: number,
    data: Pick<EvaluationReportType, "response" | "status" | any>
  ): Promise<EvaluationReportType> {
    return await this.client
      .put(`api/evaluation-reports/${id}`, { data })
      .then((res) =>
        unstrapiOne<EvaluationReportType>(res.data, "evaluationReport")
      );
  }
}
