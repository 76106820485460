import { Alert, Card, Col, Row } from "react-bootstrap";

import { api } from "@/apps/common/api-client";
import PageTitle from "@/apps/common/components/PageTitle";
import Table from "@/apps/common/components/Table";
import { getFullName } from "@/apps/common/helpers";
import { DATE_FORMATS, formatDate } from "@/apps/common/helpers/date";
import { unstrapiMany } from "@/apps/common/helpers/strapi";
import { useRequest } from "ahooks";

export default function Billing() {
  const { data: logs, loading } = useRequest(() =>
    api.common.fetchPaymentLogs().then((res) =>
      unstrapiMany(res.data, "logs").map((log: any) => ({
        ...log,
        memberService: {
          ...log.memberService,
          user: {
            ...log.memberService.user,
            fullName: getFullName(log.memberService.user)
          }
        },
        formattedUpdatedAt: formatDate(log.updatedAt, DATE_FORMATS.DATE_ONLY)
      }))
    )
  );

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      sort: false
    },
    {
      Header: "Last Updated",
      accessor: "formattedUpdatedAt",
      sort: true
    },
    {
      Header: "Patient",
      accessor: "memberService.user.fullName",
      sort: true
    },
    {
      Header: "Card Number",
      accessor: "paymentMethod.last4",
      sort: true,
      Cell: ({ value, row }: any) =>
        `**** **** **** ${value} (${row.original.paymentMethod.brand})`
    },
    {
      Header: "Amount",
      accessor: "amount",
      sort: true,
      Cell: ({ value }: { value: number }) =>
        Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(value / 100)
    },
    {
      Header: "Status",
      accessor: "status",
      sort: true
    }
    // {
    //   id: "action",
    //   Header: "Actions",
    //   accessor: "memberService.referral.id",
    //   Cell: ({ value }: { value: string }) => (
    //     <Link to={`/referrals/${value}`}>
    //       <Button size="sm" variant="btn btn-primary rounded-pill width-sm">
    //         View
    //       </Button>
    //     </Link>
    //   ),
    // },
  ];

  return (
    <>
      <PageTitle title={"Billing details"} />

      <Row className="px-2">
        <Alert>
          If you wish to utilize your out-of-network benefits to offset the
          expense of your therapy sessions then send us a message at{" "}
          <a href="mailto:care@skylerhealth.com">care@skylerhealth.com</a> to
          request for a <strong>superbill</strong>. You'll receive a detailed
          superbill that outlines the services provided, along with the
          associated fees. This document is essential for seeking reimbursement
          from your insurance company. You can then submit this superbill to
          your insurance company for reimbursement. Many insurance plans offer
          out-of-network coverage for mental health services, which means you
          may be eligible for partial reimbursement of your session fees.
        </Alert>
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Table
                columns={columns}
                data={logs ?? []}
                pageSize={10}
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
