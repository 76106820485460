import { Spinner } from "react-bootstrap";

export default function LoaderSpinner() {
  return (
    <Spinner
      style={{
        height: 20,
        width: 20,
        marginTop: 4,
        fontSize: 10,
      }}
    />
  );
}
