import { api } from "@/apps/common/api-client";
import LeftTimeline from "@/apps/common/components/LeftTimeline";
import PageTitle from "@/apps/common/components/PageTitle";
import { useRequest } from "ahooks";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import EditReferralModal from "../../components/referrals/EditReferralModal";
import ReferralDetails from "./ReferralDetails";
import { getPortalType } from "../../helpers";
import { PortalTypes } from "@/constants/portals";

export default function MemberTimeline() {
  const { id } = useParams();

  const { data: memberEvents = [] } = useRequest(() =>
    api.referrals.getEvents(Number(id))
  );

  const { data: referralDetails, run } = useRequest(
    () =>
      api.referrals.getById(Number(id!), {
        populate: [
          "memberService.providerReport.document",
          "memberService.appointment.timeSlot.serviceProvider",
          "memberService.paymentLogs",
          "serviceType",
          "state"
        ]
      }),
    {
      refreshDeps: [id]
    }
  );

  const memberService = referralDetails?.memberService;

  if (!referralDetails) {
    return;
  }

  const newScheduleLink =
    getPortalType() === PortalTypes.CLINICAL
      ? `/referrals/${id}/schedule`
      : `/appointments/${referralDetails.memberService?.appointment?.id}/schedule`;

  return (
    <>
      <PageTitle title={"Referral"}>
        {!memberService ? (
          <EditReferralModal referral={referralDetails} onSave={run} />
        ) : null}
        {referralDetails.memberService?.appointment ? (
          <Button className="ms-2" href={newScheduleLink}>
            Schedule New Session
          </Button>
        ) : (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="tooltip-disabled">
                This member is a new patient. Once they complete a single
                appointment with you, you will then be able to schedule an
                appointment with them in the future for subsequent sessions.
              </Tooltip>
            }
          >
            <span className="d-inline-block">
              <Button className="ms-2" disabled>
                Schedule New Session
              </Button>
            </span>
          </OverlayTrigger>
        )}
      </PageTitle>

      <Row>
        <Col lg={4}>
          <ReferralDetails referralDetail={referralDetails} />
        </Col>

        <Col lg={8}>
          {referralDetails?.notes ? (
            <Card>
              <Card.Header>
                <strong>Referral Notes</strong>
              </Card.Header>
              <Card.Body>{referralDetails?.notes}</Card.Body>
            </Card>
          ) : null}
          <LeftTimeline memberService={memberService!} events={memberEvents} />
        </Col>
      </Row>
    </>
  );
}
