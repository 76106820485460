import { api } from "@/apps/common/api-client";
import LeftTimeline from "@/apps/common/components/LeftTimeline";
import PageTitle from "@/apps/common/components/PageTitle";
import { useRequest } from "ahooks";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import EditReferralModal from "../../components/referrals/EditReferralModal";
import ReferralDetails from "./ReferralDetails";
import { getPortalType } from "../../helpers";
import { PortalTypes } from "@/constants/portals";
import { MemberEventType, MemberServiceType } from "../../types/appointment-types";

export default function MemberTimeline({memberService, memberEvents}:{memberService:MemberServiceType|undefined, memberEvents:MemberEventType[]|undefined}) {


  // const { data: memberEvents = [] } = useRequest(() =>
  //   api.referrals.getEvents(Number(id))
  // );

  // const { data: referralDetails, run } = useRequest(
  //   () =>
  //     api.referrals.getById(Number(id!), {
  //       populate: [
  //         "memberService.providerReport.document",
  //         "memberService.appointment.timeSlot.serviceProvider",
  //         "memberService.paymentLogs",
  //         "serviceType",
  //         "state"
  //       ]
  //     }),
  //   {
  //     refreshDeps: [id]
  //   }
  // );

  //const memberService = referralDetails?.memberService;

  // if (!referralDetails) {
  //   return;
  // }


  return (
    <>
      {/* <PageTitle title={"Member Events"}>
        {!memberService ? (
          <EditReferralModal referral={referralDetails} onSave={run} />
        ) : null}
        {referralDetails.memberService?.appointment ? (
          <Button className="ms-2" href={newScheduleLink}>
            Schedule New Session
          </Button>
        ) : (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="tooltip-disabled">
                This member is a new patient. Once they complete a single
                appointment with you, you will then be able to schedule an
                appointment with them in the future for subsequent sessions.
              </Tooltip>
            }
          >
            <span className="d-inline-block">
              <Button className="ms-2" disabled>
                Schedule New Session
              </Button>
            </span>
          </OverlayTrigger>
        )}
      </PageTitle> */}

      <Row>
        {/* <Col lg={4}>
          <ReferralDetails referralDetail={referralDetails} />
        </Col> */}

        <Col> 
          <LeftTimeline memberService={memberService!} events={memberEvents!} />
        </Col>
      </Row>
    </>
  );
}
