import { useMemo } from "react";
import { api } from "../api-client";
import AsyncMultiSelectWidget from "../components/form-widgets/AsyncMultiSelectWidget"
import AsyncSelectWidget from "../components/form-widgets/AsyncSelectWidget";
import { defaults } from "lodash-es";
import { descriptionId } from "@rjsf/utils";
import { phoneNumberMask } from "./utils/masks";
import MaskInputWidget from "../components/form-widgets/MaskInputWidget";
type SchemaProps = {
  mode: "register" | "admin" | "create" | "edit" | "preview";
}
function isValidUSZip(sZip: string) {
  return /^\d{5}$/.test(sZip);
}

function iif(condition: boolean, then: any, otherwise: any = undefined) {
  return condition ? then : otherwise;
}

//const portalType = getPortalType();

const getProviderProfileSchema = ({ mode }: SchemaProps) => (

  {
    type: "object",
    required: [
      "firstName",
      "lastName",
      "address",
      "state",
      "phoneNumber",
      "email",
      "npi",
      "states",
      "languages",
      "serviceTypes",
      "service_provider_types"
    ],
    properties: {
      firstName: {
        "title": "First Name",
        "type": "string",
      },
      lastName: {
        "title": "Last Name",
        "type": "string",
      },
      address: {
        'title': 'Address',
        'type': 'string',
        description: "Please provide the address where you provide services from."
      },
      state: {
        type: "number",
        title: "State of Residence",
        description: "Please select the state where you provide services from."
      },
      phoneNumber: {
        'title': 'Phone Number',
        'type': 'string',
        description: "Please provide your primary phone number"
      },
      email: {
        'title': 'Email',
        'type': 'string',
        description: "Please provide your primary email. This is also your username for logging into SkylerHealth platform"
      },
      status: {
        "title": "Service Provider Status.",
        "type": "string",
        "oneOf": [
          {
            "const": "approved",
            "title": "Approved"
          },
          {
            "const": "pending",
            "title": "Pending"
          }
        ]
      },
      languages: {
        type: "array",
        title: "Languages",
        description: "Select language(s) in which you can provide services in.",
        uniqueItems: true,
        items: {
          type: "object"
        },
      },
      npi: {
        "type": ["string", "null"],
        title: "NPI",
        description: "NPI Number",
        pattern: "^([0-9]{10})"
      },
      states: {
        type: "array",
        title: "States with License",
        description: "Select all the states where you are licensed to practice",
        uniqueItems: true,
        items: {
          type: "object"

        }
      },
      serviceTypes: {
        type: "array",
        title: "Service Types",
        description: "Select Service Type(s)",
        uniqueItems: true,
        items: {
          type: "object"
        },
      },
      service_provider_types: {
        type: "array",
        title: "Service Provider Type",
        description: "Select Service Provider Type(s)",
        uniqueItems: true,
        items: {
          type: "object"
        },
      }
    }
  });

const getProviderProfileUISchema = ({
  mode,
}: SchemaProps) => ({

  "ui:submitButtonOptions": {
    norender: mode === "preview",
  },

  "ui:layoutOptions": { gridColumnProps: { xs: 12 } },

  status: {
    "ui:widget": iif(mode !== "admin", "hidden"),
    "ui:options": {
      placeholder: "Provider Approval Status",
    },
    "ui:disabled": iif(mode !== "admin", true),
  },
  firstName: {
    "ui:options": {
      placeholder: "First Name"
    }
  },
  lastName: {
    "ui:options": {
      placeholder: "Last Name"
    }
  },
  address: {
    "ui:options": {
      placeholder: "Address"
    }
  },
  npi: {
    "ui:options": {
      placeholder: "Please provide your NPI number"
    }
  },
  state: {
    "ui:widget": AsyncSelectWidget,
    "ui:options": {
      placeholder: "Select State of Residence"
    },
    requestCacheKey: "Rstates",
    request: () => api.auth.fetchStatesforRegistration(),
    selectItemOnLoad: true
  },
  // phoneNumber: {
  //   "ui:options": {
  //     placeholder: "Phone Number",
  //     "inputType": "tel"
  //   }
  // },
  phoneNumber: {
    "ui:widget": MaskInputWidget,
    "ui:options": {
      inputType: "tel",
      mask: phoneNumberMask,
      placeholder: "(___) ___-____"
    }
  },
  email: {
    "ui:options": {
      placeholder: "Email",
      "inputType": "email   "
    }
  },
  states: {
    "ui:widget": AsyncMultiSelectWidget,
    "ui:readonly": false,
    "ui:options": {
      placeholder: "Select States",
    },
    requestCacheKey: "states",
    request: () => api.auth.fetchStatesforReferrals(),
    selectItemOnLoad: true
  },

  serviceTypes: {
    "ui:widget": AsyncMultiSelectWidget,
    "ui:readonly": false,
    "ui:options": {
      placeholder: "Select Service Type(s)",
    },
    requestCacheKey: "serviceTypes",
    request: () => api.common.fetchServiceTypes(),
    selectItemOnLoad: true
  },

  service_provider_types: {
    "ui:widget": AsyncMultiSelectWidget,
    "ui:readonly": false,
    "ui:options": {
      placeholder: "Select Service Provider Type(s)",
    },
    requestCacheKey: "serviceProviderTypes",
    request: () => api.common.fetchServiceProviderTypes(),
    selectItemOnLoad: true
  },

  languages: {
    "ui:widget": AsyncMultiSelectWidget,
    "ui:readonly": false,
    "ui:options": {
      placeholder: "Select Language(s)",
    },
    requestCacheKey: "languages",
    request: () => api.common.fetchLanguages(),
    selectItemOnLoad: true
  },

}
);

function customValidate(formData: any, errors: any) {

  console.debug(JSON.stringify(formData?.languages))
  if ((!formData.languages) || (formData.languages.length < 1)) {

    errors.languages.addError("Please select one or more language(s)");
  }

  if ((!formData.service_provider_types) || (formData.service_provider_types.length < 1)) {
    errors.service_provider_types.addError("Please select one or more Service Provider Type(s)");
  }

  if ((!formData.serviceTypes) || (formData.serviceTypes.length < 1)) {
    errors.serviceTypes.addError("Please select one or more Service Type(s) thay you intend to provide");
  }
  if ((!formData.states) || (formData.states.length < 1)) {
    errors.states.addError("Please select one or more State(s) you are licensed to provide services");
  }
  // if (!formData.npi) {
  //   errors.states.addError("Please provide your NPI Number");
  // }



  return errors;
}

function transformErrors(errors: any[]) {
  return errors.map((error) => {
    //console.debug(JSON.stringify(error))
    if (error.property === '.npi') {
      //console.debug('NPI Error')
      error.message = "Please enter a valid NPI number. An NPI number is a 10 digit numeric value";
    }
    else if (error.name === "required") {
      error.message = "Please fill in this field";
    } else if (error.name === "format") {
      error.message = "Please enter a valid value";
    }
    // else if (error.name === "nolanguage") {
    //   error.message = "Please select one or more language(s)";
    // }
    return error;
  });
}

export function useProviderProfileFormConfig(props: Partial<SchemaProps>) {
  const withDefaultProps = defaults(props, {
    mode: "create",
  });

  const schema = useMemo(
    () => getProviderProfileSchema(withDefaultProps),
    [withDefaultProps]
  );

  const uiSchema = useMemo(() => {
    return getProviderProfileUISchema(withDefaultProps);
  }, [withDefaultProps]);

  return {
    schema,
    uiSchema,
    customValidate,
    transformErrors
  };
}