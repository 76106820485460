import { PortalTypes } from "@/constants/portals";
import React, { useMemo } from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { getPortalType } from "../helpers";
import { useTitle } from "../helpers/useTitle";
import { useCommonStore } from "../store/useCommonStore";

interface BreadcrumbItems {
  label: string;
  path: string;
  active?: boolean;
}

interface PageTitleProps {
  breadCrumbItems?: Array<BreadcrumbItems>;
  title: string;
  children?: React.ReactNode;
}

const PageTitle = (props: PageTitleProps) => {
  useTitle(props.title);

  const { clinicUser } = useCommonStore();

  const clinic = useMemo(() => {
    if (clinicUser?.length) {
      return clinicUser[0].attributes.clinic.data.attributes;
    }
  }, [clinicUser]);

  const headerPrefix = useMemo(() => {
    const portalType = getPortalType();
    if (portalType === PortalTypes.CLINICAL) {
      return clinic?.name;
    }
  }, [clinic]);

  return (
    <Row className="page-title-container">
      <Col>
        <div className="page-title-box d-none d-sm-flex">
          <h4 className="page-title">
            {headerPrefix && (
              <strong className="fs-3 me-2">{headerPrefix}</strong>
            )}
            {props.title}
          </h4>
          <div className="page-title-right">
            {!React.Children.count(props.children) ? (
              <Breadcrumb listProps={{ className: "m-0" }}>
                <Breadcrumb.Item href="/">Skyler Health</Breadcrumb.Item>

                {(props.breadCrumbItems || []).map((item, index) => {
                  return item.active ? (
                    <Breadcrumb.Item active key={index}>
                      {item.label}
                    </Breadcrumb.Item>
                  ) : (
                    <Breadcrumb.Item key={index} href={item.path}>
                      {item.label}
                    </Breadcrumb.Item>
                  );
                })}
              </Breadcrumb>
            ) : (
              props.children
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default PageTitle;
