import config from "@/config";
import mixpanel from "mixpanel-browser";
import { useLayoutEffect } from "react";

export const useTitle = (title: string) => {
  useLayoutEffect(() => {
    document.title = `${title} | ${config.APP_TITLE}`;
    if (config.MIXPANEL_TOKEN) {
      mixpanel.track_pageview({ page: title });
    }

    return () => {
      document.title = config.APP_TITLE;
    };
  }, [title]);
};
