import { Navigate, Route, RouteProps } from "react-router-dom";
import { useAuthStore } from "../store/useAuthStore";

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
  const loggedIn = useAuthStore((state) => state.loggedIn);
  const user = useAuthStore((state) => state.user);

  return (
    <Route
      {...rest}
      render={(props: RouteProps) => {
        if (!loggedIn) {
          // not logged in so redirect to login page with the return url
          return (
            <Navigate
              to={{
                pathname: "/auth/login",
                // state: { from: props["location"] },
              }}
            />
          );
        }

        // check if route is restricted by role
        if (roles?.indexOf(user?.role) === -1) {
          // role not authorized so redirect to home page
          return <Navigate to={{ pathname: "/" }} />;
        }

        // authorized so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
