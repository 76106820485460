import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { EventData } from "./interfaces";

interface ConfirmAppointmentModalProps {
  isOpen?: boolean;
  loading?: boolean;
  onClose?: () => void;
  isEditable?: boolean;
  eventData: EventData;
  errorMessage?: string;
  onConfirmAppointment: (id: string) => void;
}

export default function ConfirmAppointmentModal({
  isOpen,
  onClose,
  eventData,
  errorMessage,
  onConfirmAppointment,
  loading
}: ConfirmAppointmentModalProps) {
  return (
    <Modal show={isOpen} onHide={onClose} backdrop="static" keyboard={false}>
      <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
        <Modal.Title id="modal-title">
          <h5>Appointment confirmation</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 pb-4 pt-0">
        {eventData?.apptType == "tentative" ? (
          <p>
            Please press Confirm button below to confirm that you would like to
            schedule your appointment for this time. We will update you soon via
            email and text message once your appointment time is confirmed.
          </p>
        ) : null}
        <Row>
          <Col sm={6}>
            <h4 className="fs-15 mb-1">Time</h4>
            <p className="sub-header">{eventData.time}</p>
          </Col>
          <Col sm={6}>
            <h4 className="fs-15 mb-1">Date</h4>
            <p className="sub-header">{eventData.date}</p>
          </Col>
          <Col sm={6}>
            <h4 className="fs-15 mb-1">Timezone</h4>
            <p className="sub-header">{eventData.timezone}</p>
          </Col>
          <Col sm={6}>
            <h4 className="fs-15 mb-1">Service provider</h4>
            <p className="sub-header">{eventData.serviceProvider}</p>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="text-end">
            <Button className="btn btn-light me-1" onClick={onClose}>
              Close
            </Button>
            {errorMessage ? (
              <Alert variant="danger">{errorMessage}</Alert>
            ) : (
              <Button
                onClick={() =>
                  eventData?.id && onConfirmAppointment(eventData?.id)
                }
                variant="success"
                type="submit"
                className="btn btn-success"
              >
                {loading ? (
                  <Spinner
                    style={{
                      height: 20,
                      width: 20,
                      marginTop: 4,
                      fontSize: 10
                    }}
                  />
                ) : (
                  "Confirm"
                )}
              </Button>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
