import { Route } from "react-router-dom";

// components
import PrivateRoute from "@/apps/common/routes/PrivateRoute";
import ClinicLayout from "../components/Layout";

// pages
import PaymentStatus from "../pages/PaymentStatus/PaymentStatus";
import Profile from "../pages/Profile/Profile";
import MemberTimeline from "../../common/pages/Timeline/Timeline";

import Confirm from "@/apps/common/pages/auth/Confirm";
import ForgetPassword from "@/apps/common/pages/auth/ForgetPassword";
import Login from "@/apps/common/pages/auth/Login";
import Logout from "@/apps/common/pages/auth/Logout";
import Register from "@/apps/common/pages/auth/Register";

import { RouteType } from "@/apps/common/components/RoutesHandler";
import Billing from "../pages/Billing/Billing";
import NewReferral from "../../common/pages/NewReferral/NewReferral";
import Referrals from "../pages/Referrals/Referrals";
import ResetPassword from "@/apps/common/pages/auth/ResetPassword";
import ScheduleAppointment from "../pages/ScheduleAppointment/ScheduleAppointment";

// dashboards
const dashboardRoutes: RouteType[] = [
  {
    path: "/referrals",
    name: "Referrals",
    element: (
      <ClinicLayout>
        <Referrals />
      </ClinicLayout>
    ),
    route: PrivateRoute
  },
  {
    path: "/referrals/:id",
    name: "MemberTimeline",
    element: <MemberTimeline />,
    route: PrivateRoute
  },
  {
    path: "/referrals/:id/schedule",
    name: "ScheduleAppointment",
    element: <ScheduleAppointment />,
    route: PrivateRoute
  },
  {
    path: "/referrals/new-referrals",
    name: "NewReferral",
    element: (
      <ClinicLayout>
        <NewReferral />
      </ClinicLayout>
    ),
    route: PrivateRoute
  },
  {
    path: "/payment-method-status",
    name: "PaymentStatus",
    element: <PaymentStatus />,
    route: PrivateRoute
  },
  {
    path: "/billing",
    name: "Billing",
    element: (
      <ClinicLayout>
        <Billing />
      </ClinicLayout>
    ),

    route: PrivateRoute
  },
  {
    path: "/my-account",
    name: "MyAccount",
    element: (
      <ClinicLayout>
        <Profile />
      </ClinicLayout>
    ),
    route: PrivateRoute
  }
];

// auth
const authRoutes: RouteType[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route
  },

  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route
  },
  {
    path: "/auth/reset-password",
    name: "Reset Password",
    element: <ResetPassword />,
    route: Route
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route
  }
];

export const defaultRoute = "/referrals";

export const authProtectedRoutes = [...dashboardRoutes];
export const publicRoutes = [...authRoutes];

export { menuItems } from "./menuItems";
