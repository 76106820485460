import React, { useState, useEffect, Suspense, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import ListGroup from 'react-bootstrap/ListGroup';
import { useTranslation } from "react-i18next";


const MedicalCondtions = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const { i18n } = useTranslation();
    useMemo (()=>{

        if (searchParams.get("lang") == "es")
            i18n.changeLanguage("es");
    },[])

    useEffect(() => {

        
        if (document.body) document.body.classList.add("authentication-bg");

        return () => {
            if (document.body) document.body.classList.remove("authentication-bg");
        };
    }, []);

    const { t } = useTranslation();



    const [titleText, setTitleText] = useState(t("List of Medical Conditions"));
    const bodyText = 'A List of conditions';



    const medicalConditions: Map<string, Array<string>> = new Map<string, Array<string>>([
        [
            "Chronic conditions",
            [
                "Diabetes",
                "Hypertension (High blood pressure)",
                "Asthma",
                "Chronic obstructive pulmonary disease (COPD)",
                "Rheumatoid arthritis"]
        ],
        [
            "Cardiovascular conditions",
            [
                "Coronary artery disease",
                "Congestive heart failure",
                "Arrhythmias"
            ]
        ],
        [
            "Neurological conditions",
            [
                "Epilepsy",
                "Migraines",
                "Multiple sclerosis",
                "Parkinson's disease"
            ]
        ],
        [ 'Mental health conditions',
            [
                'Depression',
                'Anxiety disorders',
                'Bipolar disorder',
                'Schizophrenia'
            ]
        ],
        [
            "Gastrointestinal conditions",
                [
                    "Irritable bowel syndrome(IBS)",
                    "Gastroesophageal reflux disease(GERD)",
                    "Crohn's disease",
                    "Ulcerative colitis"
                ]
        ],
        [
            'Autoimmune conditions',
                [
                    'Lupus',
                    'Rheumatoid arthritis',
                    'Hashimoto\'s thyroiditis'
                ]
        ],
        [
            'Infectious diseases',
                [
                    'HIV / AIDS',
                    'Hepatitis',
                    'Tuberculosis'
                ]
        ],
        [
            'Endocrine disorders',
                [
                    'Thyroid disorders(e.g., hypothyroidism, hyperthyroidism)',
                    'Polycystic ovary syndrome(PCOS)'
                ]
        ],
        [
            'Cancer',
            [
                'Breast cancer',
                'Lung cancer',
                'Prostate cancer',
                'Colorectal cancer'
            ]
        ],
        [
            'Genetic disorders',
                [
                    'Down syndrome',
                    'Cystic fibrosis',
                    'Huntington\'s disease'
                ]
        ],
        [
            'Allergies',
                [
                    'Food allergies',
                    'Environmental allergies(e.g., pollen, dust)'
                    
                ]
        ]

    ]);

    const _keys = Array.from(medicalConditions.keys());



    return (
        <>
        

            <h2 style={{ textAlign: 'center' }}>{t("List of Medical Conditions")}</h2>
            <ul>
                {_keys.map((_key) => (
                    <h4 key={_key}>
                        {t(_key)}
                        <ul style={{ listStyleType: 'disc' }}>
                            {
                                medicalConditions?.get(_key)?.map((_value) => (
                                    <li style={{ fontWeight: 'normal' }}>{t(_value)}</li>
                                ))
                            }
                        </ul>
                    </h4>
                ))}
            </ul>

            <h3 style={{padding:21}}>{t("It's important to note that this is not an exhaustive list, and the range of medical conditions is extensive.")}</h3>
        </>
    );
}


export default MedicalCondtions;




