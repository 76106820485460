import "./assets/scss/Theme.scss";

import { useEffect } from "react";
import { getPortalType } from "./apps/common/helpers";
import { PortalTypes } from "./constants/portals";
import { useAuthStore } from "./apps/common/store/useAuthStore";
import RoutesHandler, {
  RoutesHandlerProps
} from "./apps/common/components/RoutesHandler";

import * as clinicalRoutesParams from "./apps/clinic/routes";
import * as memberRoutesParams from "./apps/member/routes";
import * as providerRoutesParams from "./apps/provider/routes";
import * as adminRoutesParams from "./apps/admin/routes";


export default function App() {
  const portalType = getPortalType();

  let params: RoutesHandlerProps = memberRoutesParams;

  if (portalType == PortalTypes.CLINICAL) {
    params = clinicalRoutesParams;
  } else if (portalType == PortalTypes.PROVIDER) {
    params = providerRoutesParams;
  } else if (portalType == PortalTypes.ADMIN) {
    params = adminRoutesParams;
  }else if (portalType == PortalTypes.PARTNER) {
    params = clinicalRoutesParams;
  }

  const { fetchUser } = useAuthStore();

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return <RoutesHandler {...params} />;
}
