import { Button, Card } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { useRequest } from "ahooks";
import { useCommonStore } from "@/apps/common/store/useCommonStore";
import { Link, useSearchParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { PAYMENT_STATUS, PAYMENT_TYPE } from "@/apps/common/constants";
import { api } from "@/apps/common/api-client";

const PaymentStatus = () => {
  const [loading, setLoading] = useState(false);
  const { addPaymentMethod } = useCommonStore();
  const [searchParams] = useSearchParams();
  const [showPaymentType, setShowPaymentType] = useState<boolean>(false)
  const [pymntType, setPaymentType] = useState<string>(PAYMENT_TYPE.SELF);

  const displayPaymentType = () => {
    return showPaymentType
  }

  function onChangeVal(arg: any) {
    console.debug('onChangeVal - ' + arg)

    // If Member Selected OKC update MemberService Payment Type to OKC 
    if (arg == 2) {
      setPaymentType(PAYMENT_TYPE.OKLAHOMA_COUNTY)
    }
    if (arg == 1) {
      setPaymentType(PAYMENT_TYPE.SELF)
    }
  }

  const { data } = useRequest(
    () => api.memberServices.findMany(),
    {
      pollingInterval: 15000
    }
  );

  async function onSelectPaymentType() {
    console.debug('onSelectPaymentType')
    let memberService;
    if (data && data.length > 0)
      memberService = data[0];

    let paymentType = memberService!.paymentType;
    // If Member Selected OKC update MemberService Payment Type to OKC 
    if (memberService) {
      if ((pymntType == PAYMENT_TYPE.OKLAHOMA_COUNTY) || (pymntType == PAYMENT_TYPE.CONNECTS_BENEFIT)){
        const paymentData = {
          paymentType: PAYMENT_TYPE.CONNECTS_BENEFIT
        };
        await api.memberServices.updatePaymentType(memberService.id, paymentData).then(
          (res) => { console.debug('api.memberServices.updatePaymentType response ' + JSON.stringify(res)) }
        )
        // const statusData = {
        //   status: "appointment-scheduled"
        // }
        //api.memberServices.updateStatus(memberService.id, statusData);
        // const apptStatus = {
        //   status: "confirmed"
        // }
        // //api.appointments.updateStatus(memberService!.appointment!.id, apptStatus)
        location.replace("/dashboard");
        setShowPaymentType(false)
      }
      else if (pymntType == PAYMENT_TYPE.SELF) {
        //console.debug('pymntType - ' + pymntType)
        setShowPaymentType(false)
        //setShowPaymentModal(true);
        paymentType = memberService!.paymentType;
        if (paymentType === PAYMENT_TYPE.SELF) {
          console.debug('add payment')
          setLoading(true);
          addPaymentMethod().then((res) => {
            location.replace(res.data.url);
            setLoading(false);
          })
        }
      }
    }
    else
      console.error('memberService is undefined')
  }

  const addPayment = async () => {
    try {

      // Adding this for OKC DTC Members ...
      let memberService;
      if (data && data.length > 0)
        memberService = data[0];
      const paymentType = memberService!.paymentType;
      console.debug('addPayment - ' + JSON.stringify(memberService))
      if (paymentType === PAYMENT_TYPE.SELF) {
        console.debug("paymentType", paymentType);
        //console.debug("paymentMethodStatus", paymentMethodStatus);
        setShowPaymentType(true)
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Card className="mt-2">
        <Card.Body>
          {searchParams.get("status") === PAYMENT_STATUS.SUCCESS ? (
            <div className="text-center mt-2">
              <FeatherIcon size="100px" color="#155724" icon="check-circle" />
              <h4>Payment method added successfully</h4>
              <p>Thank you for updating your payment information</p>
              <Link to="/dashboard">
                <Button className="rounded-pill px-4 mt-2">Continue</Button>
              </Link>
            </div>
          ) : (
            <div className="text-center mt-2">
              <FeatherIcon size="100px" color="#721c24" icon="alert-octagon" />
              <h4>Payment method failed</h4>
              <p>
                Payment Failed: Please check your payment information and try
                again
              </p>
              <p>
                If the issue persists, contact our support team for assistance
              </p>
              <Button
                disabled={loading}
                onClick={addPayment}
                className="rounded-pill px-4 mt-2"
              >
                {loading ? "loading..." : "Try again"}
              </Button>

              {
                <Modal show={displayPaymentType()}>
                  <Modal.Header>
                    <Modal.Title>Select Payment Type</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Select aria-label="Default select example" onChange={(event) => {
                      onChangeVal(event.target.value);
                    }}>
                      <option value="1">I will pay using a credit card</option>
                      <option value="2">Connect Benefit (employees only)</option>
                    </Form.Select>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={onSelectPaymentType}>
                      Select
                    </Button>
                  </Modal.Footer>
                </Modal>
              }
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default PaymentStatus;
