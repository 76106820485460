import { api } from "@/apps/common/api-client";
import PageTitle from "@/apps/common/components/PageTitle";
import { MemberServiceContext } from "@/apps/common/components/contexts/MemberServiceContext";
import ObjectWizardTemplate from "@/modules/react-jsonschema-form-bootstrap/ObjectFieldTemplate/ObjectWizardTemplate";
import Form from "@modules/react-jsonschema-form-bootstrap";
import { ErrorSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { pickBy } from "lodash-es";
import { useContext, useMemo, useRef } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { survey_translation } from "@/locales/es/surveys_translation";
import { useTranslation } from "react-i18next";
import { MEMBER_SERVICE_STATUS } from "@/apps/common/constants";

function transformErrors(errors: any[]) {
  return errors.map((error) => {
    if (error.name === "required") {
      error.message = " ";
    }

    return error;
  });
}

function processValidationErrors(errorSchema: ErrorSchema<any>, step: string) {
  let isValid = !errorSchema[step];

  if (!isValid) {
    const stepErrorSchema = pickBy(errorSchema[step], (value, key) => {
      if (key === "__errors") {
        return false;
      }

      const valueArray = (value as typeof errorSchema)?.__errors as string[];
      if (!valueArray?.length) {
        return true;
      }

      return !(
        valueArray?.length === 1 &&
        valueArray?.[0] === "must be equal to one of the allowed values"
      );
    });

    const errorFields = Object.keys(stepErrorSchema) as string[];
    errorFields.forEach((field) => {
      const element = document.querySelector(`label[for*="${field}"]`);
      if (element) {
        element.classList.add("text-danger");
      }
    });

    const element = document.querySelector(`label.text-danger`);
    if (!element) {
      isValid = true;
    }
  }

  setTimeout(() => {
    if (isValid) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      const element = document.querySelector(`label.text-danger`);
      const y = element!.getBoundingClientRect().top + window.scrollY - 140;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, 100);

  return isValid;
}

function translateTemplate(obj: any, translationMap: Map<string, string>) {
  //let text = "";
  for (const key in obj) {

    //console.debug (key)
    if (typeof obj[key] === "object" && obj[key] !== null) {
      // If the value is another object, recursively call the function
      if (key == "enum") {
        //console.log (JSON.stringify(obj[key]))
        const enumVal = obj[key];
        //console.log ( JSON.stringify(enumVal))
        for (const i in enumVal) {
          //console.log (enumVal[i])
          const enVal = enumVal[i].trim()

          if (isNaN(enVal)) {
            //console.log (enVal)
            //text = text.concat(enumVal[i].trim() + "\n");
            //const _keys = Object.keys(es);
            // if (_keys.find((element) => element == enumVal[i].trim())) {
            //   enumVal[i] = es[enumVal[i].trim()];
            //   //console.log ( es[enumVal[i].trim()] )
            // }

            if (translationMap.has(enVal)) {
              enumVal[i] = translationMap.get(enVal);
              //console.log ( es[enumVal[i].trim()] )
            }
          }
          //console.log (JSON.stringify(enumVal))
          obj[key] = enumVal;
        }
      }
      translateTemplate(obj[key], translationMap)
      //text = text.concat(translateTemplate(obj[key]));
    } else {
      // If the value is not an object, it's a leaf node, so you can do something with it
      //console.log (key)
      if (key == "title" || key == "description" || key == "ui:description") {

        const _text = obj[key].replace(/(\r\n|\n|\r)/gm, "").trim();
        if (_text.length > 0) {
          //text =  text.concat (_text.trim() + '\n')

          //const _keys = Object.keys(es);
          // if (_keys.find((element) => element == _text)) {
          //   obj[key] = es[_text];
          //   //console.log (_text)
          // }

          if (translationMap.has(_text)) {
            obj[key] = translationMap.get(_text);
            //console.log(key + ': ' + obj[key]);
            //console.log (_text)
          }
        }
      }
    }
  }

  //console.debug (text)
  //return text;
}

function translateResponse(obj: any, translationMap: Map<string, string>) {
  //let text = "";
  for (const key in obj) {

    //console.debug (key)
    if (typeof obj[key] === "object" && obj[key] !== null) {
      // If the value is another object, recursively call the function

      translateResponse(obj[key], translationMap)
      console.debug (key + ' - ' + JSON.stringify(obj[key]))
    } else {
      // If the value is not an object, it's a leaf node, so you can do something with it
      //console.log (key)
      console.debug (key + ' - ' + JSON.stringify(obj[key]))
      const val = obj[key]
      if (translationMap.get(val))
        {
          obj[key] = translationMap.get(val);
        }
    }
  }

  //console.debug (text)
  //return text;
}


const clearLabelErrors = () => {
  document.querySelectorAll(`label.text-danger`).forEach((element) => {
    element.classList.remove("text-danger");
  });
};

function createMap() {
  console.debug("createMap");
  const _map = new Map();
  for (const i in survey_translation) {
    //Object
    //console.debug (JSON.stringify(en_es[i]))
    const key: string = Object.keys(survey_translation[i])[0];
    //console.debug (key)
    const value: string = Object.values(survey_translation[i])[0];
    //console.debug (key + ' - ' + value)
    _map.set(key, value);
  }
  return _map;
}

function createReverseMap() {
  console.debug("createReverseMap");
  const _map = new Map();
  for (const i in survey_translation) {
    //Object
    //console.debug (JSON.stringify(en_es[i]))
    const key: string = Object.keys(survey_translation[i])[0];
    //console.debug (key)
    const value: string = Object.values(survey_translation[i])[0];
    //console.debug (key + ' - ' + value)
    _map.set(value, key);
  }
  return _map;
}

export default function Questionnaire({
  setOverrideStatus
}: {
  setOverrideStatus: (status?: string) => void;
}) {
  const { i18n } = useTranslation();

  const { memberService, refreshMemberService } =
    useContext(MemberServiceContext);
  const memberReportId = memberService!.memberReport!.id;
  // Load the survey translations ...

  const lang = memberService?.user?.spokenLanguage;

  console.debug("user " + lang);

  let en_esMap = null;
  if (lang == "es") {
    en_esMap = createMap();
    //i18n.changeLanguage("es");
  }

  let es_enMap = new Map();
  if (lang == "es") {
    es_enMap = createReverseMap();
    //i18n.changeLanguage("es");
  }

  const evaluationTemplate = useMemo(() => {
    const introOverride = {
      title: `${memberService?.serviceType?.name} Introduction`,
      description: `Welcome to Skyler Health ${memberService?.serviceType?.name}! `
    };

    const baseTemplate = memberService?.memberReport?.template;
    if (!baseTemplate) {
      return null;
    }

    baseTemplate.template.properties.Introduction.title = introOverride.title;
    baseTemplate.template.properties.Introduction.description =
      introOverride.description +
      baseTemplate.template.properties.Introduction.description;

    //console.debug (JSON.stringify(baseTemplate.template))
    if (lang == "es") {
      i18n.changeLanguage("es");
    }
    //console.log (txt)
    //console.debug(JSON.stringify(baseTemplate?.template));

    return baseTemplate;
  }, [memberService?.memberReport?.template, memberService?.serviceType?.name]);

  if (lang == "es") {
    // Translate the uiSchema too
    translateTemplate(evaluationTemplate?.uiSchema, en_esMap!);
    translateTemplate(evaluationTemplate?.template, en_esMap!);
  }
  //console.debug ('evaluationTemplate - ' + JSON.stringify (evaluationTemplate?.template))

  const formRef = useRef<any>();

  async function saveDraft(step: string) {
    const formData = formRef.current.state.formData || {};
    //console.debug ('saveDraft - ' + JSON.stringify(formData))

    const { errorSchema } = validator.validateFormData(
      formData,
      evaluationTemplate?.template
    );

    clearLabelErrors();

    const data = {
      response: formData,
      status: "draft"
    };
    await api.evaluations.updateReport(memberReportId, data);

    return processValidationErrors(errorSchema, step);
  }

  const onSubmit = async () => {
    const formData = formRef.current?.state?.formData || {};
    if (lang == "es") {
      // Convert Spanish to English
      translateResponse(formData, es_enMap);
    }

    console.debug("onSubmit - " + JSON.stringify(formData));

    const data = {
      response: formData,
      status: "complete"
    };
    await api.evaluations.updateReport(memberReportId, data);
    await refreshMemberService();
  };

  const surveySkipped = useMemo(() => {
    return ![
      MEMBER_SERVICE_STATUS.ONBOARDED,
      MEMBER_SERVICE_STATUS.SURVEY_STARTED
    ].includes(memberService.status);
  }, [memberService.status]);

  const onSkipSurvey = async () => {
    if (surveySkipped) {
      setOverrideStatus(undefined);
    } else {
      setOverrideStatus(MEMBER_SERVICE_STATUS.SURVEY_COMPLETED);
    }
  };

  const submitButtonText = surveySkipped ? "Done" : "Schedule Session Now";

  return (
    <>
      <PageTitle
        breadCrumbItems={[{ label: "Dashboard", path: "/dashboard" }]}
        title={"Questionnaire"}
      />
      <Card>
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={8} lg={8}>
            <Card.Body>
              {evaluationTemplate?.template ? (
                <Form
                  formData={memberService?.memberReport?.response}
                  ref={formRef}
                  schema={evaluationTemplate?.template || {}}
                  uiSchema={{
                    ...evaluationTemplate?.uiSchema,
                    "ui:ObjectFieldTemplate": (props) => (
                      <ObjectWizardTemplate
                        {...props}
                        submitButtonText={submitButtonText}
                        saveDraft={saveDraft}
                        onSubmit={onSubmit}
                        onSkipSurvey={onSkipSurvey}
                      />
                    )
                  }}
                  className="questionnaire-form"
                  validator={validator}
                  showErrorList={false}
                  transformErrors={transformErrors}
                  onSubmit={onSubmit}
                ></Form>
              ) : (
                <div className="text-center p-4 mt-4">
                  <Spinner
                    style={{ width: 100, height: 100 }}
                    animation="border"
                    variant="info"
                  />
                </div>
              )}
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  );
}




