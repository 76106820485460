import { api } from "@/apps/common/api-client";
import { PageTitle } from "@/apps/common/components";
import { formatDate, getUserTimezone } from "@/apps/common/helpers/date";
import { getImageUrl } from "@/apps/common/helpers/images";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import { ServiceProviderType, MemberServiceType } from "@/apps/common/types/appointment-types";
import { EventClickArg } from "@fullcalendar/core/index.js";
import { useRequest } from "ahooks";
import startCase from "lodash/startCase";
import { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from "react-bootstrap";

import { FormInput, VerticalForm } from "@/apps/common/components/";
import Calendar from "@/apps/member/components/Calendar/Calendar";
import ConfirmAppointmentModal from "@/apps/member/components/Calendar/ConfirmAppointmentModal";
import { EventData } from "@/apps/member/components/Calendar/interfaces";
import { FaArrowsUpDown } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { TimeSlotsType } from "../../../common/store/useCommonStore";
import { AxiosError } from "axios";

function ProviderProfile({
  provider,
  onSelect,
  active = false,
  singleProviderMode = false
}: {
  provider: ServiceProviderType & { nextAvailableSlot?: { startDate: string } };
  onSelect: (id: number) => void;
  active: boolean;
  singleProviderMode: boolean;
}) {
  const providerName = `${provider.user?.firstName} ${provider.user?.lastName}`;

  return (
    <div className="d-flex flex-column">
      <div className="d-flex gap-3">
        <img
          className="border rounded object-fit-cover"
          style={{ width: "180px", height: "180px" }}
          src={getImageUrl(provider.user?.profilePicture?.url)}
          alt={providerName}
        />
        <div className="d-flex flex-column gap-2">
          <h1 className="my-0">{providerName}</h1>
          <h2 className="my-0">{provider.qualification}</h2>
          {active ? null : (
            <div className="my-0 d-flex flex-row gap-2 align-items-center">
              <Button
                className="px-4 py-2 rounded-pill fs-4"
                size="lg"
                onClick={() => onSelect(provider.id)}
              >
                Book Session with {provider.user?.firstName}
              </Button>
              <p className="my-0">
                Next Available:{" "}
                <strong>
                  {provider.nextAvailableSlot
                    ? formatDate(provider.nextAvailableSlot.startDate)
                    : "Not available"}
                </strong>
              </p>
            </div>
          )}
          {provider.tags ? (
            <div className="d-flex py-2 flex-wrap">
              {provider.tags.map((tag) => (
                <Badge
                  key={tag}
                  bg="light"
                  pill
                  className="px-3 py-1 mb-3 me-4 rounded border border-primary text-primary fs-5 fw-normal"
                >
                  {tag}
                </Badge>
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <p className="mt-2">{provider.about}</p>
      {provider.fullProfileLink && (!active || singleProviderMode) ? (
        <Button
          target="_blank"
          href={provider.fullProfileLink}
          variant="outline-primary rounded-pill"
          className="align-self-end"
        >
          View Full Profile
        </Button>
      ) : null}
    </div>
  );
}

export default function ScheduleAppointment() {

  console.debug('ScheduleAppointment ')

  const [memberService, setMemberService] = useState<MemberServiceType>()
  const [slotType, setSlotType] = useState<number | undefined>(undefined)

  const { id } = useParams();

  const { data: _memberService, loading: memberServiceLoading } = useRequest(
    () =>
      fetchMemberService(),
    {
      //refreshDeps: [filters],
      ready: id ? true : false
    }
  );

  async function fetchMemberService() {
    console.debug('fetchMemberService - ' + id);

    if (id) {

      api.memberServices.findMemberService(parseInt(id)).then(
        (res) => {
          setMemberService(res)
          return res
        }
      )
    }

  }

  //console.debug ('id ' + id)
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);
  const [showTimeSlotRequest, setShowTimeSlotRequest] =
    useState<boolean>(false);

  const [eventData, setEventData] = useState<EventData>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onCloseModal = () => {
    setShow(false);
    setEventData({});
    setErrorMessage(undefined);
  };

  const onOpenModal = () => setShow(true);

  const { user } = useAuthStore();

  const timeZone = getUserTimezone(user);
  const displayTimezone = getUserTimezone(user, { formatForDisplay: true });

  const onEventClick = (arg: EventClickArg) => {
    //console.debug(JSON.stringify(timeSlots));
    const filter: TimeSlotsType[] = timeSlots!.filter(
      ({ id }: { id: string }) => id === arg.event.id
    );
    const event = {
      id: String(arg.event.id),
      title: arg.event.title,
      className: arg.event.classNames[0],
      time: `${formatDate(filter[0]?.start, "hh:mm a")} - ${formatDate(
        filter[0]?.end,
        "hh:mm a"
      )}`,
      timezone: timeZone,
      date: formatDate(filter[0]?.start, "LLLL dd, yyyy (cccc)"),
      serviceProvider: startCase(memberService!.serviceProviderType),
      apptType: filter[0].type
    };
    setEventData(event);

    onOpenModal();
  };

  const onConfirmAppointment = async (id: string) => {
    try {
      setLoading(true);
      const response = await api.memberServices.startNewSession({
        timeSlotId: id,
        memberServiceId: memberService!.id
      });
      navigate(`/referrals/${response.referral?.id}`);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setErrorMessage(
        ((err as AxiosError)?.response?.data as any)?.error?.message
      );
    }
  };

  const { data: serviceProviders = [] } = useRequest(
    () => api.serviceProviders.findAvailable(memberService!.id),
    {
      ready: memberService ? true : false
    }
  );

  const [selectedProvider, setSelectedProvider] = useState<
    number | undefined
  >();

  useEffect(() => {
    if (memberService) {
      console.debug('SERVICE TYPE' + memberService!.serviceType!.name)
      if (['Psychological Evaluation for General Mental Health',
        'Psychological Evaluation for Surgical Clearance'].includes(memberService!.serviceType!.name)) {
        console.debug('Selecting all slots)')
        setSlotType(undefined)
      }
      else {
        console.debug('Setting 60 min slot')
        setSlotType(60)
      }
    }
    if (serviceProviders?.length === 1) {
      setSelectedProvider(serviceProviders[0].id);
    }
  }, [serviceProviders]);

  const { data: timeSlots, loading: slotsLoading } = useRequest(
    () => api.appointments.fetchMemberAvailableTimeSlots(selectedProvider!, slotType),
    {
      ready: !!selectedProvider,
      refreshDeps: [selectedProvider]
    }
  );

  const filteredProviders = useMemo(() => {
    console.debug('useMemo - ' + JSON.stringify(serviceProviders))

    if (!serviceProviders) {
      return [];
    }

    if (!selectedProvider) {
      return serviceProviders;
    }

    return serviceProviders.filter(
      (provider) => provider.id === selectedProvider
    );
  }, [serviceProviders, selectedProvider, memberService]);

  const handleSubmit = (formData: any) => {
    if (formData?.timeSlots) {
      //console.debug ('TimeSlot Request Submisson - ' + formData.timeSlots)
      const data = {
        data: {
          user: user?.id,
          timeslot: formData?.timeSlots
        }
      };
      api.common.requestTimeSlots(data).then(
        (response) => {
          console.debug("Successfully submitted timeSlot request");
          setShowTimeSlotRequest(false);
          return response;
        },
        (response) => {
          console.error(
            "Failed to submit timeSlot request - " + JSON.stringify(response)
          );
        }
      );
    }
  };

  const timeSlotRequestForm = (
    <VerticalForm onSubmit={handleSubmit} formClass="authentication-form row">
      <FormInput
        type="textarea"
        name="timeSlots"
        label="Please let us know what day and times (with timezone) would work for you ?"
        //startIcon={<FeatherIcons icon={"activity"} className="icon-dual" />}
        containerClass={"mb-2 col-sm-12 col-md-12 col-lg-12"}
      />

      <div className="mb-2 text-center d-grid col col-md-6 col-lg-6 col-md-offset-3">
        <Button
          variant="secondary"
          type="reset"
          onClick={() => {
            setShowTimeSlotRequest(false);
          }}
        >
          Cancel
        </Button>
      </div>
      <div className="mb-2 text-center d-grid col col-md-6 col-lg-6 col-md-offset-3">
        <Button variant="primary" type="submit">
          Submit Request
        </Button>
      </div>
    </VerticalForm>
  );

  return (
    <>

      {/* <PageTitle
        title={"Member Services"}
      /> */}

      <Card>
        <Card.Header>Schedule an appointment</Card.Header>
        <Card.Body>
          <Row>
            <Col>
              {filteredProviders.map((provider) => (
                <>
                  <ProviderProfile
                    key={provider.id}
                    active={provider.id === selectedProvider}
                    provider={provider}
                    singleProviderMode={serviceProviders.length === 1}
                    onSelect={(provider) => setSelectedProvider(provider)}
                  />
                  <div
                    className="bg-primary my-3"
                    style={{ height: 2, width: "100%" }}
                  />
                </>
              ))}
              {selectedProvider && serviceProviders.length > 1 ? (
                <Button
                  variant="secondary"
                  className="mt-2"
                  onClick={() => setSelectedProvider(undefined)}
                >
                  Select a different provider
                </Button>
              ) : null}
              <Button
                variant="link"
                onClick={() => setShowTimeSlotRequest(true)}
              >
                Don't see a time that works for you? Click here to request a
                time
              </Button>
            </Col>

            {selectedProvider ? (
              <Col>
                <Alert variant="secondary">
                  <Row>
                    <Col>
                      Your Timezone: <strong>{displayTimezone}</strong>
                    </Col>
                    <Col className="text-right">
                      Scroll to see more slots <FaArrowsUpDown />
                    </Col>
                  </Row>
                </Alert>

                {timeSlots !== undefined ? (
                  <Calendar
                    onEventClick={onEventClick}
                    events={slotsLoading ? [] : timeSlots}
                    timeZone={timeZone}
                  />
                ) : (
                  <Spinner />
                )}
              </Col>
            ) : null}
          </Row>
        </Card.Body>
      </Card>

      <Modal
        show={showTimeSlotRequest}
        centered
        onHide={() => setShowTimeSlotRequest(false)}
      >
        <ModalHeader closeButton={true}>
          <h4 className="modal-title">Request for appointment</h4>
        </ModalHeader>
        <ModalBody>{timeSlotRequestForm}</ModalBody>
      </Modal>

      {show ? (
        <ConfirmAppointmentModal
          loading={loading}
          isOpen={show}
          onClose={onCloseModal}
          eventData={eventData}
          onConfirmAppointment={onConfirmAppointment}
          errorMessage={errorMessage}
        />
      ) : null}
    </>
  );
}
