import { api } from "@/apps/common/api-client";
import { AppointmentType } from "@/apps/common/types/appointment-types";





export function isPastAppointment(appointment: AppointmentType) {

    if (!appointment?.timeSlot)
        return

    let apptExpired = false;

    const apptTime = new Date(appointment?.timeSlot?.startDate);
    if (!apptTime) {
        console.error("Appointment - " + JSON.stringify(appointment));
    }

    const now = new Date();

    if (apptTime && now.getTime() > apptTime.getTime()) apptExpired = true;

    return apptExpired;
}



export function cancelAppointment(appointment: AppointmentType) {
    if (appointment)
        api.appointments.cancel(appointment.id).then(() => {
            window.location.reload();
        });
}

