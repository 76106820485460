import { api } from "@/apps/common/api-client";
//import { ReferralData } from "@/apps/common/api-client/modules/common.module";
/*import {
  formatDateOfBirth,
  reverseFormatDateOfBirth
} from "@/apps/common/helpers/date";
 */
import { useRequest } from "ahooks";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ReferralType } from "../../types/appointment-types";
import ReferralForm from "./ReferralForm";
import { getPortalType } from "@/apps/common/helpers/index"
import { PortalTypes } from "@/constants/portals";
import { useCommonStore } from "@/apps/common/store/useCommonStore";
import { useAuthStore } from "../../store/useAuthStore";

type PropsType = {
  referral: ReferralType;
  onSave: () => void;
};

export default function EditReferralModal({ referral, onSave }: PropsType) {
  const [show, setShow] = useState(false);
  const { fetchClinic } = useCommonStore();

  const { loading, runAsync } = useRequest(
    (data) => api.referrals.update(referral.id, data),
    {
      manual: true
    }
  );

  const showModel = () => {
    setShow(true);
  };

  const dismissModal = () => {
    setShow(false);
  };

  async function isSkylerClinic(): Promise<boolean> {
    {
      console.debug('isSkylerClinic')
      let isSkylerClinic = false;
      if (getPortalType() == PortalTypes.CLINICAL) {
        const user = await useAuthStore.getState().fetchUser();
        //console.debug ('user - ' + JSON.stringify(user))

        const b = await fetchClinic(user?.data?.id)
        const internalClinic = b?.data?.data[0]?.attributes?.clinic?.data?.attributes?.internalClinic
        console.debug('clinic ' + internalClinic)

        //console.debug ('clinicuser - ' + JSON.stringify(clinicUser[0]?.attributes?.clinic.data.attributes?.internalClinic))
        if (internalClinic === true)
          isSkylerClinic = true;
      }

      return new Promise((resolve) => {
        resolve(isSkylerClinic);
      }
      );
    }

  }

  const clinicData = useRequest(isSkylerClinic, {
    onSuccess: (result, params) => {
      if (result) {
        console.debug('onSunccess')
      }
    },
  });

  return (
    <>
      <Button onClick={showModel}>Edit Referral</Button>

      <Modal size="lg" show={show} onHide={dismissModal}>
        <Modal.Header closeButton>
          <Modal.Title as="h4">Edit Referral</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { !clinicData.loading && (
          <ReferralForm mode="edit" skylerClinic = {clinicData.data} referral={referral} />
          )
          }
        </Modal.Body>
      </Modal>
    </>
  );
}
