import MarkdownModal from "@/apps/common/components/MarkdownModal/MarkdownModal";
import PaymentContextProvider, {
  PaymentContext
} from "@/apps/common/components/contexts/PaymentContextProvider";
import { CONTENT_TEMPLATE } from "@/apps/common/constants";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import startCase from "lodash/startCase";
import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { useCommonStore } from "../../../common/store/useCommonStore";
import { getPortalType } from "@/apps/common/helpers";
import { PortalTypes } from "@/constants/portals";
import { api } from "@/apps/common/api-client";

function ClinicLayoutWithoutContext({ children }: PropsWithChildren) {
  const [show, setShow] = useState(false);

  const [contractLoading, setContractLoading] = useState(false);
  const { fetchContentTemplate, contentTemplate, fetchClinic, addContract } =
    useCommonStore();
  const { user } = useAuthStore();

  const contentTemplateDetails = () => {
    if (!contentTemplate.length) {
      return;
    }
    const { attributes } = contentTemplate[0];
    return {
      slug: startCase(attributes.slug),
      content: attributes.content
    };
  };

  const acceptContract = async () => {
    try {
      setContractLoading(true);
      const data = {
        content: contentTemplateDetails()?.content,
        signedBy: user?.id.toString() || "",
        fullName: `${user?.firstName} ${user?.lastName}`,
        slug: CONTENT_TEMPLATE.BUSINESS_ASSOCIATE_AGREEMENT
      };
      await addContract({ data });
      await getContract();
      setContractLoading(false);
      setShow(false);
    } catch (err) {
      console.log(err);
      setContractLoading(false);
    }
  };

  const { requestPayment } = useContext(PaymentContext);

  useEffect(() => {
    if (getPortalType() !== PortalTypes.PARTNER) {
      requestPayment();
    }
  }, [requestPayment]);

  const getContract = async () => {
    try {
      const { data } = await api.contracts.fetchContracts(
        CONTENT_TEMPLATE.BUSINESS_ASSOCIATE_AGREEMENT
      );
      if (!data.data.length) {
        fetchContentTemplate(CONTENT_TEMPLATE.BUSINESS_ASSOCIATE_AGREEMENT);
        setShow(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getContract();
    fetchClinic(user?.id.toString() || "");
  }, []);

  return (
    <>
      <MarkdownModal
        loading={contractLoading}
        subtitle="Please review and Accept the Business Associate Agreement (BAA) below to ensure that we can privately and safely share patient information between your office and Skyler Health providers."
        content={contentTemplateDetails()?.content || ""}
        show={show}
        title={contentTemplateDetails()?.slug || ""}
        acceptText="By indicating my consent, I am signing this Business Associate Agreement (BAA) electronically. I agree my electronic signature is the legal equivalent of my manual signature on this Agreement. If I am signing on behalf of the named clinic and I attest that I have the authority to do so."
        onAccept={acceptContract}
      />
      {children}
    </>
  );
}

export default function ClinicLayout({ children }: PropsWithChildren) {
  return (
    <PaymentContextProvider>
      <ClinicLayoutWithoutContext>{children}</ClinicLayoutWithoutContext>
    </PaymentContextProvider>
  );
}
