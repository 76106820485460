import { Button, Modal } from "react-bootstrap";
//import FeatherIcon from "feather-icons-react";


interface ConfirmModalProps {
  show: boolean;
  loading?: boolean;
  onHide?: () => void;
  onClickOk?: () => void;
  title: string,
  message: string

}

export const ConfirmModal = ({
  show,
  loading,
  onClickOk,
  onHide,
  title,
  message
}: ConfirmModalProps) => {
  return (
    <Modal className="consifrm-modal" show={show} centered onHide={onHide}>
      <Modal.Header closeButton={!!onHide}>
        <h4 className="modal-title">{title}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <p className="text-muted mt-2">
          {message}
         </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
      <div className="text-md-end mb-0 mx-auto">
        <Button disabled={loading} onClick={onClickOk} variant="primary" className="btn me-1 btn-block" type="submit" style={{ minWidth: "80px" , maxWidth: "80px"}}>
          {loading ? "loading..." : "Ok"}
        </Button>
        <Button variant="primary" className="btn btn-block" type="reset" onClick={onHide} style={{ minWidth: "80px" , maxWidth: "80px"}}>
          Cancel
        </Button>
      </div>
      </Modal.Footer>
    </Modal>
  );
};

//export default EditProfileModal;
