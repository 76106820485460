import { useReferralFormConfig } from "@/apps/common/forms/ReferralForm";
import Form from "@/modules/react-jsonschema-form-bootstrap";
import validator from "@rjsf/validator-ajv8";
import { Button, Modal, Spinner } from "react-bootstrap";

type PropsType = {
  referralDetails: any;
  show: boolean;
  loading: boolean;
  onDismiss: () => void;
  onSubmit: () => void;
};

export default function ConfirmReferralModal({
  referralDetails,
  show,
  loading,
  onDismiss,
  onSubmit
}: PropsType) {
  const { schema, uiSchema } = useReferralFormConfig({
    mode: "preview",
    type: referralDetails?.referralId ? "repeat" : "new"
  });

  return (
    <Modal show={show} onHide={onDismiss} centered>
      <Modal.Header>
        <Modal.Title as="h4">Confirm Referral Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          className="authentication-form referral-info-form row"
          formData={referralDetails}
          schema={schema as any}
          uiSchema={uiSchema}
          validator={validator}
          disabled={true}
        />

        <div className="mb-2 text-center d-grid col-12">
          <Button onClick={onSubmit} disabled={loading}>
            {loading ? <Spinner /> : "Submit"}
          </Button>
        </div>
        <div className="mb-2 text-center d-grid col-12">
          <Button onClick={onDismiss} variant="secondary" disabled={loading}>
            Edit
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
