import { ReferralType } from "@/apps/common/types/appointment-types";
import Form from "@/modules/react-jsonschema-form-bootstrap";
import { WidgetProps } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import FeatherIcons from "feather-icons-react";
import { useCallback, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import SearchExistingMember from "./SearchExistingMember";

export default function ReferExistingField({
  value,
  onChange,
  formContext,
  disabled
}: WidgetProps) {
  const [showSearchMemberModal, setShowSearchMemberModal] = useState(false);

  const { setExistingReferral, defaultValues } = formContext as any;
  if (!setExistingReferral) {
    throw new Error("Pass setExistingReferral in formContext");
  }

  const onSelect = useCallback(
    (referral: ReferralType) => {
      setShowSearchMemberModal(false);
      onChange(referral.id);
      setExistingReferral(referral);
      console.log({ referral, value });
    },
    [onChange, setExistingReferral, value]
  );

  const clearSelection = useCallback(() => {
    onChange(undefined);
    setExistingReferral?.();
  }, [onChange, setExistingReferral]);

  return (
    <Row>
      {value ? (
        <Col xs={"12"}>
          <Card className="bg-light">
            <Card.Body className="pb-0">
              <Form
                schema={{
                  type: "object",
                  properties: {
                    dateOfBirth: {
                      type: "string",
                      title: "Date of birth"
                    },
                    firstName: {
                      type: "string",
                      title: "First Name"
                    },
                    lastName: {
                      type: "string",
                      title: "Last Name"
                    },
                    email: {
                      type: "string",
                      format: "email",
                      title: "Email"
                    },
                    phoneNumber: {
                      type: "string",
                      title: "Mobile Phone"
                    }
                  }
                }}
                uiSchema={{
                  "ui:layoutOptions": { gridColumnProps: { xs: 6 } },
                  "ui:submitButtonOptions": {
                    norender: true
                  }
                }}
                formData={defaultValues}
                validator={validator}
                disabled={true}
              ></Form>
            </Card.Body>
            {disabled ? null : (
              <Card.Footer className="gap-1 d-flex">
                <Button
                  className="me-2"
                  onClick={() => setShowSearchMemberModal(true)}
                >
                  <FeatherIcons
                    icon="repeat"
                    className="me-2"
                    style={{ height: 16 }}
                  />
                  Select different member
                </Button>
                <Button onClick={() => clearSelection()} variant="secondary">
                  Clear
                </Button>
              </Card.Footer>
            )}
          </Card>
        </Col>
      ) : (
        <Col xs={"6"}>
          <Button
            variant="outline-secondary"
            onClick={() => setShowSearchMemberModal(true)}
            className="w-auto"
          >
            <FeatherIcons icon="search" className="me-2" />
            Search a member
          </Button>
        </Col>
      )}
      <SearchExistingMember
        show={showSearchMemberModal}
        onHide={() => setShowSearchMemberModal(false)}
        onSelect={onSelect}
      />
    </Row>
  );
}
