import ReferExistingField from "@/apps/common/pages/NewReferral/ReferExistingField";
import { useMemo } from "react";
import { api } from "../api-client";
import AsyncSelectWidget from "../components/form-widgets/AsyncSelectWidget";
import MaskInputWidget from "../components/form-widgets/MaskInputWidget";
import { PAYMENT_TYPE, PAYMENT_TYPE_DISPLAY } from "../constants";
import { DATE_FORMATS, isValidDateFormat } from "../helpers/date";
//import { unstrapiMany } from "../helpers/strapi";
import { dateOfBirthMask, phoneNumberMask } from "./utils/masks";
import { defaults } from "lodash-es";
import { getPortalType } from "../helpers";
import { PortalTypes } from "@/constants/portals";
//import { useAuthStore } from "@/apps/common/store/useAuthStore";

type SchemaProps = {
  mode: "create" | "edit" | "preview" | "admin-edit";
  type: "new" | "repeat";
  hasPaymentAttached: boolean;
  portalType?: "" | PortalTypes.PARTNER;
  skylerClinic?: boolean;
}

function iif(condition: boolean, then: any, otherwise: any = undefined) {
  return condition ? then : otherwise;
}

const portalType = getPortalType();

// const skylerClinic = () => {

//   const user = useAuthStore.getState().fetchUser();
//   console.debug ( "user - " + JSON.stringify(user))
//   return false;

// }

const PAYMENT_TYPE_MESSAGE_FOR_CLINIC =
  "Select Member if you want the patient to pay for the evaluation. Otherwise, select Clinic for the clinic to pay for the evaluation.";
const PAYMENT_TYPE_MESSAGE_FOR_PROVIDER =
  "Select Member if you want the patient to pay for the evaluation. Otherwise, select Provider for the Service Provider to pay for the evaluation.";
const PAYMENT_TYPE_MESSAGE_FOR_ADMIN = "Select Payment Type.";

const PAYMENT_TYPE_MESSAGE =
  portalType === PortalTypes.CLINICAL ? 
    PAYMENT_TYPE_MESSAGE_FOR_CLINIC
    : portalType === PortalTypes.PROVIDER ?
    PAYMENT_TYPE_MESSAGE_FOR_PROVIDER 
    : PAYMENT_TYPE_MESSAGE_FOR_ADMIN


const getReferralSchema = ({ mode, portalType, skylerClinic }: SchemaProps) => ({
  type: "object",
  required: [
    "dateOfBirth",
    "state",
    "firstName",
    "lastName",
    "email",
    "phoneNumber",
    "serviceType",
    "spokenLanguage",
    "paymentType"
  ],
  properties: {
    referralId: {
      type: "number",
      title: "Select Existing Referral"
    },
    dateOfBirth: {
      type: "string",
      title: "Date of birth"
    },
    state: {
      type: "number",
      title: "State"
    },
    firstName: {
      type: "string",
      title: "First Name"
    },
    lastName: {
      type: "string",
      title: "Last Name"
    },
    email: {
      type: "string",
      format: "email",
      title: "Email"
    },
    phoneNumber: {
      type: "string",
      title: "Mobile Phone"
    },
    referringDoctor: {
      type: "string",
      title: "Referring Doctor"
    },
    employer: {
      type: "string",
      title: "Employer"
    },
    serviceType: {
      type: "number",
      title: "Service Type"
    },
    spokenLanguage: {
      type: "string",
      title: "Language Spoken",
      enum: ["English", "Spanish"]
    },
    paymentType: {
      type: "string",
      title: "Payment By",
      description: mode !== "preview" ? PAYMENT_TYPE_MESSAGE : undefined
    },
    notes: {
      type: "string",
      title: "Notes for Skyler Health",
      default: ""
    }
  }
});

const getReferralUISchema = ({
  mode,
  type,
  hasPaymentAttached,
  skylerClinic
}: SchemaProps) => ({
  
  "ui:rootFieldId": `referral-${type}-${mode}`,
  "ui:submitButtonOptions": {
    norender: mode === "preview",
    submitText:iif( mode === "admin-edit" ,
      "Update Referral Details",   "Submit"     
    ),
  },
  "ui:layoutOptions": { gridColumnProps: { xs: 6 } },
  referralId: {
    "ui:widget": iif(((mode === "preview") || (mode === 'admin-edit')), "hidden", ReferExistingField),
    "ui:gridColumnProps": { xs: 12 },
    "ui:disabled": mode === "edit"
  },
  dateOfBirth: {
    "ui:widget": iif(
      type === "repeat" && mode === "create",
      "hidden",
      MaskInputWidget
    ),
    "ui:options": {
      mask: dateOfBirthMask,
      placeholder: "MM/DD/YYYY"
    },
    "ui:readonly": iif( mode === "preview",
      true,false        
    ),
  },
  state: {
    "ui:widget": AsyncSelectWidget,
    "ui:options": {
      placeholder: "Select State"
    },
    requestCacheKey: "states",
    request: () => api.auth.fetchStatesforReferrals(),
    selectItemOnLoad: true,
    "ui:readonly": iif( mode === "preview",
      true,false        
    ),
  },
  firstName: {
    "ui:widget": iif(type === "repeat" && mode === "create", "hidden"),
    "ui:options": {
      placeholder: "First Name"
    },
    "ui:readonly": iif( mode === "preview",
      true,false        
    ),
  },
  lastName: {
    "ui:widget": iif(type === "repeat" && mode === "create", "hidden"),
    "ui:options": {
      placeholder: "Last Name"
    },
    "ui:readonly": iif( mode === "preview",
      true,false        
    ),
  },
  email: {
    "ui:widget": iif(type === "repeat" && mode === "create", "hidden"),
    "ui:options": {
      placeholder: "Email"
    },
    "ui:readonly": iif( mode === "preview",
      true,false        
    ),
  },
  phoneNumber: {
    "ui:widget": iif(
      type === "repeat" && mode === "create",
      "hidden",
      MaskInputWidget
    ),
    "ui:options": {
      inputType: "tel",
      mask: phoneNumberMask,
      placeholder: "(___) ___-____"
    },
    "ui:readonly": iif( mode === "preview",
      true,false        
    ),
  },
  referringDoctor: {
    "ui:options": {
      placeholder: "Referring Doctor",
      default: ""
    },
    "ui:readonly": iif( mode === "preview",
      true,false        
    ),
  },
  employer: {
    "ui:widget": iif(
      (portalType === "provider" || portalType === "clinic"),
      "hidden",
    ),
    "ui:options": {
      placeholder: "Employer"
    },
    "ui:readonly": iif( mode === "preview",
      true,false        
    ),
  },
  serviceType: {
    "ui:widget": AsyncSelectWidget,
    "ui:options": {
      placeholder: "Select Service Type"
    },
    requestCacheKey: "serviceTypes",
    request: () =>
      api.common.fetchServiceTypes().then((states) =>
        states.map((state) => ({
          label: state.name,
          value: state.id
        }))
      ),
    selectItemOnLoad: true,
    "ui:readonly": iif( mode === "preview",
      true,false        
    ),
  },
  spokenLanguage: {
    "ui:options": {
      placeholder: "Select Language Spoken",
      default: 'English'
    },
    "ui:readonly": iif( mode === "preview",
      true,false        
    ),
  },
  paymentType: {
    "ui:widget": AsyncSelectWidget,
    "ui:options": {
      placeholder: "Select Payment By"
    },
    request: () => {
      if (portalType === PortalTypes.PARTNER) {
        return [
          {
            value: PAYMENT_TYPE.OKLAHOMA_COUNTY,
            label: PAYMENT_TYPE_DISPLAY[PAYMENT_TYPE.OKLAHOMA_COUNTY]
          },
          {
            value: PAYMENT_TYPE.CONNECTS_BENEFIT,
            label: PAYMENT_TYPE_DISPLAY[PAYMENT_TYPE.CONNECTS_BENEFIT]
          }
        ];
      }

      return [
        ((portalType === PortalTypes.PROVIDER &&
        hasPaymentAttached) || (portalType === PortalTypes.ADMIN)) && {
          value: PAYMENT_TYPE.PROVIDER,
          label: PAYMENT_TYPE_DISPLAY[PAYMENT_TYPE.PROVIDER]
        },
        ((portalType === PortalTypes.CLINICAL && 
        hasPaymentAttached) || (portalType === PortalTypes.ADMIN)) && {
          value: PAYMENT_TYPE.CLINIC,
          label: PAYMENT_TYPE_DISPLAY[PAYMENT_TYPE.CLINIC]
        },
        ((portalType === PortalTypes.CLINICAL &&
        skylerClinic) || (portalType === PortalTypes.ADMIN)) && {
          value: PAYMENT_TYPE.CONNECTS_BENEFIT,
          label: PAYMENT_TYPE_DISPLAY[PAYMENT_TYPE.CONNECTS_BENEFIT]
        },
        // {
        //   value: PAYMENT_TYPE.INSURANCE,
        //   label: PAYMENT_TYPE_DISPLAY[PAYMENT_TYPE.INSURANCE]
        // },
        {
          value: PAYMENT_TYPE.SELF,
          label: PAYMENT_TYPE_DISPLAY[PAYMENT_TYPE.SELF]
        }
      ].filter((x) => x);
    },
    requestRefreshDeps: [hasPaymentAttached],
    selectItemOnLoad: true,
    "ui:readonly": iif( mode === "preview",
      true,false        
    ),
  },
  notes: {
    "ui:gridColumnProps": { xs: 12 },
    //"ui:widget": iif(mode === "preview", "hidden", "textarea"),
    "ui:widget": "textarea",
    "ui:options": {
      placeholder: "Notes for Skyler Health",
      rows: 3
    },
    "ui:readonly": iif( mode === "preview",
      true,false        
    ),
     "ui:emptyValue": ""
  }
});

function customValidate(formData: any, errors: any) {
  if (!isValidDateFormat(formData.dateOfBirth, DATE_FORMATS.DATE_ONLY)) {
    errors.dateOfBirth.addError("Please enter date in MM/DD/YYYY");
  }

  return errors;
}

function transformErrors(errors: any[]) {
  return errors.map((error) => {
    if (error.name === "required") {
      error.message = "Please fill in this field";
    } else if (error.name === "format") {
      error.message = "Please enter a valid value";
    }
    return error;
  });
}

export function useReferralFormConfig(props: Partial<SchemaProps>) {
  const withDefaultProps = defaults(props, {
    mode: "create",
    type: "new",
    hasPaymentAttached: true
  });

  if (portalType === PortalTypes.PARTNER)
    withDefaultProps.portalType = PortalTypes.PARTNER

  const schema = useMemo(
    () => getReferralSchema(withDefaultProps),
    [withDefaultProps]
  );

  const uiSchema = useMemo(() => {
    return getReferralUISchema(withDefaultProps);
  }, [withDefaultProps]);

  return {
    schema,
    uiSchema,
    customValidate,
    transformErrors
  };
}
