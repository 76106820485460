import { useRequest } from "ahooks";
import { formatDate } from "../helpers/date";
import { MemberEventType, MemberServiceType } from "../types/appointment-types";
import { api } from "../api-client";

export default function LeftTimeline({
  memberService,
  events
}: {
  memberService: MemberServiceType;
  events: MemberEventType[];
}) {
  const { data: timelineResponse, loading: timelineLoading } = useRequest(
    () => api.common.fetchConfiguration("TIMELINE_DESCRIPTIONS"),
    {
      cacheKey: "TIMELINE_DESCRIPTIONS"
    }
  );

  const descriptionMap = timelineResponse?.value ?? {};

  return (
    <>
      <h5 className="mb-3">Timeline</h5>
      <div className="left-timeline ps-3">
        <ul className="list-unstyled events">
          {events?.map((event) => {
            let summary = null;
            try {
              if (
                event.event === "appointment-scheduled" &&
                memberService.appointment?.timeSlot?.startDate
              ) {
                summary = `It is scheduled on ${formatDate(
                  memberService.appointment.timeSlot.startDate
                )}.`;
              }
            } catch (e) {
              console.error(e);
            }

            return (
              <li key={event.id} className="event-list">
                <div>
                  <div className="d-flex">
                    <div className="event-date text-center me-4 flex-shrink-0">
                      <div className=" avatar-sm rounded-circle bg-soft-primary">
                        <span className="fs-16 avatar-title text-primary fw-semibold">
                          {("0" + formatDate(event.createdAt, "d")).slice(-2)}
                        </span>
                      </div>
                      <p className="mt-2">
                        {formatDate(event.createdAt, "LLL")}
                      </p>
                    </div>
                    <div className="flex-grow-1">
                      <div className="card d-inline-block">
                        <div className="card-body">
                          <h5 className="mt-0">
                            {descriptionMap?.[event.event] ?? event.event}
                            {summary ? <span> {summary}</span> : null}
                          </h5>
                          <p className="m-0 p-0">
                            {formatDate(event.createdAt, "hh:mm a")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
