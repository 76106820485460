export const menuItems = [
  // {
  //   key: "dashboards",
  //   icon: "home",
  //   label: "Dashboards",
  //   isTitle: true,
  //   url: "/dashboard",
  // },
  {
    key: "referrals",
    icon: "file-text",
    label: "Referrals",
    isTitle: true,
    url: "/referrals"
  },
  {
    key: "billing",
    icon: "credit-card",
    label: "Billing",
    isTitle: true,
    url: "/billing"
  }
  // {
  //   key: "services",
  //   icon: "file-text",
  //   label: "Services",
  //   isTitle: true,
  //   url: "/services",
  // },
];
