//import ReferExistingField from "@/apps/common/pages/NewReferral/ReferExistingField";
import { useMemo } from "react";
import { api } from "../api-client";
import AsyncSelectWidget from "../components/form-widgets/AsyncSelectWidget";
import { defaults } from "lodash-es";

type SchemaProps = {
  mode: "create" | "edit" | "preview";
}
function isValidUSZip(sZip:string) {
  return /^\d{5}$/.test(sZip);
}
function iif(condition: boolean, then: any, otherwise: any = undefined) {
  return condition ? then : otherwise;
}

//const portalType = getPortalType();

const getTeleHealthLocationSchema = ({mode}: SchemaProps) => ({
  type: "object",
  required: [
    "streetAddress1",
    "city",
    "state",
    "zip",
    "description",
  ],
  properties: {
    streetAddress1: {
      type: "string",
      title: "StreetAddress1",
      description: "Please enter your street address"
    },
    streetAddress2: {
      type: "string",
      title: "StreetAddress2",
      description: "Please enter your street address"
    },
    city: {
      type: "string",
      title: "City",
      description: "Please enter your city"
    },
    state: {
      type: "number",
      title: "State",
      description: "Please select your state"
    },
    zip: {
      type: "string",
      title: "Zip Code",
      description: "Please enter your zipcode",
    },
    description: {
      title: "Please describe this location",
      type: "string",
      enum: [
        "Home",
        "Work",
        "School",
        "Community",
        "Other"
      ]
    },   
  }
});

const getTeleHealthLocationUISchema = ({
  mode,
}: SchemaProps) => ({
 
  "ui:submitButtonOptions": {
    norender: mode === "preview",
  },

  "ui:layoutOptions": { gridColumnProps: { xs: 6 } },

  streetAddress1: {
    "ui:options": {
      placeholder: "Street Address"
    },

    "ui:readonly": iif (mode === "preview", true)

  },
  streetAddress2: {
    "ui:options": {
      placeholder: "Street Address"
    },
    "ui:readonly": iif (mode === "preview", true)
  },
  city: {
    "ui:options": {
      placeholder: "City"
    },
    "ui:readonly": iif (mode === "preview", true)
  },
  state: {
    "ui:widget": AsyncSelectWidget,
    "ui:readonly": true,
    "ui:options": {
      placeholder: "Select State"
    },
    requestCacheKey: "states",
    request: () => api.auth.fetchStatesforReferrals(),
    selectItemOnLoad: true
  },
  zip:{
    "ui:readonly": iif (mode === "preview", true),    
  },
  description:{
    "ui:readonly": iif (mode === "preview", true),   
    "ui:options": {
      placeholder: "Describe your location"
    }, 
  }
});

function customValidate(formData: any, errors: any) {
//   if (!isValidDateFormat(formData?.dateOfBirth, DATE_FORMATS.DATE_ONLY)) {
//     errors.dateOfBirth.addError("Please enter date in MM/DD/YYYY");
//   }
if ( !isValidUSZip(formData?.zip) ){
  errors.zip.addError ("Pleaser enter a valid 5 digit US Zip Code")
}
 
  //console.debug ('errors ' + JSON.stringify(errors))
  return errors;
}

function transformErrors(errors: any[]) {
  return errors.map((error) => {
    if (error.name === "required") {
      error.message = "Please fill in this field";
    } else if (error.name === "format") {
      error.message = "Please enter a valid value";
    }
    return error;
  });
}

export function useTeleHealthLocationFormConfig(props: Partial<SchemaProps>) {
  const withDefaultProps = defaults(props, {
    mode: "create",
  });


  const schema = useMemo(
    () => getTeleHealthLocationSchema(withDefaultProps),
    [withDefaultProps]
  );

  const uiSchema = useMemo(() => {
    return getTeleHealthLocationUISchema(withDefaultProps);
  }, [withDefaultProps]);

  return {
    schema,
    uiSchema,
    customValidate,
    transformErrors
  };
}