import { yupResolver } from "@hookform/resolvers/yup";
import FeatherIcons from "feather-icons-react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as yup from "yup";

// components
import { FormInput, VerticalForm } from "@/apps/common/components/";

import { useTitle } from "../../helpers/useTitle";
import AuthLayout from "./AuthLayout";
import { useRequest } from "ahooks";
import { api } from "../../api-client";

// images

interface UserData {
  email: string;
}

/* bottom link */
const BottomLink = () => {
  const { t } = useTranslation();

  return (
    <Row className="mt-3">
      <Col xs={12} className="text-center">
        <p className="text-muted">
          {t("Back to")}{" "}
          <Link to={"/auth/login"} className="text-primary fw-bold ms-1">
            {t("Login")}
          </Link>
        </p>
      </Col>
    </Row>
  );
};

const ForgetPassword = () => {
  const { t } = useTranslation();

  useTitle("Forgot Password");

  const {
    run: requestPasswordReset,
    loading,
    error,
    data
  } = useRequest((email) => api.auth.requestPasswordReset(email), {
    manual: true
  });

  /*
   * form validation schema
   */
  const schemaResolver = yupResolver(
    yup.object().shape({
      email: yup.string().required(t("Please enter Email"))
    })
  );

  /*
   * handle form submission
   */
  const onSubmit = (formData: UserData) => {
    console.log(formData["email"]);
    requestPasswordReset(formData["email"]);
  };

  console.log({ loading, error, data });

  return (
    <AuthLayout bottomLinks={<BottomLink />}>
      <h4 className="h4 mb-0 mt-3">{t("Reset Password")}</h4>
      <p className="text-muted mt-1 mb-4">
        {t(
          "Enter your email address and we'll send you an email with instructions to reset your password."
        )}
      </p>

      {error ? (
        <Alert variant="danger" className="my-2">
          An error occurred. Please try again.
        </Alert>
      ) : data ? (
        <Alert variant="success">
          Please check your email for further instructions.
        </Alert>
      ) : null}

      {data && !error ? null : (
        <VerticalForm<UserData>
          onSubmit={onSubmit}
          resolver={schemaResolver}
          defaultValues={{}}
          formClass="authentication-form"
        >
          <FormInput
            label={t("Email Address")}
            type="email"
            name="email"
            startIcon={<FeatherIcons icon={"mail"} className="icon-dual" />}
            placeholder={t("hello@domain.com")}
            containerClass={"mb-3"}
          />

          <div className="mb-0 text-center">
            <Button className="w-100" type="submit" disabled={loading}>
              {t("Submit")}
            </Button>
          </div>
        </VerticalForm>
      )}
    </AuthLayout>
  );
};

export default ForgetPassword;
