import { yupResolver } from "@hookform/resolvers/yup";
import FeatherIcons from "feather-icons-react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";

// components
import { FormInput, VerticalForm } from "@/apps/common/components/";

import { useTitle } from "../../helpers/useTitle";
import AuthLayout from "./AuthLayout";
import { useRequest } from "ahooks";
import { api } from "../../api-client";
import { useMemo } from "react";
import { useAuthStore } from "../../store/useAuthStore";

// images

interface ResetPasswordData {
  password: string;
  passwordConfirmation: string;
}

/* bottom link */
const BottomLink = () => {
  const { t } = useTranslation();

  return (
    <Row className="mt-3">
      <Col xs={12} className="text-center">
        <p className="text-muted">
          {t("Back to")}{" "}
          <Link to={"/auth/login"} className="text-primary fw-bold ms-1">
            {t("Login")}
          </Link>
        </p>
      </Col>
    </Row>
  );
};

export default function ResetPassword() {
  const { t } = useTranslation();

  useTitle("Forgot Password");

  const [searchParams] = useSearchParams();
  const { user, loggedIn, handleToken } = useAuthStore();

  const code = useMemo(() => {
    return searchParams.get("code") || "";
  }, [searchParams]);

  const {
    runAsync: resetPassword,
    loading,
    error,
    data
  } = useRequest(
    ({ code, password }) => api.auth.resetPassword(code, password),
    {
      manual: true
    }
  );

  /*
   * form validation schema
   */
  const schemaResolver = yupResolver(
    yup.object().shape({
      password: yup.string().required(t("Please enter Password")),
      passwordConfirmation: yup
        .string()
        .required(t("Please confirm your Password"))
        .test("passwords-match", "Passwords must match", function (value) {
          return this.parent.password === value;
        })
    })
  );

  /*
   * handle form submission
   */
  const onSubmit = async (formData: ResetPasswordData) => {
    console.log(formData);
    const response = await resetPassword({
      code,
      password: formData["password"]
    });
    handleToken(response.data.jwt);
  };

  return (
    <AuthLayout bottomLinks={<BottomLink />}>
      {loggedIn || user ? <Navigate to="/"></Navigate> : null}

      <h4 className="h4 mb-0 mt-3">{t("Reset Password")}</h4>
      <p className="text-muted mt-1 mb-4">
        {t(
          "Enter your email address and we'll send you an email with instructions to reset your password."
        )}
      </p>

      {error ? (
        <Alert variant="danger" className="my-2">
          An error occurred. Please try again.
        </Alert>
      ) : data ? (
        <Alert variant="success">
          Please check your email for further instructions.
        </Alert>
      ) : null}

      {data && !error ? null : (
        <VerticalForm<ResetPasswordData>
          onSubmit={onSubmit}
          resolver={schemaResolver}
          defaultValues={{}}
          formClass="authentication-form"
        >
          <FormInput
            type="password"
            name="password"
            label={t("New Password")}
            startIcon={<FeatherIcons icon={"lock"} className="icon-dual" />}
            placeholder={t("Enter your new Password")}
            containerClass={"mb-3"}
          ></FormInput>

          <FormInput
            type="password"
            name="passwordConfirmation"
            label={t("Confirm your Password")}
            startIcon={<FeatherIcons icon={"lock"} className="icon-dual" />}
            placeholder={t("Confirm your new Password")}
            containerClass={"mb-3"}
          ></FormInput>

          <div className="mb-0 text-center">
            <Button className="w-100" type="submit" disabled={loading}>
              {t("Submit")}
            </Button>
          </div>
        </VerticalForm>
      )}
    </AuthLayout>
  );
}
