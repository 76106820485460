import { Col, Row, Modal, Card, Container, ListGroup, ListGroupItem, Badge } from "react-bootstrap";
import { api } from "@/apps/common/api-client";
import { useEffect, useState, useMemo } from "react";
import { useRequest } from "ahooks";
import { AdminNoteType, UserType } from "@/apps/common/types/appointment-types";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import Table from "@/apps/common/components/Table";
import API_BASE_URL from "@/config"
import { useNavigate } from "react-router-dom";
//import userAvatar from "/src/assets/images/users/avatar-11.png"
import FiltersBar from "../MemberService/filtersBar";
//
//import userAvatar from "../../../assets/images/users/avatar-11.png";
//import { Link } from "react-router-dom";

// components
import PageTitle from "@/apps/common/components/PageTitle";
//import MemberServiceDetails from "../../components/MemberServiceDetails/MemberServiceDetails";



export default function Members() {

    type rowData = {
        id: number
        name: string
        email: string
        phoneNumber: string
        state: string
    }

    const [selectedMember, setSelectedMember] = useState<UserType | null>(null)
    const [localDomains, setLocalDomains] = useState<boolean>(false)
    const [filters, setFilters] = useState<any>({
        followUp: true
    });

    const navigate = useNavigate();

    const handleRowClick = (row: any) => {
        const member = members?.find((x) => {
            return (x.id == row.values.id)

        })
        setSelectedMember(member!)
        setDisplayDetails(true)

    };

    const columns = [
        {
            Header: "Id",
            accessor: "id",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.id}
                </a>
            )
        },
        {
            Header: "Name",
            accessor: "name",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.name}
                </a>
            )
        },
        {
            Header: "email",
            accessor:
                "email",
            sort: false,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.email}
                </a>
            )
        },
        {
            Header: "phoneNumber",
            accessor:
                "phoneNumber",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.phoneNumber}
                </a>
            )
        },
        {
            Header: "state",
            accessor:
                "state",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.state}
                </a>
            )

        },
        {
            Header: "createdAt",
            accessor:
                "createdAt",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {new Date(row.values.createdAt).toDateString()}
                </a>
            )

        },

    ];

    const [members, setMembers] = useState<UserType[]>()
    const [displayDetails, setDisplayDetails] = useState<boolean>(false)
    const handleClose = () => setDisplayDetails(false)

    const { data: _members = [] } = useRequest(
        () =>

            fetchAllMembers(filters),
        {
            refreshDeps: [filters],
            debounceWait: 300
        }
    );

    async function fetchAllMembers(filters: string) {

        api.user.fetchAllMembers({ filters }).then((res) => {
            console.debug('Fetached all members - ' + res.length)
            setMembers(res)
            setRows([])

            res?.forEach((user: UserType) => {

                // if (user?.admin_notes.length > 0)
                //     console.debug('member - ' + JSON.stringify(user?.admin_notes))

                const row = {
                    id: user?.id,
                    name: user?.firstName + ' ' + user?.lastName,
                    email: user?.email ? user?.email : "N/A",
                    phoneNumber: user?.phoneNumber ? user?.phoneNumber : "N/A",
                    state: user?.state?.name ? user?.state?.name : "N/A",
                    createdAt: user?.createdAt ? user?.createdAt : "N/A"
                    //appointmentDate: x?.appointment?.timeSlot?.startDate ? new Date(x?.appointment?.timeSlot?.startDate).toString() : "N/A",
                }
                //showTable = true;
                setRows(prev => [...prev, row])
            })
            return res
        })
    }

    useEffect(() => {
        if (API_BASE_URL.API_URL.includes('localhost') ||
            API_BASE_URL.API_URL.includes('dev') ||
            API_BASE_URL.API_URL.includes('beta')) {
            setLocalDomains(true)
        }
    }, [members]);


    const a: rowData[] = [];
    const [rows, setRows] = useState(a);

    return (
        <>
            <PageTitle
                title={"Members"}
            />

            <Row>
                <Col>
                    <FiltersBar values={filters} onChange={setFilters} />
                </Col>
            </Row>

            <div>
                <Table
                    columns={columns}
                    data={rows}
                    pageSize={20}
                    isSortable={true}
                    pagination={true}
                    isSearchable={true}
                />
            </div>

            <Modal show={displayDetails} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>User Details - {selectedMember?.firstName + ' ' + selectedMember?.lastName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <strong>Admin Notes:</strong>
                        </Row>
                        <ListGroup as="ol" >
                            {
                                selectedMember?.admin_notes?.map((v: AdminNoteType, i: number) => (
                                    <ListGroup.Item as="li"  >

                                        <Badge>
                                            {new Date(v.createdAt).toDateString()}
                                        </Badge>
                                        <div className="p-2 bg-light border">{v.note}</div>
                                    </ListGroup.Item>
                                )
                                )
                            }
                        </ListGroup>

                    </Container>
                </Modal.Body>

            </Modal>
        </>
    );
}


