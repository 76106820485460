import { Spinner, Alert, Card, Container, Row, Col, Button, Modal, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import Table from "@/apps/common/components/Table";
import React, { useEffect, useState } from "react";
import { api } from "@/apps/common/api-client";
import { useRequest } from "ahooks";
import { AccountType, InvoiceType, UserType, MemberServiceType } from "@/apps/common/types/appointment-types";
import { dateSort } from "@/apps/common/helpers/date";
import { useNavigate } from "react-router-dom"

export default function Invoice({ user, account }: { user: Partial<UserType> | null, account: Partial<AccountType> | null }) {


    const [invoiceRows, setInvoiceRows] = useState<invoiceRowData[]>([]);
    const [memberServices, setMemberServices] = useState<MemberServiceType[]>([]);
    const [billingAccount, setBillingAccount] = useState<Partial<AccountType> | null>();
    const [showProcessInvoiceDlg, setShowProcessInvoiceDlg] = useState<boolean>(false);
    const [selectedInvoice, setSelectedInvoice] = useState<invoiceRowData>();
    const [alertMessage, setAlertMessage] = useState<string>();
    const [showAlertMessage, setShowAlertMessage] = useState<boolean>(false);
    const [displayLoadingSpinner, setDisplayLoadingSpinner] = useState<boolean>(true);
    const [displayTableAlert, setDisplayTableAlert] = useState<boolean>(false);
    const [tableAlertMessage, setTableAlertMessage] = useState<string>();

    const navigate = useNavigate();

    const fetchedIvoices = () => {
        //console.debug('Finished fetchhing invoices')
        setDisplayLoadingSpinner(false)
    }

    const showAlert = () => {
        setShowAlertMessage(true)
        setTimeout(() => {
            setShowAlertMessage(false);
        }, 2000);

    }

    const showTableAlert = () => {
        setDisplayTableAlert(true)
        setTimeout(() => {
            setDisplayTableAlert(false);
        }, 2000);

    }

    const updateInvoicePaidStatus = () => {
        //console.debug('Mark invoice as paid.')
        const data = {
            id: selectedInvoice?.id
        }

        if (selectedInvoice?.id) {
            api.invoice.update(selectedInvoice.id, { paymentReceivedDate: new Date() }).then(
                (res) => {
                    //console.debug('Succesfully marked invoice as paid')
                    // Update the invoice for submissionDate 
                    _fetchAllInvoices()
                    setAlertMessage('Invoice has been successfully updated as PAID')
                    showAlert()
                },
                (err) => {
                    console.error('Error updating invoice as PAID')
                    setAlertMessage('Error occured while updating the invoice as PAID. Please try again.')
                    showAlert()
                }
            )
        }
    }

    const submitInvoice = () => {
        //console.debug('Submitting invoice.')
        const data = {
            id: selectedInvoice?.id
        }
        api.invoice.submitInvoice(data).then(
            (res) => {
                //console.debug('Successfully submitted invoice')
                // Update the invoice for submissionDate 

                if (selectedInvoice?.id)
                    api.invoice.update(selectedInvoice.id, { submissionDate: new Date() })

                _fetchAllInvoices()
                setAlertMessage('Invoice has been successfully submitted')
                showAlert()
            },
            (err) => {
                console.error('Error submitting invoice')
                setAlertMessage('Error occured while submitting the invoice. Please try again.')
                showAlert()
            }
        )
    }

    const processInvoice = (row: any) => {
        //console.debug('Invoice Number ' + JSON.stringify(row.values.invoiceNumber))
        let _invoice: Partial<InvoiceType>;
        invoiceRows.forEach((invoice) => {
            if (invoice.invoiceNumber === row.values.invoiceNumber) {
                setSelectedInvoice(invoice)
            }

        })
        //setSelectedInvoice(row.values)
        setShowProcessInvoiceDlg(true)
    };

    const deleteInvoice = (row: any) => {
        //console.debug('Deleting Invoice Number ' + JSON.stringify(row.values.invoiceNumber))
        invoiceRows.forEach((invoice) => {
            if (invoice.invoiceNumber === row.values.invoiceNumber) {
                api.invoice.delete(invoice.id).then(
                    (res) => {
                        //console.debug('Successfully deleted invoice - ' + invoice.id)
                        _fetchAllInvoices()
                        setTableAlertMessage('Successfully deleted Invoice - ' + invoice.invoiceNumber)
                        showTableAlert()
                    },
                    (err) => {
                        console.error('Error deleteing invoice - ' + invoice.id)
                        setTableAlertMessage('An ERROR occured while deleting Invoice - ' + invoice.invoiceNumber)
                        showTableAlert()
                    }
                )

            }
        }
        )
    }

    useEffect(() => {
        //console.debug('fetching ....')
        _fetchAllInvoices()
        _fetchBillingAccount()
    }, [user, account]);

    const { loading: loadingInvoices, data: _invoices = [], run: _fetchAllInvoices } = useRequest(
        () =>
            fetchAllInvoices(),
        {
            //refreshDeps: [filters],
            //debounceWait: 300,
            //pollingInterval: 900,
            ready: !!user?.id || !!account,
            onSuccess: fetchedIvoices,
            manual: true

        }
    );

    const { data: _billingAccount = [], run: _fetchBillingAccount } = useRequest(
        () =>
            fetchBillingAccount(),
        {
            //refreshDeps: [filters],
            debounceWait: 3000,
            //pollingInterval: 9000,
            ready: !!user?.id && !account,

        }
    );

    async function fetchBillingAccount() {
        // Fetch Billing Account, if any for this user
        //console.debug('Fetching Billing Accounts for userId ' + user?.id)
        const filter = {
            users: {
                id: [user?.id]
            }
        }
        api.accounts.fetchAllAccounts({ populate: ['users'], filters: filter }).then(
            (res) => {
                //console.debug('All Billing Accounts - ' + JSON.stringify(res))
                setBillingAccount(res[0])

            },
            (err) => {
                console.error('ERROR fetching Billing Accounts - ' + JSON.stringify(err))
            }
        )
    }

    async function fetchAllInvoices() {

        console.debug('Fetching All Invoices')

        const users = [];
        let filters;

        if (user?.id) {

            if (user) {
                users.push(user.id)
            }
        }

        if (account) {
            //console.debug('Account - ' + JSON.stringify(account))
            setBillingAccount(account)
            account.users?.forEach(
                (user) => users.push(user.id)
            )
        }

        let _users = '['

        users.forEach(
            (userId) => {
                _users = _users + ' ' + userId.toString() + ', '
            }
        )
        _users = _users + ']'

        //console.debug (_users)


        filters = {
            $and: [
                {
                    user: {
                        id: {
                            $in: users
                        }
                    }
                },
                {
                    invoice: {
                        $notNull: 'id'
                    }
                }
            ]
        };

        // Fetch all invoices for this user
        if (users.length > 0) {
            //console.debug('Fetching invoices for users ' + _users)
            api.memberServices.findManyforAdmin({ filters }).then((res) => {
                
                setMemberServices(res)
                //console.debug('Response ' + JSON.stringify(res))
                setInvoiceRows([])
                res.forEach((x) => {

                    //console.debug('invoice - ' + JSON.stringify(x?.invoice))

                    const row = {
                        id: x?.invoice?.id,
                        invoiceNumber: x?.invoice?.invoiceNumber,
                        memberName: `${x?.user?.firstName} ${x?.user?.lastName}`,
                        appointmentDate: x?.appointment?.timeSlot?.startDate ? new Date(x?.appointment?.timeSlot?.startDate).toDateString() : 'N/A' ,
                        generationDate: new Date(x?.invoice?.generationDate).toLocaleString(),
                        submissionDate: x?.invoice?.submissionDate ? new Date(x?.invoice?.submissionDate).toDateString() : 'pending',
                        paymentReceivedDate: x?.invoice?.paymentReceivedDate ? new Date(x?.invoice?.paymentReceivedDate).toDateString() : 'pending',
                        invoice: x?.invoice?.invoiceReport?.url ? x?.invoice?.invoiceReport?.url : '',
                        processInvoice: x?.invoice?.invoiceNumber,
                        deleteInvoice: x?.invoice?.invoiceNumber,
                    }

                    setInvoiceRows(prev => [...prev, row])

                })
                return res
            },
                (err) => { })
        }
    }

    type invoiceRowData = {
        id: number
        invoiceNumber: string
        memberName: string
        appointmentDate: string
        generationDate: string
        submissionDate: string
        paymentReceivedDate: string
        invoice: string
    }

    const handleRowClick = (row: any) => {
        console.debug('handleRowClick ' + JSON.stringify(row.values.memberName))
        let _invoice: Partial<InvoiceType>;
        memberServices.forEach((memberService) => {
            console.debug ('memberService - ' + JSON.stringify(memberService))
            if (memberService?.invoice?.invoiceNumber === row.values.invoiceNumber) {
                console.debug ('Found')
                navigate(`/memberServiceDetails/${memberService?.id}`)                
            }

        })
    };

    const invoiceColumns = [
        {
            Header: "Invoice Number",
            accessor: "invoiceNumber",
            sort: true,
            Cell: ({ row }: { row: any }) => (

                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.invoiceNumber}
                </a>

        )
        },
        {
            Header: "Member",
            accessor: "memberName",
            sort: true,
            Cell: ({ row }: { row: any }) => (

                    < a
                        onClick={() => handleRowClick(row)}
                    >
                        {row.values.memberName}
                    </a>

            )
        },
        {
            Header: "Appointment Date",
            accessor: "appointmentDate",
            sort: true,
            sortType: dateSort,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.appointmentDate}
                </a>
        )
        },
        {
            Header: "Generated Date",
            accessor: "generationDate",
            sort: true,
            sortType: dateSort,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.generationDate}
                </a>
        )
        },
        {
            Header: "Sent Date",
            accessor: "submissionDate",
            sort: true,
            sortType: dateSort,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.submissionDate}
                </a>
        )
        },
        {
            Header: "Paid Date",
            accessor: "paymentReceivedDate",
            sort: true,
            sortType: dateSort,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.paymentReceivedDate}
                </a>
        )
        },
        {
            Header: "Invoice",
            accessor: "invoice",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                < a
                    href={row.values.invoice}
                    target="_blank"
                >
                    {row.values.invoice ? `Invoice Report` : `No Invoice PDF`}
                </a>
            )
        },
        {
            Header: "Process Invoice",
            accessor: "processInvoice",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                <>
                    <Button size='sm'
                        onClick={() => processInvoice(row)}

                    >
                        {/* <FiRefreshCw/> */}
                        Process
                    </Button>

                </>

            )
        },
        {
            Header: "Delete Invoice",
            accessor: "deleteInvoice",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                <>
                    <Button size='sm'
                        onClick={() => deleteInvoice(row)}

                    >
                        Delete

                    </Button>

                </>

            )
        },
    ];


    return (
        <>
            {displayLoadingSpinner ?
                (

                    <div className="d-flex justify-content-center">
                        <Spinner>  </Spinner>
                    </div>

                )
                :
                (
                    <>
                        <Container fluid>
                            <Row>
                            <Button onClick={()=>{_fetchAllInvoices()}}>Refresh List</Button>
                            </Row>
                            <Row>
                            <Alert show={displayTableAlert}>
                                {tableAlertMessage}
                            </Alert>
                            <p><strong>Dates displayed in {Intl.DateTimeFormat().resolvedOptions().timeZone} TimeZone</strong></p>                            
                            <Table
                                columns={invoiceColumns}
                                data={invoiceRows}
                                pageSize={20}
                                isSortable={true}
                                pagination={true}
                                isSearchable={true}
                            />
                            </Row>

                        </Container>
                    </>)
            }
            <Modal
                show={showProcessInvoiceDlg}
                centered
                onHide={() => {
                    setShowProcessInvoiceDlg(false)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title as="h4">Process Invoice - {selectedInvoice?.invoiceNumber}</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Container>
                        <Row>Billing Account:</Row>
                        {billingAccount ? (
                            <>
                                <Row>{billingAccount?.name}</Row>
                                <Row>{billingAccount?.email}</Row>
                            </>
                        ) :
                            (
                                <>
                                    No Billing Account Attached to this Invoice.
                                    <br></br>
                                    Send invoice to:
                                    <br />
                                    {user?.firstName} ' '  {user?.lastName}
                                    <br />
                                    {user?.email}
                                </>
                            )
                        }
                    </Container>

                </Modal.Body>

                <Modal.Footer>
                    <ButtonToolbar >
                        <ButtonGroup className="m-2, p-1">
                            {/* <Button className="me-2 px-1" onClick={() => scheduleNewSession()}>
                                                        <div className="d-flex align-items-center px-1">
                                                            <FeatherIcon icon="trash" />
                                                            <span className="mx-1">Schedule New Session</span>
                                                        </div>
                                                    </Button> */}
                            <Button
                                className="btn btn-sm btn-primary me-2 pe-1"
                                onClick={() => {
                                    submitInvoice()
                                }
                                }
                            >
                                <div className="d-flex align-items-center px-1">
                                    <span className="mx-1">Send Invoice</span>
                                </div>
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup className="m-2, p-1">
                            {/* <Button className="me-2 px-1" onClick={() => scheduleNewSession()}>
                                                        <div className="d-flex align-items-center px-1">
                                                            <FeatherIcon icon="trash" />
                                                            <span className="mx-1">Schedule New Session</span>
                                                        </div>
                                                    </Button> */}
                            <Button
                                className="btn btn-sm btn-primary me-2 pe-1"
                                onClick={() => {
                                    updateInvoicePaidStatus()
                                }}
                            >
                                <div className="d-flex align-items-center px-1">
                                    <span className="mx-1">Mark Invoice as Paid</span>
                                </div>
                            </Button>
                        </ButtonGroup>

                    </ButtonToolbar>
                    <Alert show={showAlertMessage}>
                        {alertMessage}
                    </Alert>
                </Modal.Footer>
            </Modal>
        </>
    )
}
