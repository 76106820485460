import { api } from "@/apps/common/api-client";
import PaymentContextProvider from "@/apps/common/components/contexts/PaymentContextProvider";
import { useRequest } from "ahooks";
import { PropsWithChildren } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, Navigate} from "react-router-dom";



function ProviderLayoutWithoutContext({ children }: PropsWithChildren) {
  const { data: serviceProvider, loading } = useRequest(() =>
    api.serviceProviders
      .findMany({ populate: ["states"] })
      .then((providers) => providers[0])
  );

  const navigate = useNavigate();

  if (loading || !serviceProvider) {
    return (
      <div className="text-center p-4 mt-4">
        <Spinner
          style={{ width: 100, height: 100 }}
          animation="border"
          variant="info"
        />
      </div>
    );
  }
  //const myaccounts_url = new URL ('/my-account')
  const myaccounts_url = new URL (window.location.origin + '/my-account' )
  console.debug (myaccounts_url)


  if ((serviceProvider.status === "pending") && (myaccounts_url.href !== window.location.href)) {
    // return (
    //   <Card className="mt-3">
    //     <Card.Body>
    //       Your request to provide services thru Skyler Health is being reviewed.
    //       We will inform you regarding your approval via email.
    //     </Card.Body>
    //   </Card>
    // );
    
    console.debug(window.location.href)
    return (
      <>
        <Navigate
          to={{
            pathname: myaccounts_url.pathname
          }}
        />
      </>

    );
  }

  return children;
}

export default function ProviderLayout({ children }: PropsWithChildren) {
  return (
    <PaymentContextProvider>
      <ProviderLayoutWithoutContext>{children}</ProviderLayoutWithoutContext>
    </PaymentContextProvider>
  );
}
