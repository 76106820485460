import { generateForm } from "@modules/react-jsonschema-form-bootstrap";
import validator from "@rjsf/validator-ajv8";
import { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { api } from "../api-client";
import { useAccountFormConfig } from "../forms/Account";
import { AccountType} from "../types/appointment-types";

const Form = generateForm<Partial<AccountType>>();

export default function AccountForm({
  mode,
  account,
  updateData,
  display
}: {
  mode: "register" | "admin" | "create" | "edit" | "preview";
  account: Partial<AccountType> | null;
  updateData: (data: any) => void;
  display: (value: boolean) => void;
}) {

  const [accountData, setAccountData] = useState<Partial<AccountType>>();
  const [submitMessage, setSubmitMessage] = useState<string>();
  const [displaySubmitMessage, setDisplaySubmitMessage] = useState<boolean>(false);
  const { schema, uiSchema, customValidate, transformErrors } =
    useAccountFormConfig({
      mode,
    });

  const showAlert = () => {
    setDisplaySubmitMessage(true)
    const timeoutId = setTimeout(() => {
      setDisplaySubmitMessage(false);
      display(false)
    }, 500);
  }

  console.debug('Account Details - ' + JSON.stringify(account))
  const setValues = () => {
    console.debug('SetValues - ' + JSON.stringify(account))
    const data = {
      ...(account
        ? {
          ...account,
        }
        : {}
      )
    };

    //console.debug('setting values ' + JSON.stringify(data))
    return data
  }


  useEffect(() => {
    setAccountData(setValues())
  }, [account]);

  const onSubmit = (form: any) => {

    console.debug('submitting Account data - ' + JSON.stringify(form.formData))
    const formData = form?.formData;

    const data = {
      ...formData
    }

    if (mode === "create") {
      console.debug('create Account')

      api.accounts.create(data).then(
        (res) => {
          console.debug('Successfully created the account')
          setSubmitMessage('Account was successfully created')
          showAlert()
        },
        (err) => {
          console.error('Error creating the account')
        }
      )


    }

    if (mode !== 'create') {
      if (account?.id) {

        api.accounts.update(account.id, data).then(
          (res) => {

            console.debug('Account updated -' + JSON.stringify(res))
            setSubmitMessage('Account was successfully updated')
            //setDisplaySubmitMessage(true)
            showAlert()

          },
          (err) => {
            setSubmitMessage("An error occured while updating the Account details. Please try again.")
            showAlert()
          }
        )
      }
    }

  };

  return (
    <>
      {
        <>
          <br />
          {account || mode === "create" ?
            (<Form
              formContext={accountData}
              formData={accountData as Partial<AccountType>}
              schema={schema as any}
              uiSchema={uiSchema as any}
              validator={validator}
              showErrorList={false}
              customValidate={customValidate}
              noHtml5Validate
              liveValidate={false}
              transformErrors={transformErrors}
              onSubmit={onSubmit}
            ></Form>) : null
          }
          <br />
          <br />
          <Alert variant="secondary" className="mb-0 d-md-block d-sm-none" show={displaySubmitMessage} >
            {submitMessage}
          </Alert>
        </>

      }
    </>

  );

}
