import { Button, Card, Col, Row } from "react-bootstrap";

import { api } from "@/apps/common/api-client";
import PageTitle from "@/apps/common/components/PageTitle";
import Table from "@/apps/common/components/Table";
import { MEMBER_SERVICE_STATUS } from "@/apps/common/constants";
import { getFullName } from "@/apps/common/helpers";
import { DATE_FORMATS, formatDate } from "@/apps/common/helpers/date";
import { useRequest } from "ahooks";
import FeatherIcon from "feather-icons-react";

export default function History() {
  const { data: services } = useRequest(() =>
    api.memberServices.findMany().then((res) =>
      res.map((memberService) => ({
        ...memberService,
        user: {
          ...memberService.user,
          fullName: getFullName(memberService.user)
        },
        formattedUpdatedAt: formatDate(
          memberService.updatedAt,
          DATE_FORMATS.DATE_ONLY
        )
      }))
    )
  );

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      sort: false
    },
    {
      Header: "Last Updated",
      accessor: "formattedUpdatedAt",
      sort: true
    },
    {
      Header: "Member Full Name",
      accessor: "user.fullName",
      sort: true
    },
    {
      Header: "Status",
      accessor: "status",
      sort: true
    },
    {
      Header: "Appointment time",
      accessor: "appointment.timeSlot.startDate",
      sort: false,
      Cell: ({ value }: { value: string }) => (
        <span>{value ? formatDate(value) : "Not set"}</span>
      )
    },
    {
      id: "download",
      Header: "Report",
      accessor: "providerReport.document.url",
      Cell: ({ value: providerReport, row }: { value: string; row: any }) => {
        if (
          providerReport?.trim().length &&
          [
            MEMBER_SERVICE_STATUS.COMPLETE,
            MEMBER_SERVICE_STATUS.REPORT_GENERATION_SENT
          ].includes(row.original.status)
        ) {
          return (
            <Button
              onClick={() => window.open(providerReport)}
              className="btn btn-sm btn-primary rounded-pill mx-1"
            >
              <div className="d-flex align-items-center">
                <FeatherIcon icon="download" />
                <span className="mx-1">Download report</span>
              </div>
            </Button>
          );
        } else {
          return <span>No report available</span>;
        }
      }
    }
  ];

  return (
    <>
      <PageTitle title={"Service History"} />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Table
                columns={columns}
                data={services ?? []}
                pageSize={10}
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
