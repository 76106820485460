export const menuItems = [
  {
    key: "dashboard",
    icon: "home",
    label: "Dashboard",
    isTitle: true,
    url: "/"
  },
  {
    key: "history",
    icon: "file-text",
    label: "History",
    isTitle: true,
    url: "/history"
  },
  {
    key: "billing",
    icon: "credit-card",
    label: "Billing",
    isTitle: true,
    url: "/billing"
  }
];
