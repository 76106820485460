import { FormInput, VerticalForm } from "@/apps/common/components/";
import PageTitle from "@/apps/common/components/PageTitle";
import { MemberServiceContext } from "@/apps/common/components/contexts/MemberServiceContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRequest } from "ahooks";
import { useContext, useMemo, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { api } from "../../common/api-client";

interface ServiceTypeData {
  serviceType: number;
  serviceProviderType: string;
}

/**
 * ServiceTypeSelection
 */
export default function ServiceTypeSelection() {
  const { memberService, refreshMemberService } =
    useContext(MemberServiceContext);

  const [loading, setLoading] = useState(false);

  const { data: serviceTypes } = useRequest(() =>
    api.common.fetchServiceTypes()
  );

  const schemaResolver = yupResolver(
    yup.object().shape({
      serviceType: yup.number().required("Please select service type"),
      serviceProviderType: yup
        .string()
        .required("Please select service provider")
    })
  );

  const onSubmit = async (formData: ServiceTypeData) => {
    try {
      setLoading(true);
      const data = {
        serviceProviderType: formData.serviceProviderType,
        serviceType: formData.serviceType
      };
      await api.memberServices.update(memberService!.id, data);
      await refreshMemberService();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const defaultValues = useMemo(
    () => ({
      serviceType: serviceTypes?.[0]?.id,
      serviceProviderType: memberService.serviceProviderType
    }),
    [memberService.serviceProviderType, serviceTypes]
  );

  return (
    <>
      <PageTitle
        breadCrumbItems={[{ label: "Dashboard", path: "/" }]}
        title={"Service type"}
      />
      <Card>
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={8} lg={8}>
            <Card.Body>
              {serviceTypes?.length ? (
                <VerticalForm<ServiceTypeData>
                  onSubmit={onSubmit}
                  resolver={schemaResolver}
                  defaultValues={defaultValues}
                  formClass="authentication-form row"
                >
                  <FormInput
                    label={"I need (select one of the following)"}
                    type="select"
                    name="serviceType"
                    key="serviceType"
                    containerClass={"mb-3 col-sm-12 col-md-12 col-lg-12"}
                  >
                    <option value=""></option>
                    {serviceTypes?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </FormInput>
                  <div className="mb-2 text-center d-grid col-12">
                    <Button disabled={loading} type="submit">
                      {loading ? (
                        <Spinner
                          style={{
                            height: 20,
                            width: 20,
                            marginTop: 4,
                            fontSize: 10
                          }}
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </VerticalForm>
              ) : (
                <Spinner />
              )}
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  );
}
