import { api } from "@/apps/common/api-client";
import { FormInput, InputMasks, VerticalForm } from "@/apps/common/components/";
import { ClinicProfileData } from "@/apps/common/api-client/modules/common.module";
import { LegacyUserType } from "@/apps/common/api-client/api-client.types";
import EditProfileModal from "@/apps/clinic/pages/Profile/EditProfileModal";
import ProfileDetails from "@/apps/common/components/ProfileDetails";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import { useCommonStore } from "@/apps/common/store/useCommonStore";
import { yupResolver } from "@hookform/resolvers/yup";
import FeatherIcons from "feather-icons-react";
import pick from "lodash/pick";
import { useEffect, useMemo, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useRequest } from "ahooks";
import PaymentMethods from "@/apps/common/components/PaymentMethods";

interface ClinicData {
  clinicName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
}

// interface EditClinicData {
//   editDetails: boolean;
//   editLabel: string;
// }

const clinicSchemaResolver = yupResolver(
  yup.object().shape({
    email: yup
      .string()
      .required("Please enter Email")
      .email("Please enter valid Email"),
    phoneNumber: yup.string().required("Please enter Phone"),
    firstName: yup.string().required("Please enter First name"),
    lastName: yup.string().required("Please enter Last name"),
    clinicName: yup.string().required("Please enter Clinic name"),
    address: yup.string().required("Please enter address")
  })
);

const Profile = () => {
  const [showConfirmUpdateModal, setshowConfirmUpdateModal] = useState(false);
  const [savingProfile, setSavingProfile] = useState<boolean>(false);

  const { t } = useTranslation();

  const [clinicData, setClinicData] = useState<ClinicData>();

  let clinicProfile: Partial<ClinicProfileData>;
  const { clinicUser } = useCommonStore();
  const { fetchStates, states, user } = useAuthStore();
  const [refreshView, setRefreshView] = useState<boolean>(false);

  const refresh = () => {
    setRefreshView(true);
  };
  const clinic = useMemo(() => {
    if (clinicUser?.length) {
      return clinicUser[0].attributes.clinic.data.attributes;
    }
  }, [clinicUser]);

  const clinicId = useMemo(() => {
    if (clinicUser?.length) {
      return clinicUser[0].id;
    }
  }, [clinicUser]);

  const paymentStripeId = useMemo(() => {
    if (!clinicUser.length) {
      return;
    }
    const { attributes } = clinicUser[0];
    const status =
      attributes?.clinic?.data?.attributes?.paymentProfile?.data?.attributes
        ?.stripeId;
    return status;
  }, [clinicUser]);

  useEffect(() => {
    if (!states) {
      fetchStates();
    }
  }, [fetchStates, states]);

  useEffect(() => {
    if (!clinicUser?.length) {
      return;
    }

    const clinic = clinicUser[0].attributes.user.data.attributes;

    const _clinicData: ClinicData = pick(clinic, [
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "clinicName",
      "address"
    ]);
    _clinicData.clinicName =
      clinicUser[0].attributes.clinic.data.attributes.name;
    _clinicData.address =
      clinicUser[0].attributes.clinic.data.attributes.location;
    setClinicData(_clinicData);
  }, [clinicUser]);

  const paymentMethod =
    clinicUser[0]?.attributes?.clinic?.data?.attributes?.paymentProfile?.data
      ?.attributes?.paymentMethods?.[0]?.data?.attributes.stripeId;

  const updateProfile = async () => {
    try {
      console.log("Saved Profile");
      setSavingProfile(true);

      const userData: Partial<LegacyUserType> = {
        firstName: clinicData?.firstName,
        lastName: clinicData?.lastName,
        email: clinicData?.email,
        phoneNumber: clinicData?.phoneNumber
      };
      updateUser(user!.id, userData);

      clinicProfile = {
        data: {
          name: clinicData?.clinicName,
          location: clinicData?.address
        }
      };
      updateClinicProfile();

      setSavingProfile(false);
      setshowConfirmUpdateModal(false);
    } catch (err) {
      console.log(err);
      setSavingProfile(false);
    }
  };

  const onCloseModal = () => {
    setshowConfirmUpdateModal(false);
  };

  const { loading: saving, runAsync: updateUser } = useRequest(
    api.auth.updateUser.bind(api.auth),
    {
      onSuccess: (result, params) => {
        try {
          useAuthStore.getState().fetchUser();
        } catch (error) {
          console.error(error);
        }
      },
      manual: true
    }
  );

  const { runAsync: updateClinicProfile } = useRequest(
    () => api.common.updateClinicProfile(clinicId, clinicProfile),
    {
      onSuccess: (result, params) => {
        useCommonStore.getState().fetchClinic(user!.id.toString());
      },
      manual: true
    }
  );

  const onSubmit = (formData: ClinicData) => {
    setClinicData(formData);
    setshowConfirmUpdateModal(true);
  };

  const clinicForm = clinicData ? (
    <VerticalForm<ClinicData>
      onSubmit={onSubmit}
      resolver={clinicSchemaResolver}
      defaultValues={clinicData}
      formClass="authentication-form row"
    >
      <FormInput
        label={t("Clinic name")}
        type="text"
        name="clinicName"
        startIcon={<FeatherIcons icon={"activity"} className="icon-dual" />}
        placeholder={t("Clinic name")}
        containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
      />

      <FormInput
        label={t("Address")}
        type="text"
        name="address"
        startIcon={<FeatherIcons icon={"map-pin"} className="icon-dual" />}
        placeholder={t("Your address")}
        containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
      />

      <FormInput
        label={t("First name")}
        type="text"
        name="firstName"
        startIcon={<FeatherIcons icon={"user"} className="icon-dual" />}
        placeholder={t("Your first name")}
        containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
      />
      <FormInput
        label={t("Last name")}
        type="text"
        name="lastName"
        startIcon={<FeatherIcons icon={"user"} className="icon-dual" />}
        placeholder={t("Your last name")}
        containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
      />

      <FormInput
        label={t("Email Address")}
        type="email"
        name="email"
        startIcon={<FeatherIcons icon={"mail"} className="icon-dual" />}
        placeholder={t("hello@domain.com")}
        containerClass={"mb-2 col-6"}
      />
      <InputMasks
        type="mask"
        startIcon={<FeatherIcons icon={"phone"} className="icon-dual" />}
        label={t("Mobile Phone")}
        mask={[
          "(",
          /[1-9]/,
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/
        ]}
        placeholder="(___) ___-____"
        name="phoneNumber"
        containerClass={"mb-2 col-6"}
      />

      <div className="mb-2 text-center d-grid col">
        <Button type="submit">
          {saving ? <Spinner size="sm" /> : t("Save")}
        </Button>
      </div>
    </VerticalForm>
  ) : null;

  const clinicDetails = clinic ? (
    <>
      <h4 className="mb-2 fs-15">Clinic Details</h4>
      <div className="table-responsive">
        <table className="table table-borderless mb-0 text-muted">
          <tbody>
            <tr>
              <th scope="row">State</th>
              <td>{clinic.state.data.attributes.name}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  ) : null;

  return (
    <>
      <ProfileDetails /*cardDetails={paymentMethod?.[0]?.attributes}*/>
        <>
          {clinicForm}
          {clinicDetails}

          {showConfirmUpdateModal ? (
            <EditProfileModal
              show={true}
              onClick={updateProfile}
              loading={savingProfile}
              onHide={onCloseModal}
            />
          ) : null}
        </>
      </ProfileDetails>
      <PaymentMethods userType={"clinic"} userId={null}/>
    </>
  );
};

export default Profile;
