import { AxiosInstance } from "axios";

export abstract class BaseApiModule {
  constructor(
    readonly client: AxiosInstance,
    protected readonly basePath?: string
  ) {}

  protected buildPath(path?: string | string[]): string {
    const pathParts = path ? (Array.isArray(path) ? path : [path]) : [];
    if (this.basePath) {
      pathParts.unshift(this.basePath);
    }
    return pathParts.join("/");
  }
}
