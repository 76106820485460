import { api } from "@/apps/common/api-client";
import { useRequest } from "ahooks";
import Markdown from "markdown-to-jsx";
import React from "react";
import { Accordion, Alert, Col } from "react-bootstrap";

export default function AppointmentTranscripts({
  appointmentId
}: {
  appointmentId: number;
}) {
  const { data: transcripts = [] } = useRequest(
    () => api.transcripts.getByAppointment(Number(appointmentId)),
    {
      pollingInterval: 5000
    }
  );

  if (transcripts.length === 0) {
    return null;
  }

  return (
    <Col>
      <Accordion>
        <Accordion.Item eventKey="transcripts">
          <Accordion.Header>Transcripts</Accordion.Header>
          <Accordion.Body>
            <div>
              {transcripts.map((transcript, index) => {
                const newMessage =
                  transcripts[index - 1]?.sender !== transcripts[index].sender;
                const lastMessageFromSender =
                  transcripts[index + 1]?.sender !== transcripts[index].sender;

                return (
                  <>
                    {newMessage ? (
                      <div className="mt-2">
                        <strong>{transcript.sender}</strong>:
                      </div>
                    ) : null}
                    <span key={transcript.id}>
                      {transcript.text}{" "}
                      {lastMessageFromSender && transcript.llmResponse ? (
                        <Alert variant="info">
                          <Markdown>{transcript.llmResponse}</Markdown>
                        </Alert>
                      ) : null}
                    </span>
                  </>
                );
              })}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Col>
  );
}
