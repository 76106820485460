import PageTitle from "@/apps/common/components/PageTitle";
import Table from "@/apps/common/components/Table";
import { getFullName } from "@/apps/common/helpers";
import {
  DATE_FORMATS,
  formatDate,
  formatDateOfBirth
} from "@/apps/common/helpers/date";
import { useMemo, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
//import { useRequest } from "ahooks";
//import { api } from "@/apps/common/api-client";
import { api } from "@/apps/common/api-client";
import { useRequest } from "ahooks";
import styles from "./Referrals.module.css";

const columns = [
  {
    Header: "ID",
    accessor: "id"
  },
  {
    Header: "Referral Created",
    accessor: "formattedCreatedAt",
    sort: true
  },
  {
    Header: "Patient name",
    accessor: "fullName",
    sort: true
  },
  {
    Header: "Patient DOB",
    accessor: "formattedDateOfBirth",
    sort: false
  },
  {
    Header: "Service type",
    accessor: "serviceType.name",
    sort: false
  },
  {
    Header: "Phone number",
    accessor: "phoneNumber",
    sort: false,
    Cell: ({ value }: { value: string }) => {
      const match = value.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return value;
    }
  },
  {
    Header: "Email",
    accessor: "email",
    sort: false
  },
  {
    Header: "Status",
    accessor: "memberService.status",
    sort: false,
    Cell: ({ value }: { value: string }) => (
      <span className={`services-status ${value || "referral-sent"}`}>
        {value || "referral-sent"}
      </span>
    )
  },
  {
    id: "action",
    Header: "Actions",
    accessor: "id",
    Cell: ({ value }: { value: string }) => (
      <Link to={`/referrals/${value}`}>
        <Button size="sm" variant="btn btn-primary rounded-pill width-sm">
          View
        </Button>
      </Link>
    )
  }
];

export default function Referrals() {
  const [query, setQuery] = useState<string>();
  const { data: referrals = [], loading } = useRequest(
    () =>
      api.referrals.findMany({
        query,
        populate: ["memberService", "serviceType", "state"],
        pageSize: 100
      }),
    {
      refreshDeps: [query],
      debounceWait: 300
    }
  );

  const referralsWithName = useMemo(
    () =>
      referrals.map((referral) => {
        return {
          ...referral,
          fullName: getFullName(referral),
          formattedCreatedAt: formatDate(
            referral.createdAt,
            DATE_FORMATS.DATE_ONLY
          ),
          formattedDateOfBirth: formatDateOfBirth(referral.dateOfBirth)
        };
      }),
    [referrals]
  );

  return (
    <>
      <PageTitle breadCrumbItems={[]} title={"Member referrals"}></PageTitle>
      <div>
        <div className={styles.rightPanel}>
          {/*
          totalCoupons ? ( 
          <Alert variant="success" className="mb-0">
            You have {unusedCoupons}{" "}
            {unusedCoupons > 1 ? "referrals" : "referral"} left with 50%
            discount.
          </Alert>
          ) : null */}
          <Link
            to="/referrals/new-referrals"
            className="btn btn-primary rounded-pill"
          >
            Invite Member <i className="uil uil-plus"></i>
          </Link>
        </div>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Table
                  columns={columns}
                  data={referralsWithName}
                  pageSize={100}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
