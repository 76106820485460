import { useRequest } from "ahooks";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useMemo,
  useState
} from "react";
import { api } from "../../api-client";
import { PAYMENT_STATUS } from "../../constants";
import { useCommonStore } from "../../store/useCommonStore";
import PaymentModal from "../PaymentModal";

export const PaymentContext = createContext({
  requestPayment: () => {},
  hasPaymentAttached: true
});

export default function PaymentContextProvider({
  children
}: PropsWithChildren) {
  const { addPaymentMethod } = useCommonStore();

  const [paymentModal, setShowPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const addPayment = async () => {
    try {
      setLoading(true);
      const { data } = await addPaymentMethod();
      location.replace(data.url);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const { data: paymentProfile } = useRequest(() =>
    api.payments.getPaymentProfile()
  );
  const paymentMethodStatus = useMemo(() => {
    if (!paymentProfile) {
      return PAYMENT_STATUS.ATTACHED;
    }

    return paymentProfile.paymentMethodStatus;
  }, [paymentProfile]);

  const requestPayment = useCallback(() => {
    if (paymentMethodStatus !== PAYMENT_STATUS.ATTACHED) {
      setShowPaymentModal(true);
    }
  }, [paymentMethodStatus]);

  return (
    <PaymentContext.Provider
      value={{
        hasPaymentAttached: paymentMethodStatus === PAYMENT_STATUS.ATTACHED,
        requestPayment
      }}
    >
      {children}
      <PaymentModal
        loading={loading}
        show={paymentModal}
        onClick={addPayment}
        onHide={() => setShowPaymentModal(false)}
      />
    </PaymentContext.Provider>
  );
}
