import { Button, Col, Row, Modal, Card, Container, ListGroup, ListGroupItem, Badge } from "react-bootstrap";
import { api } from "@/apps/common/api-client";
import { useEffect, useState, useMemo } from "react";
import { useRequest } from "ahooks";
import { AdminNoteType, AccountType } from "@/apps/common/types/appointment-types";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import Table from "@/apps/common/components/Table";
import API_BASE_URL from "@/config"
import { useNavigate } from "react-router-dom";
//import userAvatar from "/src/assets/images/users/avatar-11.png"
import FiltersBar from "../MemberService/filtersBar";
import AccountForm from "@/apps/common/components/Accounts"
import Invoice from "@apps/admin/components/Invoice"
//
//import userAvatar from "../../../assets/images/users/avatar-11.png";
//import { Link } from "react-router-dom";

// components
import PageTitle from "@/apps/common/components/PageTitle";
import { useParams } from "react-router-dom";
//import MemberServiceDetails from "../../components/MemberServiceDetails/MemberServiceDetails";



export default function Invoices() {

    const { id } = useParams();

    const [localDomains, setLocalDomains] = useState<boolean>(false)
    const [account, setAccount] = useState<Partial<AccountType>|null>(null)

    const navigate = useNavigate();

    useEffect(() => {
        _fetchAccount()
    }, []);

    const { data: _account, run:_fetchAccount} = useRequest(
        () =>
            fetchAccount(),
        {
            refreshDeps: [id],
            debounceWait: 500,
            //pollingInterval: 5000
        }
    );

    async function fetchAccount() {
        //console.debug('fetchAccount');

        if (id) {

            api.accounts.find(parseInt(id)).then(
                (res) => {
                    //console.debug ('account ' + JSON.stringify(res))
                    setAccount(res)
                    return res
                }
            )
        }



    }
    // useEffect(() => {
    //     if (API_BASE_URL.API_URL.includes('localhost') ||
    //         API_BASE_URL.API_URL.includes('dev') ||
    //         API_BASE_URL.API_URL.includes('beta')) {
    //         setLocalDomains(true)
    //     }
    // }, [accounts]);



    return (
        <>
            <PageTitle
                title={"Invoices"}
            />
            <Invoice user={null} account={account}></Invoice>
        </>
    );
}


