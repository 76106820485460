import { Button, Card, Col, Row } from "react-bootstrap";

import { api } from "@/apps/common/api-client";
import PageTitle from "@/apps/common/components/PageTitle";
import Table from "@/apps/common/components/Table";
import { getFullName } from "@/apps/common/helpers";
import { DATE_FORMATS, formatDate } from "@/apps/common/helpers/date";
import { unstrapiMany } from "@/apps/common/helpers/strapi";
import { useRequest } from "ahooks";
import { Link } from "react-router-dom";

export default function Billing() {
  const { data: logs, loading } = useRequest(() =>
    api.common.fetchPaymentLogs().then((res) =>
      unstrapiMany(res.data, "logs").map((log: any) => ({
        ...log,
        memberService: {
          ...log.memberService,
          user: {
            ...log.memberService.user,
            fullName: getFullName(log.memberService.user),
          },
        },
        formattedUpdatedAt: formatDate(log.updatedAt, DATE_FORMATS.DATE_ONLY),
      }))
    )
  );

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      sort: false,
    },
    {
      Header: "Last Updated",
      accessor: "formattedUpdatedAt",
      sort: true,
    },
    {
      Header: "Patient",
      accessor: "memberService.user.fullName",
      sort: true,
    },
    {
      Header: "Card Number",
      accessor: "paymentMethod.last4",
      sort: true,
      Cell: ({ value, row }: any) =>
        `**** **** **** ${value} (${row.original.paymentMethod.brand})`,
    },
    {
      Header: "Amount",
      accessor: "amount",
      sort: true,
      Cell: ({ value }: { value: number }) =>
        Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value / 100),
    },
    {
      Header: "Status",
      accessor: "status",
      sort: true,
    },
    {
      id: "action",
      Header: "Actions",
      accessor: "memberService.referral.id",
      Cell: ({ value }: { value: string }) => (
        <Link to={`/referrals/${value}`}>
          <Button size="sm" variant="btn btn-primary rounded-pill width-sm">
            View
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <PageTitle title={"Billing details"} />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Table
                columns={columns}
                data={logs ?? []}
                pageSize={10}
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
