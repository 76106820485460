import { AxiosInstance, AxiosResponse } from "axios";
import { BaseApiModule } from "./base.module";
import { unstrapiOne } from "../../helpers/strapi";


export class MemberEventsApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }



  createInsuranceDeniedEvent(memberServiceId:number) {

    const data = {
        event: "insurance-benefit-denied",
        memberService: memberServiceId,
        //memberReferral: appointment.memberService.referral?.id,
        type: "insurance",
        level: "info",
        sendCommunicationOnSave: true
    }
    
    return this.client
      .post("api/member-events", { data })
      .then((res) => { });
  }

}
