import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Wizard, Steps, Step } from "react-albus";
import { useTranslation } from "react-i18next";

import {
  canExpand,
  descriptionId,
  FormContextType,
  getTemplate,
  getUiOptions,
  ObjectFieldTemplateProps,
  RJSFSchema,
  StrictRJSFSchema,
  titleId
} from "@rjsf/utils";
import { Button, ProgressBar, Spinner } from "react-bootstrap";
import { useState } from "react";

interface AdditionalProps {
  submitButtonText: string;
  saveDraft: (step: string) => Promise<boolean>;
  onSubmit: () => void;
  onSkipSurvey: () => void;
}

export default function ObjectWizardTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({
  description,
  title,
  properties,
  required,
  uiSchema,
  idSchema,
  schema,
  formData,
  onAddClick,
  disabled,
  errorSchema,
  readonly,
  registry,
  submitButtonText,
  saveDraft,
  onSubmit,
  onSkipSurvey
}: ObjectFieldTemplateProps<T, S, F> & AdditionalProps) {
  const [loading, setLoading] = useState(false);
  const uiOptions = getUiOptions<T, S, F>(uiSchema);
  const TitleFieldTemplate = getTemplate<"TitleFieldTemplate", T, S, F>(
    "TitleFieldTemplate",
    registry,
    uiOptions
  );
  const DescriptionFieldTemplate = getTemplate<
    "DescriptionFieldTemplate",
    T,
    S,
    F
  >("DescriptionFieldTemplate", registry, uiOptions);

  const { t } = useTranslation();
  // Button templates are not overridden in the uiSchema
  const {
    ButtonTemplates: { AddButton }
  } = registry.templates;
  return (
    <>
      {title && (
        <TitleFieldTemplate
          id={titleId<T>(idSchema)}
          title={title}
          required={required}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
      )}
      {description && (
        <DescriptionFieldTemplate
          id={descriptionId<T>(idSchema)}
          description={description}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
      )}
      <Container fluid className="p-0">
        <Wizard
          render={({ step, steps }) => (
            <>
              <ProgressBar
                animated
                striped
                variant="info"
                now={((steps.indexOf(step) + 1) / steps.length) * 100}
                className="mb-3 progress-sm"
              />

              <Steps>
                {properties.map((element: any, index: number) => (
                  <Step
                    id={String(index)}
                    key={index}
                    render={({ next, previous }) => (
                      <Row
                        style={{ marginBottom: "10px" }}
                        className={element.hidden ? "d-none" : undefined}
                      >
                        <Col xs={12}>{element.content}</Col>
                        <Col xs={12}>
                          <hr />
                          <ul className="list-inline wizard mb-0">
                            <li className="previous list-inline-item">
                              {index > 0 ? (
                                <Button
                                  onClick={() => {
                                    previous();

                                    setTimeout(() => {
                                      window.scrollTo({
                                        top: 0,
                                        behavior: "smooth"
                                      });
                                    }, 100);
                                  }}
                                  variant="secondary"
                                >
                                  {t("Previous")}
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    onSkipSurvey();
                                  }}
                                  variant="secondary"
                                >
                                  {t("Skip Survey")}
                                </Button>
                              )}
                            </li>
                            <li className="next list-inline-item float-end">
                              <Button
                                disabled={loading}
                                onClick={async () => {
                                  setLoading(true);
                                  if (properties.length - 1 === index) {
                                    await onSubmit();
                                  } else {
                                    const valid = await saveDraft(
                                      properties[index]?.name
                                    );
                                    if (valid) {
                                      next();
                                    }
                                  }
                                  setLoading(false);
                                }}
                                variant="primary"
                              >
                                {loading ? (
                                  <Spinner size="sm" />
                                ) : (
                                  <span>
                                    {properties.length - 1 === index
                                      ? submitButtonText
                                      : t("Next")}
                                  </span>
                                )}
                              </Button>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    )}
                  />
                ))}
              </Steps>
            </>
          )}
        />
      </Container>
    </>
  );
}
