import { api } from "@/apps/common/api-client";
import AsyncSelectWidget from "@/apps/common/components/form-widgets/AsyncSelectWidget";
import Form from "@/modules/react-jsonschema-form-bootstrap";
import validator from "@rjsf/validator-ajv8";
import FiltersBarInlineFormTemplate from "./filtersBarInlineFormTemplate";
import { APPOINTMENT_STATUS, MEMBER_SERVICE_STATUS } from "@/apps/common/constants";

const schema = {
  type: "object",
  properties: {
    // query: {
    //   type: "string",
    //   title: "Search"
    // },
    admin_follow_up: {
      type: "boolean",
      title: "Users with Admin Notes to follow up",
      default: false
    },
    // referrals: {
    //   type: "boolean",
    //   title: "Show Member Referrals",
    //   default: true
    // },

    filter: {
      title: "MemberService Filter",
      type: "string",
      "enum": ["All", "D2C", "Referrals"],
      "enumNames": ["All", "D2C", "Referrals"],
    },

    // status: {
    //   title: "Status",
    //   type: "array",
    //   minItems: 1,
    //   items: {
    //     type: "string",
    //     enum: [
    //       APPOINTMENT_STATUS.PENDING,
    //       APPOINTMENT_STATUS.CONFIRMED,
    //       APPOINTMENT_STATUS.DUE,
    //       APPOINTMENT_STATUS.COMPLETED
    //     ]
    //   },
    //   uniqueItems: true
    // }

    // serviceType: {
    //   type: "number",
    //   title: "Service Type"
    // },
    appointmentDateRange:{
      type: 'object',
      properties: {
        "startDate": {
          "type": "string",
          "format": "date",
          "description": "Appointments From"
        },
        "endDate": {
          "type": "string",
          "format": "date",
          "description": "Appointments To"
        }
      }
    },
    memberServiceStatus: {
      type: 'object',
      properties: {
        status: {
          title: "Status",
          type: "array",
          minItems: 1,
          items: {
            type: "string",
            enum: [
              MEMBER_SERVICE_STATUS.APPOINTMENT_SCHEDULED,
              MEMBER_SERVICE_STATUS.COMPLETE,
              MEMBER_SERVICE_STATUS.APPOINTMENT_NO_SHOW,
              MEMBER_SERVICE_STATUS.CANCELED,
            ]
          },
          uniqueItems: true
        }
      }
    }
  }
};

const uiSchema = {
  "ui:submitButtonOptions": {
    norender: true
  },
  "ui:ObjectFieldTemplate": (props: any) => (
    <FiltersBarInlineFormTemplate {...props} />
  ),

  //   query: {
  //     "ui:gridColumnProps": { md: 12, lg: 5 },
  //     "ui:options": {
  //       placeholder: "Name, email, or phone number"
  //     }
  //   },
  //   serviceType: {
  //     "ui:widget": AsyncSelectWidget,
  //     "ui:gridColumnProps": { md: 12, lg: 7 },
  //     "ui:options": {
  //       placeholder: ""
  //     },
  //     requestCacheKey: "serviceTypes",
  //     request: () =>
  //       api.common.fetchServiceTypes().then((states) =>
  //         states.map((state) => ({
  //           label: state.name,
  //           value: state.id
  //         }))
  //       )
  //   },
  memberServiceStatus: {
    'ui:title': 'MemberService Status Filtering',
    'ui:field': 'object',
    status: {
      "ui:widget": "checkboxes",
      "ui:options": {
        inline: true
      },
    },
  },
  appointmentDateRange: {
    'ui:title': 'Appointment Date Range Filtering',
    'ui:field': 'object',
  },
  filter: {
    "ui:widget": "radio",
    "ui:options": {
      inline: true
    },
  }


};

export default function FiltersBar({
  values,
  onChange
}: {
  values: any;
  onChange: any | null;
}) {
  return (
    <Form
      className="filters-bar"
      schema={schema as any}
      uiSchema={uiSchema}
      validator={validator}
      formData={values}
      onChange={(event) => {
        onChange(event.formData);
      }}
    />
  );
}
