import ProfileDetails from "@/apps/common/components/ProfileDetails";
import PaymentMethods from "@/apps/common/components/PaymentMethods";
import EditProfileModal from "@/apps/common/components/EditProfileModal";
import { FormInput, InputMasks, VerticalForm } from "@/apps/common/components/";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FeatherIcons from "feather-icons-react";
import { api } from "@/apps/common/api-client";
import { LegacyUserType } from "@/apps/common/api-client/api-client.types";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import TeleHealthLocationForm from "@/apps/common/components/HealthInsurance/TeleHealthLocation"
//import HealthInsurance from "../../components/HealthInsurance/HealthInsurance";
import HealthInsuranceForm from "@/apps/common/components/HealthInsurance/HealthInsurance"
import { Button, Spinner, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useRequest } from "ahooks";
import { DateTime } from "luxon";
import {
  DATE_FORMATS,
  isValidDateFormat,
  reverseFormatDateOfBirth
} from "@/apps/common/helpers/date";
import { TelehealthLocationType, HealthInsuranceType, UserType, InsuranceProviderType, StateType } from "@/apps/common/types/appointment-types";

type _state = {
  id: number;
  attributes: {
    name: string;
    code: string;
    createdAt: string;
    updatedAt: string;
    enabled: boolean;
  };
};

interface MemberProfileData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  //address: string;
  dob: string;
  state: string;
}

const memberProfileDataSchemaResolver = yupResolver(
  yup.object().shape({
    email: yup
      .string()
      .required("Please enter Email")
      .email("Please enter valid Email"),
    phoneNumber: yup.string().required("Please enter Phone"),
    firstName: yup.string().required("Please enter First name"),
    lastName: yup.string().required("Please enter Last name"),
    //address: yup.string().required("Please enter address"),
    state: yup.string().required("Please select your State"),
    dob: yup
      .string()
      .required("Please enter Date of birth")
      .test("is-valid-date", "Please enter date in MM/DD/YYYY", (value) => {
        return isValidDateFormat(value, DATE_FORMATS.DATE_ONLY);
      })
  })
);

const Profile = () => {
  const { t } = useTranslation();

  const [memberProfileData, setMemberProfileData] =
    useState<MemberProfileData>();
  const [showConfirmUpdateModal, setshowConfirmUpdateModal] = useState(false);
  const [savingProfile, setSavingProfile] = useState<boolean>(false);
  const [teleHealthLoc, setTeleHealthLoc] = useState<TelehealthLocationType>();
  const { user, fetchStates, states } = useAuthStore();
  const [healthInsurance, setHealthInsuranceData] = useState<HealthInsuranceType>()
  const [formSubmitStatus, setFormSubmitStatus] = useState<boolean | null>(null)
  const [userData, setUserData] = useState<Partial<UserType>>()
  const [healthInsuranceFormMode, setHealthInsuranceFormMode] = useState<"create" | "edit" | "preview">("preview")

  useEffect(() => {
    if (!user) {
      return;
    }

    //console.debug('user ' + JSON.stringify(user))
    const _insuranceProvider: unknown = user?.insurance_provider
    const _state: unknown = user?.state;

    const _user: Partial<UserType> = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      insurance_provider: _insuranceProvider as InsuranceProviderType,
      state: _state as StateType
    }
    setUserData(_user)

    if (_insuranceProvider) {
      setHealthInsuranceFormMode("edit")
    }
    else {
      setHealthInsuranceFormMode("create")
    }

    // fetch telehealth_location data ...
    api.telehealthLoc.find(user.id).then(
      (res) => {
        console.debug('telehealth Location - ' + JSON.stringify(res[0]))
        setTeleHealthLoc(res[0])
      }
    )

    // fetch health insurance data ...
    api.healthInsurance.find(user.id).then(
      (res) => {
        console.debug('Fetched health insurance data - ' + JSON.stringify(res[0]))
        setHealthInsuranceData(res[0])
      }
    )

    const _dob = DateTime.fromFormat(
      user.dateOfBirth!.toString(),
      "yyyy-MM-dd"
    ).toFormat(DATE_FORMATS.DATE_ONLY);

    const _memberProfileData: MemberProfileData = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
      dob: _dob,
      state: user?.state.id.toString()
      //address: user.address ? user.address : "..."
    };

    setMemberProfileData(_memberProfileData);
  }, [user]);

  const { loading: saving, runAsync: updateUser } = useRequest(
    api.auth.updateUser.bind(api.auth),
    {
      onSuccess: (result, params) => {
        try {
          console.debug("user update succeeded - " + JSON.stringify(result));
          useAuthStore.getState().fetchUser();
        } catch (error) {
          console.debug("user update failed - " + JSON.stringify(error));
          console.error(error);
        }
      },
      manual: true
    }
  );

  const onSubmitHealthInsuranceForm = (formData: any) => {

    console.debug('submitting HealthInsurance form - ' + JSON.stringify(formData))
    //setHealthInsuranceData(formData);
    const _healthInsurance = {
      firstLegalName: formData.firstLegalName,
      lastLegalName: formData.lastLegalName ? formData.lastLegalName : '',
      groupNumber: formData.groupNumber,
      memberId: formData.memberId,
      phoneNumber: formData.phoneNumber,
    }

    try {

      if (healthInsuranceFormMode === "create") {
        console.debug ('Saving InsuranceProvider information ' + JSON.stringify(formData.insuranceProvider))
        // Update the insurance provider
        const userData: Partial<LegacyUserType> = {

          insurance_provider: formData.insuranceProvider
        };

        updateUser(user!.id, userData).then(
          (res) => {

          }
        )
      }

      if (healthInsurance?.id) {
        console.debug('Updating an existing HealthInsurance')
        const data = {
          ..._healthInsurance
        }

        api.healthInsurance.update(healthInsurance.id, data).then((res) => {

          setFormSubmitStatus(true)
          setHealthInsuranceData(res)
          console.debug('Response after updateing Health Insurance - ' + JSON.stringify(res))

        },
          (err) => {
            setFormSubmitStatus(false)

          });
      }
      else {

        const a = {
          ..._healthInsurance,
          user: user?.id
        }
        api.healthInsurance.create(a).then((res) => {
          setFormSubmitStatus(true)
        },
          (err) => {
            setFormSubmitStatus(false)
          }
        )
      }

    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = (formData: MemberProfileData) => {
    setMemberProfileData(formData);
    setshowConfirmUpdateModal(true);
  };

  useEffect(() => {
    fetchStates();
  }, [fetchStates]);


  const updateProfile = async () => {
    try {
      console.debug("saving Member Profile ...");
      setSavingProfile(true);

      const userData: Partial<LegacyUserType> = {
        firstName: memberProfileData?.firstName,
        lastName: memberProfileData?.lastName,
        email: memberProfileData?.email,
        phoneNumber: memberProfileData?.phoneNumber,
        state: { id: parseInt(memberProfileData!.state!) }
        //address: memberProfileData?.address
      };

      if (
        memberProfileData &&
        isValidDateFormat(memberProfileData!.dob, DATE_FORMATS.DATE_ONLY)
      ) {
        //console.debug ('memberProfileData!.dob,' + memberProfileData!.dob,)
        userData.dateOfBirth = reverseFormatDateOfBirth(
          memberProfileData!.dob
        )!;
        //console.debug ( userData.dateOfBirth)
      }

      updateUser(user!.id, userData);

      setSavingProfile(false);
      setshowConfirmUpdateModal(false);
    } catch (err) {
      console.log(err);
      setSavingProfile(false);
    }
  };

  const onCloseModal = () => {
    setshowConfirmUpdateModal(false);
  };

  const memberProfileForm = memberProfileData ? (
    <VerticalForm<MemberProfileData>
      onSubmit={onSubmit}
      resolver={memberProfileDataSchemaResolver}
      defaultValues={memberProfileData}
      formClass="authentication-form row"
    >
      <FormInput
        label={t("First name")}
        type="text"
        name="firstName"
        startIcon={<FeatherIcons icon={"user"} className="icon-dual" />}
        placeholder={t("Your first name")}
        containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
      />
      <FormInput
        label={t("Last name")}
        type="text"
        name="lastName"
        startIcon={<FeatherIcons icon={"user"} className="icon-dual" />}
        placeholder={t("Your last name")}
        containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
      />
      {/* <FormInput
        label={t("Address")}
        type="text"
        name="address"
        startIcon={<FeatherIcons icon={"map-pin"} className="icon-dual" />}
        placeholder={t("Your address")}
        containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
      /> */}
      <FormInput
        label={t("Email Address")}
        type="email"
        name="email"
        startIcon={<FeatherIcons icon={"mail"} className="icon-dual" />}
        placeholder={t("hello@domain.com")}
        containerClass={"mb-2 col-6"}
      />
      <InputMasks
        type="mask"
        startIcon={<FeatherIcons icon={"calendar"} className="icon-dual" />}
        label={t("Date of birth")}
        mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
        placeholder="MM/DD/YYYY"
        name="dob"
        containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
      />
      <InputMasks
        type="mask"
        startIcon={<FeatherIcons icon={"phone"} className="icon-dual" />}
        label={t("Mobile Phone")}
        mask={[
          "(",
          /[1-9]/,
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/
        ]}
        placeholder="(___) ___-____"
        name="phoneNumber"
        containerClass={"mb-2 col-6"}
      />
      <FormInput
        label={t("State")}
        type="select"
        key="state"
        name="state"
        startIcon={<FeatherIcons icon={"map-pin"} className="icon-dual" />}
        containerClass={`mb-2 col-sm-12 col-md-6 col-lg-6"`}
        defaultValue={memberProfileData.state}
      >
        {/* <option value="">Select your state</option> */}
        {states &&
          states.map((v: _state, i: number) => (
            <option
              key={i}
              value={v.id}
              selected={parseInt(memberProfileData.state) == v.id}
            >
              {v?.attributes?.name}
            </option>
          ))}
      </FormInput>
      <div className="mb-2 text-center d-grid col-lg-12">
        <Button type="submit">
          {saving ? <Spinner size="sm" /> : t("Save")}
        </Button>
      </div>
    </VerticalForm>
  ) : null;

  return (
    <>

      <ProfileDetails /*cardDetails={}*/>{memberProfileForm}</ProfileDetails>

      <Card className="mt-2">
        <Card.Body>
          <br />
          <div style={{ margin: "0 auto" }} className="mt-3 pt-2 border-top text">
            <h3 className="mb-2 fs-15">Telehealth Location Details</h3>
          </div>
          {userData ?
          (<TeleHealthLocationForm mode="edit" teleHealthLocation={teleHealthLoc!} display={() => { }} user={userData!} />):
          null}
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Body>
          <div style={{ margin: "0 auto" }} className="mt-3 pt-2 border-top text">
            <h3 className="mb-2 fs-15">Health Insurance Details</h3>
          </div>
          {/* <HealthInsurance display={(val) => { }} /> */}
          {userData ?
            // (<HealthInsuranceForm mode={healthInsuranceFormMode} healthInsurance={healthInsurance} user={userData} onSubmitForm={onSubmitHealthInsuranceForm} formSubmitStatus={formSubmitStatus} />)
            (<HealthInsuranceForm mode={healthInsuranceFormMode} healthInsurance={healthInsurance} user={userData} display={()=>{}} />)
            : null
          }
        </Card.Body>
      </Card>

      {showConfirmUpdateModal ? (
        <EditProfileModal
          show={showConfirmUpdateModal}
          onClick={updateProfile}
          loading={savingProfile}
          onHide={onCloseModal}
        />
      ) : null}
      <PaymentMethods userType={"member"} userId={null}/>
    </>
  );
};

export default Profile;
