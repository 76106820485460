import { useMemo } from "react";
import { api } from "../api-client";
import AsyncMultiSelectWidget from "../components/form-widgets/AsyncMultiSelectWidget"
import AsyncSelectWidget from "../components/form-widgets/AsyncSelectWidget";
import { defaults } from "lodash-es";
import { descriptionId } from "@rjsf/utils";
import { phoneNumberMask } from "./utils/masks";
import MaskInputWidget from "../components/form-widgets/MaskInputWidget";
type SchemaProps = {
  mode: "register" | "admin" | "create" | "edit" | "preview";
}
function isValidUSZip(sZip: string) {
  return /^\d{5}$/.test(sZip);
}

function iif(condition: boolean, then: any, otherwise: any = undefined) {
  return condition ? then : otherwise;
}

//const portalType = getPortalType();

const getAccountSchema = ({ mode }: SchemaProps) => (

  {
    type: "object",
    required: [
      "name",
      "contactName",
      "email",
      "street1",
      "city",
      "zip",
    ],
    properties: {
      name: {
        "title": "Account Name",
        "type": "string",
      },
      contactName: {
        "title": "Contact Name",
        "type": "string",
      },
      street1: {
        'title': 'Street1',
        'type': 'string',
        description: "Please provide Street1 for the address. "
      },
      street2: {
        type: "string",
        title: "Street2",
        description: "Please provide optional Street2 for the address."
      },
      city: {
        type: "string",
        title: "City",
        description: "Please provide the City for the Account"
      },
      zip: {
        type: "string",
        title: "zipCode",
        description: "Please select the state where you provide services from."
      },
      phoneNumber: {
        'title': 'Phone Number',
        'type': 'string',
        description: "Please provide Primary Phoneumber for this account"
      },
      email: {
        'title': 'Email',
        'type': 'string',
        description: "Please provide your primary email. This is also your username for logging into SkylerHealth platform"
      },

    }
  });

const getAccountUISchema = ({
  mode,
}: SchemaProps) => ({

  "ui:submitButtonOptions": {
    norender: mode === "preview",
  },

  "ui:layoutOptions": { gridColumnProps: { xs: 12 } },


  name: {
    "ui:options": {
      placeholder: "Account Name"
    }
  },

  contactName: {
    "ui:options": {
      placeholder: "Contact Name"
    }
  },

  street1: {
    "ui:options": {
      placeholder: "Street1"
    }
  },

  street2: {
    "ui:options": {
      placeholder: "Street2"
    }
  },

  city: {
    "ui:options": {
      placeholder: "City"
    }
  },

  zip: {
    "ui:options": {
      placeholder: "zipCode"
    }
  },

  phoneNumber: {
    "ui:widget": MaskInputWidget,
    "ui:options": {
      inputType: "tel",
      mask: phoneNumberMask,
      placeholder: "(___) ___-____"
    }
  },
  email: {
    "ui:options": {
      placeholder: "Email",
      "inputType": "email   "
    }
  },
}
);

function customValidate(formData: any, errors: any) {
  return errors;
}

function transformErrors(errors: any[]) {

  return errors

}

export function useAccountFormConfig(props: Partial<SchemaProps>) {
  const withDefaultProps = defaults(props, {
    mode: "create",
  });

  const schema = useMemo(
    () => getAccountSchema(withDefaultProps),
    [withDefaultProps]
  );

  const uiSchema = useMemo(() => {
    return getAccountUISchema(withDefaultProps);
  }, [withDefaultProps]);

  return {
    schema,
    uiSchema,
    customValidate,
    transformErrors
  };
}