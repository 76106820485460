import { AxiosInstance, AxiosResponse } from "axios";
import { AuthLoginResponse, LegacyUserType } from "../api-client.types";
import { BaseApiModule } from "./base.module";
import { getPortalType } from "../../helpers";
import { PortalTypes } from "@/constants/portals";
import { AxiosStrapiList } from "../types";
import { StateType } from "../../types/appointment-types";
import { api } from "@/apps/common/api-client";
import { unstrapiMany } from "@/apps/common/helpers/strapi";

export interface LoginParams {
  identifier: string;
  password: string;
}

export interface RegisterData {
  username: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  state?: string;
  spokenLanguage?: string;
  email: string;
  password: string;
  token?: string;
  timezone: string;
  employer?: string | null;
}

export interface RegisterClinicData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  state: string;
  email: string;
  username: string;
  password: string;
  clinic: {
    name: string;
    location: string;
    state: string;
  };
  timezone: string;
}

export interface RegisterServiceProviderData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  state: string;
  email: string;
  username: string;
  password: string;
  timezone: string;
  type?: string;
  dateOfBirth?: string;

}

export interface validateReferralData {
  lastName: string;
  dateOfBirth: string;
  token?: string;
}

const DEFAULT_HEADERS = {
  headers: { Authorization: "" }
};

export class AuthApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api/auth");
  }

  async login(data: LoginParams): Promise<AxiosResponse<AuthLoginResponse>> {
    const portalType = getPortalType();
    let url = "member/";
    switch (portalType) {
      case PortalTypes.CLINICAL:
        url = "clinical/";
        break;
      case PortalTypes.PROVIDER:
        url = "provider/";
        break;
      case PortalTypes.MEMBER:
        url = "member/";
        break;
      case PortalTypes.ADMIN:
        url = "admin/";
        break;
      case PortalTypes.PARTNER:
        url = "partner/";
        break;
    }

    return await this.client.post(this.buildPath(url), data, DEFAULT_HEADERS);
  }

  async requestPasswordReset(email: string): Promise<AxiosResponse> {
    return await this.client.post(
      "/api/auth/forgot-password",
      { email, url: `${window.location.origin}/auth/reset-password` },
      DEFAULT_HEADERS
    );
  }

  async resetPassword(code: string, password: string): Promise<AxiosResponse> {
    return await this.client.post(
      "/api/auth/reset-password",
      { code, password, passwordConfirmation: password },
      DEFAULT_HEADERS
    );
  }

  async register(
    data: RegisterData
  ): Promise<AxiosResponse<AuthLoginResponse>> {
    return await this.client.post(
      this.buildPath("register/member"),
      data,
      DEFAULT_HEADERS
    );
  }

  async registerClinic(
    data: RegisterClinicData
  ): Promise<AxiosResponse<AuthLoginResponse>> {
    return await this.client.post(
      this.buildPath("register/clinical"),
      data,
      DEFAULT_HEADERS
    );
  }

  async registerServiceProvider(
    data: RegisterServiceProviderData
  ): Promise<AxiosResponse<AuthLoginResponse>> {
    return await this.client.post(
      this.buildPath("register/serviceprovider"),
      data,
      DEFAULT_HEADERS
    );
  }

  async validateReferral(data: validateReferralData): Promise<AxiosResponse> {
    return await this.client.post(
      "/api/referrals/validate",
      data,
      DEFAULT_HEADERS
    );
  }

  async fetchUser(): Promise<AxiosResponse<LegacyUserType>> {
    return await this.client.get(
      "/api/users/me/?populate=role,state,profilePicture,insurance_provider"
    );
  }

  async updateUser(
    user: number,
    data: Partial<LegacyUserType>
  ): Promise<AxiosResponse<LegacyUserType>> {
    return await this.client.put(`/api/users/${user}/`, data);
  }

  async fetchStates(): Promise<AxiosStrapiList<StateType>> {
    return await this.client.get("/api/states", {
      params: {
        filters: { enabled: true },
        sort: "name:asc"
      },
      headers: { Authorization: "" }
    });
  }

  async fetchStatesforReferrals() {
    const portalType = getPortalType();

    if (portalType == "provider") {
      const sp_data = await api.serviceProviders
        .findMany({ populate: ["states", "user", "paymentProfile"] })
        .then((providers: any) => providers[0]);
      //console.debug("sp_data - " + JSON.stringify(sp_data.states));
      const a = sp_data.states.map((state: any) => ({
        label: state.name,
        value: state.id
      }));

      //console.debug( 'States' + JSON.stringify(a));
      return a;
    }

    //console.debug ('fetchStatesforReferrals')

    const b = await this.client
      .get("/api/states", {
        params: {
          filters: { enabled: true },
          sort: "name:asc"
        },
        headers: { Authorization: "" }
      })
      .then((response) => unstrapiMany(response.data, "state"))

    //console.debug ('b' + JSON.stringify(b))
    const a = b.map((state: any) => ({
      label: state.name,
      value: state.id
    }));
    //console.debug( 'States' + JSON.stringify(a));
    return a;
  }

  async fetchStatesforRegistration() {
    const portalType = getPortalType();


    //console.debug ('fetchStatesforReferrals')

    const b = await this.client
      .get("/api/states", {
        params: {
          filters: { enabled: true },
          sort: "name:asc"
        },
        headers: { Authorization: "" }
      })
      .then((response) => unstrapiMany(response.data, "state"))

    //console.debug ('b' + JSON.stringify(b))
    const a = b.map((state: any) => ({
      label: state.name,
      value: state.id
    }));
    //console.debug( 'States' + JSON.stringify(a));
    return a;
  }


  async fetchQualifications(): Promise<AxiosResponse> {
    return await this.client.get("/api/service-provider-types");
  }

  async fetchAccessCodeDetails(): Promise<AxiosResponse> {
    return await this.client.get("/api/member-referral-access-tokens");
  }
}
