import { useAuthStore } from "@/apps/common/store/useAuthStore";
import React, { useMemo } from "react";
import { Navigate, Route, RouteProps, Routes } from "react-router-dom";
import DefaultLayout from "../../common/layouts/Default";
import HorizontalLayout from "../../common/layouts/Horizontal/";

export interface RouteType {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: React.ComponentType;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RouteType[];
}

type MenuItemType = {
  key: string;
  icon: string;
  label: string;
  isTitle: boolean;
  url: string;
};

const flattenRoutes = (routes: RouteType[]) => {
  let flatRoutes: RouteType[] = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

export type RoutesHandlerProps = {
  defaultRoute: string;
  authProtectedRoutes: RouteType[];
  publicRoutes: RouteType[];
  menuItems: MenuItemType[];
};

export default function RoutesHandler({
  defaultRoute,
  authProtectedRoutes,
  publicRoutes,
  menuItems,
  ...props
}: RoutesHandlerProps) {
  const loggedIn = useAuthStore((state) => state.loggedIn);

  const authProtectedFlattenRoutes = useMemo(
    () => flattenRoutes([...authProtectedRoutes]),
    [authProtectedRoutes]
  );

  const publicProtectedFlattenRoutes = useMemo(
    () => flattenRoutes([...publicRoutes]),
    [publicRoutes]
  );

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <DefaultLayout {...props}>{route.element}</DefaultLayout>
              }
              key={idx}
            />
          ))}
        </Route>

        <Route>
          {authProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                !loggedIn ? (
                  <Navigate
                    to={{
                      pathname: "/auth/login",
                      search: "next=" + location.pathname + location.search
                    }}
                  />
                ) : (
                  <HorizontalLayout
                    menuBarHidden={false}
                    menuItems={menuItems}
                    {...props}
                  >
                    {route.element}
                  </HorizontalLayout>
                )
              }
              key={idx}
            />
          ))}
        </Route>
        <Route path="*" element={<Navigate to={defaultRoute} />} />
      </Routes>
    </React.Fragment>
  );
}
