import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import luxonPlugin from "@fullcalendar/luxon3";

interface CalendarProps {
  // onDateClick: (value: any) => void;
  onEventClick: (value: EventClickArg) => void;
  events: EventInput[];
  timeZone: string;
}

const Calendar = ({ onEventClick, events, timeZone }: CalendarProps) => {
  /*
   * handle calendar methods
   */
  // const handleDateClick = (arg: any) => {
  //   onDateClick(arg);
  // };
  const handleEventClick = (arg: EventClickArg) => {
    onEventClick(arg);
  };

  return (
    <>
      {/* full calendar control */}
      <div id="calendar">
        <FullCalendar
          height="75vh"
          initialView="listMonth"
          initialDate={events[0]?.start}
          plugins={[
            luxonPlugin,
            // dayGridPlugin,
            // interactionPlugin,
            // timeGridPlugin,
            listPlugin,
            BootstrapTheme
          ]}
          handleWindowResize={true}
          themeSystem="bootstrap"
          buttonText={{
            today: "Today",
            month: "Month",
            week: "Week",
            day: "Day",
            list: "List",
            prev: "Prev",
            next: "Next"
          }}
          headerToolbar={
            {
              // left: "prev,next today",
              // center: "title",
              // right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }
          }
          dayMaxEventRows={1}
          editable={false}
          selectable={false}
          droppable={false}
          events={events}
          // stickyHeaderDates={false}
          // dateClick={handleDateClick}
          eventClick={handleEventClick}
          timeZone={timeZone}
        />
      </div>
    </>
  );
};

export default Calendar;
