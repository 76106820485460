import logoDark from "@/assets/images/logo-skyler-dark.png";
import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getPortalTitle } from "../../helpers";
import { getPortalType } from "../../helpers";
import { PortalTypes } from "@/constants/portals";
import Footer from "../../layouts/Footer";

interface AccountLayoutProps {
  bottomLinks?: React.ReactNode;
  children?: React.ReactNode;
}

const portalType = getPortalType();

const AuthLayout = ({ bottomLinks, children }: AccountLayoutProps) => {
  useEffect(() => {
    if (document.body) document.body.classList.add("authentication-bg");

    return () => {
      if (document.body) document.body.classList.remove("authentication-bg");
    };
  }, []);

  return (
    <>
      <div className="account-pages my-4">
        <Container>
          <Row className="justify-content-center">
            <Col xl={10}>
              <Card>
                <Card.Body className="p-0">
                  <Row className="g-0">
                    <Col lg={12} className="p-4">
                      <div className="auth-logo mx-auto">
                        <Link
                          to="https://skylerhealth.com/"
                          className="logo logo-dark text-center"
                        >
                          <span className="logo-lg">
                            <img src={logoDark} alt="" />
                          </span>
                        </Link>
                        <h1>{getPortalTitle()}</h1>
                      </div>

                      {children}
                    </Col>
                    <Col lg={6} className="d-none">
                      <div className="auth-page-sidebar">
                        {portalType !== PortalTypes.MEMBER ? (
                          <>
                            <div className="overlay"></div>
                            <div className="auth-user-testimonial">
                              <p className="fs-19 fw-bold text-black">
                                <a
                                  href="https://skylerhealth.com/"
                                  target="_blank"
                                >
                                  Skyler Health
                                </a>{" "}
                                makes it easy to get evaluations done remotely
                                with results delivered in 24 hours so that you
                                can get back to life.
                              </p>
                              <div className="steps">
                                <p className="fs18">
                                  Simple three steps for members to get
                                  evaluations done quickly:
                                </p>
                                <ol>
                                  <li>
                                    Member on-boards and takes a self-paced
                                    digital evaluation that takes less than 15
                                    minutes.
                                  </li>
                                  <li>
                                    Member meets our licensed counselor remotely
                                    via a tele-health video session for an
                                    evaluation.
                                  </li>
                                  <li>
                                    Counselor finalizes the report. Skyler
                                    Health sends the final report within 24
                                    hours to the member and the referring
                                    doctor.
                                  </li>
                                </ol>
                              </div>
                              <p className="">
                                <a
                                  href="https://skylerhealth.com/"
                                  target="_blank"
                                >
                                  Skyler Health
                                </a>
                                's providers are licensed, vetted and
                                professional therapists who are fully-licensed
                                and have undergone a thorough background check
                                and clinical review, to ensure that your
                                patients receive the highest standard of care.
                              </p>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* bottom links */}
              {bottomLinks}
            </Col>
          </Row>
          <Footer showLinks />
        </Container>
      </div>
    </>
  );
};

export default AuthLayout;
