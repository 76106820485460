import { Card } from "react-bootstrap";
import userAvatar from "../../../assets/images/users/avatar-11.png";
import { useAuthStore } from "../store/useAuthStore";
import React, { useState, useRef, MouseEventHandler } from "react";
import { api } from "@/apps/common/api-client";
import Button from "react-bootstrap/Button";

const PersonalDetails = ({
  cardDetails,
  refreshParent,
  children
}: {
  cardDetails?: any;
  children?: React.ReactNode;
  refreshParent?: (refresh: boolean) => void;
}) => {
  const { user } = useAuthStore();
  //const picture = user?.profilePicture?.url || userAvatar;

  const [profileImage, setProfileImage] = useState<any | null>(null);
  const [picture, setProfilePicture] = useState<any | null>(
    user?.profilePicture?.url || userAvatar
  );

  function handleChange(e: any) {
    console.debug(e.target.files);
    setProfilePicture(URL.createObjectURL(e.target.files[0]));
    setProfileImage(e.target.files[0]);
    //console.debug (URL.createObjectURL(e.target.files[0]))
  }

  const onSubmit = () => {
    //console.debug (profileImage)
    const userId = user?.id.toString();
    if (userId) {
      const resp = api.common.uploadImage(profileImage, userId).then((resp) => {
        console.debug("resp - " + JSON.stringify(resp));
        refreshParent?.(true);
        setProfileImage(null);
        window.location.reload();
      });
    }
  };

  const myRefname = useRef<HTMLInputElement>(null);

  const handleFileSelect: MouseEventHandler = (e) => {
    console.debug("handleFileSelect");
    e.preventDefault();
    myRefname?.current?.click();
  };

  return (
    <Card className="mt-2">
      <Card.Body>
        <div className="text-center mt-2">
          <br />
          <div>
            <a href="" onClick={handleFileSelect}>
              <img
                src={picture}
                alt=""
                className="avatar-lg rounded-circle" /*onClick={handleFileSelect}*/
              />
              <br></br>
              select new Profile Image
            </a>
            <input
              id="profileImage"
              type="file"
              accept="image/*"
              ref={myRefname}
              onChange={handleChange}
              hidden
            />
            <br />
            <Button
              variant="primary"
              className="btn btn-primary btn-xs"
              onClick={onSubmit}
              disabled={!profileImage ? true : false}
            >
              Upload
            </Button>
          </div>
          <h4 className="mt-2 mb-0">
            {user?.firstName} {user?.lastName}
          </h4>
        </div>
        <div style={{ margin: "0 auto" }} className="mt-3 pt-2 border-top text">
          <h4 className="mb-2 fs-15">Contact Information</h4>
          {children ? (
            children
          ) : (
            <div className="table-responsive">
              <table className="table table-borderless mb-0 text-muted">
                <tbody>
                  <tr>
                    <th scope="row">Email</th>
                    <td>{user?.email}</td>
                  </tr>
                  <tr>
                    <th scope="row">Phone</th>
                    <td>{user?.phoneNumber}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {cardDetails && (
            <>
              <h4 className="mb-2 fs-15">Payment Information</h4>
              <div className="table-responsive">
                <table className="table table-borderless mb-0 text-muted">
                  <tbody>
                    <tr>
                      <th scope="row">Payment method</th>
                      <td>
                        ********{cardDetails.last4} ({cardDetails.brand})
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default PersonalDetails;
