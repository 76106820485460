import { api } from "@/apps/common/api-client";
import { useRequest } from "ahooks";

import PageTitle from "@/apps/common/components/PageTitle";
import Spinner from "@/apps/common/components/Spinner";
import FeatherIcons from "feather-icons-react";
import Markdown from "markdown-to-jsx";
import { Button, ButtonGroup, Card } from "react-bootstrap";
import { useCallback } from "react";

export default function Appointment({
  appointmentId
}: {
  appointmentId: number;
}) {
  const {
    data: appointment,
    loading,
    refresh: refreshAppointment
  } = useRequest(() => api.appointments.getById(Number(appointmentId)));

  const { data: transcripts = [] } = useRequest(() =>
    api.transcripts.getByAppointment(Number(appointmentId))
  );

  const rateSummary = useCallback(
    async (rating: number) => {
      await api.appointments.rateSummary(appointmentId, rating);
      refreshAppointment();
    },
    [appointmentId, refreshAppointment]
  );

  return (
    <>
      <PageTitle title={"Appointment Details"} />

      {loading ? <Spinner /> : null}
      {appointment?.summary ? (
        <Card>
          <Card.Header
            className={"text-bg-secondary d-flex align-items-center"}
          >
            <strong>Summary</strong>
            <div className="flex-fill" />
            <ButtonGroup className="me-2" aria-label="Rate the summary">
              <Button
                variant="secondary"
                active={appointment.summaryRating === 1}
                onClick={() => rateSummary(1)}
              >
                <FeatherIcons icon={"thumbs-up"} />
              </Button>
              <Button
                variant="secondary"
                active={appointment.summaryRating === -1}
                onClick={() => rateSummary(-1)}
              >
                <FeatherIcons icon={"thumbs-down"} />
              </Button>
            </ButtonGroup>
          </Card.Header>
          <Card.Body>
            <Markdown>{appointment.summary}</Markdown>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Header className={"text-bg-secondary"}>
          <strong>Transcripts</strong>
        </Card.Header>
        <Card.Body>
          {transcripts.map((transcript, index) => {
            const newMessage =
              transcripts[index - 1]?.sender !== transcripts[index].sender;
            const lastMessageFromSender =
              transcripts[index + 1]?.sender !== transcripts[index].sender;

            return (
              <>
                {newMessage ? (
                  <div className="mt-2">
                    <strong>{transcript.sender}</strong>:
                  </div>
                ) : null}
                <span key={transcript.id}>
                  {transcript.text}{" "}
                  {/* {lastMessageFromSender && transcript.llmResponse ? (
                        <Alert variant="info">
                          <Markdown>{transcript.llmResponse}</Markdown>
                        </Alert>
                      ) : null} */}
                </span>
              </>
            );
          })}
        </Card.Body>
      </Card>
    </>
  );
}
