//import ReferExistingField from "@/apps/common/pages/NewReferral/ReferExistingField";
import { useMemo } from "react";
import { api } from "../api-client";
import AsyncSelectWidget from "../components/form-widgets/AsyncSelectWidget";
import { defaults } from "lodash-es";
import { dateOfBirthMask, phoneNumberMask } from "./utils/masks";
//import MaskInputWidget from "../components/form-widgets/MaskInputWidget";
import { PortalTypes } from "@/constants/portals";
import { isValidPhoneNumber } from "libphonenumber-js"; // or any other phone validation library

type SchemaProps = {
  mode: "create" | "edit" | "preview" | "admin";
  //type: "new" | "repeat";
  portalType?: "" | PortalTypes.ADMIN;  
}

function isValidUSZip(sZip:string) {
  return /^\d{5}$/.test(sZip);
}
function iif(condition: boolean, then: any, otherwise: any = undefined) {
  return condition ? then : otherwise;
}

//const portalType = getPortalType();

const getMemberProfileSchema = ({mode}: SchemaProps) => ({
  type: "object",
  required: [
    "firstName",
    "lastName",
    "dateOfBirth",
    "email",
    "phoneNumber",
    "state"
  ],
  properties: {
    // streetAddress1: {
    //   type: "string",
    //   title: "StreetAddress1",
    //   description: "Please enter your street address"
    // },
    // streetAddress2: {
    //   type: "string",
    //   title: "StreetAddress2",
    //   description: "Please enter your street address"
    // },
    // city: {
    //   type: "string",
    //   title: "City",
    //   description: "Please enter your city"
    // },
    firstName: {
      type: "string",
      title: "First Name",
      description: "Please provide your first Name"
    },
    lastName: {
      type: "string",
      title: "First Name",
      description: "Please provide your first Name"
    },
    state: {
      type: "number",
      title: "State",
      description: "Please select your state"
    },
    dateOfBirth: {
      type: "string",
      title: "Date of birth"
    },
    email: {
      type: "string",
      format: "email",
      title: "Email"
    },
    phoneNumber: {
      type: "string",
      title: "Mobile Phone Number"
    }, 
    employer: {
      type: "string",
      title: "Employer",
      minLength: 0
    }, 
    spokenLanguage: {
      type: "string",
      title: "Spoken Language"
    }, 
  }
});

const getMemberProfileUISchema = ({
  mode,
}: SchemaProps) => ({
 
  "ui:submitButtonOptions": {
    norender: mode === "preview",
    submitText:iif( mode === "admin",
        "Update Member Details",        
      ),
  },

  "ui:layoutOptions": { gridColumnProps: { xs: 6 } },
  dateOfBirth: {    
    // "ui:widget": iif(
    //   type === "repeat" && mode === "create",
    //   "hidden",
    //   MaskInputWidget
    // ),
    "ui:options": {
      mask: dateOfBirthMask,
      placeholder: "MM/DD/YYYY",
      readOnly:true
    }
  },
  state: {
    "ui:widget": AsyncSelectWidget,
    "ui:readonly": iif( mode === "admin",
      true,false        
    ),
    "ui:options": {
      placeholder: "Select State"
    },
    requestCacheKey: "states",
    request: () => api.auth.fetchStatesforReferrals(),
    selectItemOnLoad: true
  },
  firstName: {
    // "ui:widget": iif(type === "repeat" && mode === "create", "hidden"),
    "ui:options": {
      placeholder: "First Name"
    }
  },
  lastName: {
    // "ui:widget": iif(type === "repeat" && mode === "create", "hidden"),
    "ui:options": {
      placeholder: "Last Name"
    }
  },
  email: {
    //"ui:widget": iif(type === "repeat" && mode === "create", "hidden"),
    "ui:options": {
      placeholder: "Email"
    }
  },
  phoneNumber: {
    // "ui:widget": iif(
    //   type === "repeat" && mode === "create",
    //   "hidden",
    //   MaskInputWidget
    // ),
    "ui:options": {
      inputType: "tel",
      mask: phoneNumberMask,
      placeholder: "(___) ___-____"
    }
  },
  employer: {
    // "ui:widget": iif(type === "repeat" && mode === "create", "hidden"),
    "ui:emptyValue":" ",
    "ui:options": {
      placeholder: "Employer"
    }
  },
  spokenLanguage: {
    // "ui:widget": iif(type === "repeat" && mode === "create", "hidden"),
    "ui:readonly": iif( mode === "admin",
      true,false        
    ),
    "ui:options": {
      placeholder: "Spoken Language"
    },
  },
  

});

function customValidate(formData: any, errors: any) {
  console.debug ('Phone Number' + formData.phoneNumber )
  if (!formData?.phoneNumber || !isValidPhoneNumber(formData?.phoneNumber,'US'))  {
    errors.phoneNumber.addError("Please enter a valid US Phone Number 1(###)-(###)-(####)");
  }
  return errors;
}

function transformErrors(errors: any[]) {
  return errors.map((error) => {
    if (error.name === "required") {
      error.message = "Please fill in this field";
    } else if (error.name === "format") {
      error.message = "Please enter a valid value";
    }
    return error;
  });
}

export function useMemberProfileFormConfig(props: Partial<SchemaProps>) {
  const withDefaultProps = defaults(props, {
    mode: "create",
  });


  const schema = useMemo(
    () => getMemberProfileSchema(withDefaultProps),
    [withDefaultProps]
  );

  const uiSchema = useMemo(() => {
    return getMemberProfileUISchema(withDefaultProps);
  }, [withDefaultProps]);

  return {
    schema,
    uiSchema,
    customValidate,
    transformErrors
  };
}