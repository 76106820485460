import { api } from "@/apps/common/api-client";
import PageTitle from "@/apps/common/components/PageTitle";
import Table from "@/apps/common/components/Table";
import { getFullName } from "@/apps/common/helpers";
import {
  DATE_FORMATS,
  formatDate,
  formatDateOfBirth
} from "@/apps/common/helpers/date";
import { useRequest } from "ahooks";
import { useMemo, useState } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Referrals.module.css";
import FiltersBar from "./FiltersBar";

const columns = [
  {
    Header: "Referral Created",
    accessor: "formattedCreatedAt",
    sort: true
  },
  {
    Header: "Patient name",
    accessor: "fullName",
    sort: true
  },
  {
    Header: "Patient DOB",
    accessor: "formattedDateOfBirth",
    sort: false
  },
  {
    Header: "Service type",
    accessor: "serviceType.name",
    sort: false
  },
  {
    Header: "Phone number",
    accessor: "phoneNumber",
    sort: false,
    Cell: ({ value }: { value: string }) => {
      const match = value.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return value;
    }
  },
  {
    Header: "Email",
    accessor: "email",
    sort: false
  },
  {
    Header: "Status",
    accessor: "memberService.status",
    sort: false,
    Cell: ({ value }: { value: string }) => (
      <span className={`services-status ${value || "referral-sent"}`}>
        {value || "referral-sent"}
      </span>
    )
  },
  {
    id: "action",
    Header: "Actions",
    accessor: "id",
    Cell: ({ value }: { value: string }) => (
      <Link to={`/referrals/${value}`}>
        <Button size="sm" variant="btn btn-primary rounded-pill width-sm">
          View
        </Button>
      </Link>
    )
  }
];

export default function Referrals() {
  const [filters, setFilters] = useState<any>();
  const { query, ...remainingFilters } = filters || {};
  const { data: referrals = [], loading } = useRequest(
    () =>
      api.referrals.findMany({
        query,
        filters: remainingFilters,
        populate: ["memberService", "serviceType", "state"],
        pageSize: 100
      }),
    {
      refreshDeps: [filters],
      debounceWait: 300
    }
  );

  const { data: coupons } = useRequest(() => api.common.fetchCoupons());
  // const totalCoupons = useMemo(() => coupons?.length || 0, [coupons]);
  const unusedCoupons = useMemo(
    () => coupons?.filter((c) => !c.reusable && !c.used).length ?? 0,
    [coupons]
  );

  const referralsWithName = useMemo(
    () =>
      referrals.map((referral) => {
        return {
          ...referral,
          fullName: getFullName(referral),
          formattedCreatedAt: formatDate(
            referral.createdAt,
            DATE_FORMATS.DATE_ONLY
          ),
          formattedDateOfBirth: formatDateOfBirth(referral.dateOfBirth)
        };
      }),
    [referrals]
  );

  return (
    <>
      <PageTitle breadCrumbItems={[]} title={"Member referrals"}>
        {unusedCoupons ? (
          <Alert variant="success" className="mb-0 d-md-block d-sm-none">
            You have {unusedCoupons}{" "}
            {unusedCoupons > 1 ? "referrals" : "referral"} left with 50%
            discount.
          </Alert>
        ) : null}
      </PageTitle>
      <Row className={styles.referralToolbar}>
        <Col>
          <FiltersBar values={filters} onChange={setFilters} />
        </Col>
        <Col md="auto" className="d-flex align-items-center">
          <Link
            to="/referrals/new-referrals"
            className="btn btn-primary rounded-pill mb-3 mb-md-0"
          >
            Send new referral <i className="uil uil-plus"></i>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Table
                columns={columns}
                data={referralsWithName}
                pageSize={10}
                isSortable={true}
                pagination={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
