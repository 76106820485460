import { MemberServiceType } from "@/apps/common/types/appointment-types";
import { createContext } from "react";

export type MemberServiceContextType = {
  memberService: MemberServiceType;
  refreshMemberService: () => Promise<MemberServiceType[]>;
};

export const MemberServiceContext = createContext<MemberServiceContextType>({
  memberService: {} as any,
  refreshMemberService: async () => [] as MemberServiceType[]
});
