import { FormInput, InputMasks, VerticalForm } from "@/apps/common/components/";
import PageTitle from "@/apps/common/components/PageTitle";
import { yupResolver } from "@hookform/resolvers/yup";
import FeatherIcons from "feather-icons-react";

import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { api } from "../../common/api-client";
import { MemberServiceContext } from "@/apps/common/components/contexts/MemberServiceContext";
import {
  DATE_FORMATS,
  isValidDateFormat,
  reverseFormatDateOfBirth
} from "@/apps/common/helpers/date";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "@/apps/common/store/useAuthStore";

interface MemberProfileData {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  state: number;
  spokenLanguage: string;
}

/**
 * MemberProfileCompletion
 */
export default function MemberProfileCompletion() {
  const { t } = useTranslation();

  const { states, fetchStates } = useAuthStore();

  const { memberService, refreshMemberService } =
    useContext(MemberServiceContext);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchStates();
  }, [fetchStates]);

  const defaultValues = {
    serviceProviderType: memberService.serviceProviderType
  };

  const schemaResolver = yupResolver(
    yup.object().shape({
      firstName: yup.string().required(t("Please enter First name")),
      lastName: yup.string().required(t("Please enter Last name")),
      dateOfBirth: yup
        .string()
        .required(t("Please enter Date of birth"))
        .test("is-valid-date", "Please enter date in MM/DD/YYYY", (value) => {
          return isValidDateFormat(value, DATE_FORMATS.DATE_ONLY);
        }),

      phoneNumber: yup.string().required("Please enter Phone"),
      state: yup.number().required("Please select your State"),
      spokenLanguage: yup.string().required("Please select your Language")
    })
  );

  const onSubmit = async (formData: MemberProfileData) => {
    try {
      setLoading(true);
      await api.auth.updateUser(memberService!.user!.id, {
        ...formData,
        dateOfBirth: reverseFormatDateOfBirth(formData.dateOfBirth)!,
        state: {
          id: formData.state
        }
      });
      await api.contracts.signContract("privacy-policy");
      setLoading(false);
    } catch (err) {
      console.error(err);
    }

    await refreshMemberService();
    setLoading(false);
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[{ label: "Dashboard", path: "/" }]}
        title={"Member Profile"}
      />
      <Card>
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={8} lg={8}>
            <Card.Body>
              <VerticalForm<MemberProfileData>
                onSubmit={onSubmit}
                resolver={schemaResolver}
                defaultValues={defaultValues}
                formClass="authentication-form row"
              >
                <FormInput
                  label={t("First name")}
                  type="text"
                  name="firstName"
                  startIcon={
                    <FeatherIcons icon={"user"} className="icon-dual" />
                  }
                  placeholder={t("Your first name")}
                  containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
                />
                <FormInput
                  label={t("Last name")}
                  type="text"
                  name="lastName"
                  startIcon={
                    <FeatherIcons icon={"user"} className="icon-dual" />
                  }
                  placeholder={t("Your last name")}
                  containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
                />
                <InputMasks
                  type="mask"
                  startIcon={
                    <FeatherIcons icon={"calendar"} className="icon-dual" />
                  }
                  label={t("Date of birth")}
                  mask={[
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/
                  ]}
                  placeholder="MM/DD/YYYY"
                  name="dateOfBirth"
                  containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
                />
                <InputMasks
                  type="mask"
                  startIcon={
                    <FeatherIcons icon={"phone"} className="icon-dual" />
                  }
                  label={t("Mobile Phone")}
                  mask={[
                    "(",
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/
                  ]}
                  placeholder="(___) ___-____"
                  name="phoneNumber"
                  containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
                />
                <FormInput
                  label={t("Language Spoken")}
                  type="select"
                  name="spokenLanguage"
                  key="spokenLanguage"
                  startIcon={
                    <FeatherIcons icon={"globe"} className="icon-dual" />
                  }
                  containerClass={"mb-3 col-sm-12 col-md-12 col-lg-12"}
                >
                  <option value="">Select your language</option>
                  <option value="en">English</option>
                  <option value="es">Spanish</option>
                </FormInput>
                <FormInput
                  label={t("State")}
                  type="select"
                  key="state"
                  name="state"
                  startIcon={
                    <FeatherIcons icon={"map-pin"} className="icon-dual" />
                  }
                  containerClass={`mb-2 col-sm-12 col-md-12 col-lg-12"`}
                  defaultValue={""}
                >
                  <option value="">Select your state</option>
                  {states?.map((v) => (
                    <option key={v.id} value={v.id}>
                      {v?.attributes?.name}
                    </option>
                  ))}
                </FormInput>

                <div className="mb-2 text-center d-grid col-12">
                  <Button disabled={loading} type="submit">
                    {loading ? (
                      <Spinner
                        style={{
                          height: 20,
                          width: 20,
                          marginTop: 4,
                          fontSize: 10
                        }}
                      />
                    ) : (
                      "Save and Continue"
                    )}
                  </Button>
                </div>
              </VerticalForm>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  );
}
