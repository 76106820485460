import { api } from "@/apps/common/api-client";
import PageTitle from "@/apps/common/components/PageTitle";
//import { getFullName } from "@/apps/common/helpers";
import { useRequest } from "ahooks";
import { Alert, Button, Card, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";

import MemberSessionDetails from "../../components/MemberSessionDetails";
import { useCallback } from "react";
import Markdown from "markdown-to-jsx";

const LoaderSpinner = () => (
  <Spinner
    style={{
      height: 20,
      width: 20,
      marginTop: 4,
      fontSize: 10
    }}
  />
);

export default function AppointmentAide() {
  const { id } = useParams();

  const { data: appointment, loading } = useRequest(() =>
    api.appointments.getById(Number(id))
  );

  const { data: transcripts = [] } = useRequest(
    () => api.transcripts.getByAppointment(Number(id)),
    {
      pollingInterval: 2000
    }
  );

  const startAssistant = useCallback(() => {
    api.appointments.startAssistant(Number(id));
  }, [id]);

  const startRecording = useCallback(() => {
    api.appointments.startRecording(Number(id));
  }, [id]);

  const summarize = useCallback(() => {
    api.appointments.summarize(Number(id));
  }, [id]);

  return (
    <>
      <PageTitle
        breadCrumbItems={[{ label: "Appointment report", path: "/" }]}
        title={"Session Details"}
      />

      {loading ? (
        <div className="text-center p-4 mt-4">
          <Spinner
            style={{ width: 100, height: 100 }}
            animation="border"
            variant="info"
          />
        </div>
      ) : null}

      {appointment ? (
        <>
          <Card>
            <Card.Body>
              <MemberSessionDetails appointment={appointment} />
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title as={"h4"}>Bot Actions</Card.Title>
              <Button variant="success" onClick={startAssistant}>
                Join Meeting
              </Button>
              <Button
                variant="danger"
                className="ms-2"
                onClick={startRecording}
              >
                Start Recording
              </Button>
              <Button variant="danger" className="ms-2" onClick={summarize}>
                Summarize
              </Button>
            </Card.Body>
          </Card>

          {appointment?.summary ? (
            <Card>
              <Card.Body>
                <Card.Title as={"h4"}>Summary</Card.Title>
                <Markdown>{appointment.summary}</Markdown>
              </Card.Body>
            </Card>
          ) : null}

          <Card>
            <Card.Body>
              {transcripts.map((transcript, index) => {
                const newMessage =
                  transcripts[index - 1]?.sender !== transcripts[index].sender;
                const lastMessageFromSender =
                  transcripts[index + 1]?.sender !== transcripts[index].sender;

                return (
                  <>
                    {newMessage ? (
                      <div className="mt-2">
                        <strong>{transcript.sender}</strong>:
                      </div>
                    ) : null}
                    <span key={transcript.id}>
                      {transcript.text}{" "}
                      {lastMessageFromSender && transcript.llmResponse ? (
                        <Alert variant="info">
                          <Markdown>{transcript.llmResponse}</Markdown>
                        </Alert>
                      ) : null}
                    </span>
                  </>
                );
              })}
            </Card.Body>
          </Card>
        </>
      ) : null}
    </>
  );
}
