import { InputHTMLAttributes, ChangeEvent } from "react";
import { InputGroup, Form } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import { FieldErrors } from "react-hook-form";

interface MaskInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  placeholder: string;
  startIcon?: any;
  mask: Array<string | RegExp>;
  name: string;
  containerClass: string;
  errors?: FieldErrors;
  setValue?: any;
  clearErrors?: any;
  getValues?: any;
}

const InputMasks = ({
  label,
  startIcon,
  mask,
  placeholder,
  name,
  containerClass,
  errors,
  setValue,
  clearErrors,
  value,
  getValues,
  disabled
}: MaskInputProps) => {
  return (
    <div className={containerClass}>
      <label className="form-label" htmlFor={name}>
        {label}
      </label>{" "}
      <br />
      <InputGroup>
        {startIcon && <InputGroup.Text>{startIcon}</InputGroup.Text>}
        <MaskedInput
          value={getValues()[name]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue(name, e.target.value);
            clearErrors(name);
          }}
          id={name}
          name={name}
          mask={mask}
          placeholder={placeholder}
          disabled={disabled}
          className={`form-control ${errors && errors[name] && "is-invalid"}`}
        />
      </InputGroup>
      {errors && errors[name] ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {String(errors[name]?.["message"])}
        </Form.Control.Feedback>
      ) : null}
    </div>
  );
};

export default InputMasks;
