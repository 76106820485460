import { api } from "@/apps/common/api-client";
import { CPTCodeType } from "@/apps/common/types/appointment-types";
import { useRequest } from "ahooks";
import { Card } from "react-bootstrap";
import Select from "react-select";

export default function CPTCodeSelector({
  value,
  onChange,
  disabled
}: {
  value?: CPTCodeType[];
  onChange: (value: CPTCodeType[]) => void;
  disabled?: boolean;
}) {
  const { data: cptCodeData } = useRequest(() => api.common.fetchCPTCodes(), {
    cacheKey: "cpt-codes"
  });

  //console.debug ('cpt codes ' + JSON.stringify(cptCodeData?.data.data))
  // const cptCodes = cptCodeData?.data?.data;
  // if (cptCodes?.length > 0) {
  //   for (const i in cptCodes) {
  //     //console.debug (JSON.stringify(cptCodes[i].attributes))
  //     const code = {
  //       label:
  //         cptCodes[i].attributes.code +
  //         " - " +
  //         cptCodes[i].attributes.description,
  //       value: cptCodes[i].attributes.code,
  //       descp: cptCodes[i].attributes.description,
  //       id: cptCodes[i].id
  //     };
  //     cptCodeOptions.push(code);
  //   }
  // }

  // const onChange = (value: CPTCodeOption[]) => {
  //   selectedcptCodeOptions = value;
  //   //console.debug ('ONCHANGE' + JSON.stringify(selectedcptCodeOptions))
  // };

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Diagnosis Codes. (Required for Report Submission)</Card.Title>
          <Select
            isDisabled={disabled}
            //components={{ IndicatorsContainer, MenuList }}
            onChange={(cptCodeOptions) =>
              onChange(cptCodeOptions as CPTCodeType[])
            }
            placeholder={"Diagnosis Codes"}
            options={cptCodeData!}
            maxMenuHeight={350}
            isSearchable
            isClearable
            isMulti
            name="search-app"
            className="app-search"
            classNamePrefix="react-select"
            getOptionLabel={(option) =>
              option.code + " - " + option.description
            }
            getOptionValue={(option) => String(option.id)}
            hideSelectedOptions={true}
            value={value}
          />
        </Card.Body>
      </Card>
    </>
  );
}
