import React from "react";
import ReactDOM from "react-dom/client";

import "./i18n";

import App from "./App";

import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { configureStore } from "./redux/store";
import Hotjar from "@hotjar/browser";

import "regenerator-runtime";
import config from "./config";

mixpanel.init(config.MIXPANEL_TOKEN, {
  track_pageview: false,
  persistence: "localStorage"
});

if (config.MIXPANEL_TOKEN) {
  const siteId = 4934845;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
}

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: config.SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/api\.([a-z]+\.)*skylerhealth\.com/
        ]
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.5
  });
}

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <Provider store={configureStore({})}>
    <React.Fragment>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
