import { api } from "@/apps/common/api-client";
import { ChangeEvent, FocusEvent } from "react";
import { unstrapiMany } from "@/apps/common/helpers/strapi";
import { StateType } from "@/apps/common/types/appointment-types";
import { useRequest } from "ahooks";
import FeatherIcon from "feather-icons-react";
import Select from "react-select";
import {
  FormContextType,
  enumOptionsIndexForValue,
  RJSFSchema,
  StrictRJSFSchema,
  WidgetProps,
} from "@rjsf/utils";
import { useEffect } from "react";

export default function MultiSelectorWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({
  schema,
  id,
  options,
  required,
  disabled,
  readonly,
  value,
  multiple,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  rawErrors = [],
  name
}: WidgetProps<T, S, F>) {

  const { enumOptions, enumDisabled, emptyValue: optEmptyValue } = options;
  //console.debug ('enumOptions ' + JSON.stringify(enumOptions))

  const { data: states = [] } = useRequest(
    () =>
      api.auth
        .fetchStates()
        .then((response) => unstrapiMany(response.data, "state")),
    {
      cacheKey: "states"
    }
  );

  function getValue(event: FocusEvent | ChangeEvent | any, multiple?: boolean) {
    if (multiple) {
      const a = [].slice
        .call(event.target.options as any)
        .filter((o: any) => o.selected)
        .map((o: any) => o.value);
      console.debug('getMultipleValue' + JSON.stringify(a))
      return a
    } else {
      console.debug('getSingleValue' + JSON.stringify(event.target.value))
      return event.target.value;
    }
  }
  const selectedIndexes = enumOptionsIndexForValue<S>(
    value,
    enumOptions,
    multiple
  );

  useEffect(() => {
    if (value)
      _onChange(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onChange = (newValues: any) => {

    // console.debug ('newValues -' + JSON.stringify(newValues))
    const selectedValues: number[] = [];
    newValues.forEach((element: any) => {
      selectedValues.push(element.id)
    });
    onChange(newValues)
  }

  return (
    <div className="position-relative input-group" style={{ 'width': '500' }}>
      <Select
        isMulti
        options={enumOptions}
        onChange={(newValues) => { _onChange(newValues) }}
        defaultValue={value}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => String(option.id)}
        styles={{
          control: (base) => ({
            ...base,
            borderWidth: 0,
            'width': '300px'
          }),
        }}
      // className="form-control"
      />
    </div >
  );
}
