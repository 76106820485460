import { api } from "@/apps/common/api-client";
import { PageTitle } from "@/apps/common/components";
import { formatDate, getUserTimezone } from "@/apps/common/helpers/date";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { DateSelectArg, EventClickArg } from "@fullcalendar/core/index.js";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import luxonPlugin from "@fullcalendar/luxon3";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useRequest } from "ahooks";
import clsx from "clsx";
import { parseISO } from "date-fns";
import {
  FormEventHandler,
  MouseEventHandler,
  useCallback,
  useState
} from "react";
import { Form, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ErrorBoundary } from "react-error-boundary";
import { Link } from "react-router-dom";
import styles from "../Referrals/Referrals.module.css";
//import { L } from "node_modules/@fullcalendar/list/internal-common";

interface EventData {
  start: Date;
  end: Date;
  type?: string;
}

function fallbackRender({ error }: { error: Error }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  // console.error("fallbackRender - " + JSON.stringify(error));
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

export default function Availability() {
  const { data } = useRequest(() =>
    api.common.fetchCalendar().then((res) => {
      return res.data;
    })
  );

  function downloadCalendar() {
    //console.debug("calendar -" + JSON.stringify(data) + "\n");
    const blob = new Blob([data], {
      type: "text/calendar"
    });
    const url = window.URL.createObjectURL(blob);

    return url;
  }

  const { data: timeSlots, run: refreshTimeSlots } = useRequest(
    () => api.appointments.fetchTimeSlots(),
    {
      manual: false,
      pollingInterval: 100000,
      refreshOnWindowFocus: true
    }
  );

  const { user } = useAuthStore();

  const timeZone = getUserTimezone(user);

  const [initialDate] = useState(new Date());

  const [show, setShow] = useState(false);
  const [showPastDateError, setShowPastDateError] = useState(false);

  const [loading, setLoading] = useState(false);

  const [eventData, setEventData] = useState<EventData>();


  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);

  const handleEventClick = useCallback(
    async (arg: EventClickArg) => {
      console.debug("handleEventClick - " + JSON.stringify(arg.event));
      setEventData({
        start: arg.event.start!,
        end: arg.event.end!,
        type: arg.event.extendedProps.type
      });
      handleShow();
    },
    [handleShow]
  );

  const handleSelect = useCallback(
    async (arg: DateSelectArg) => {
      //setEventData({ start: arg.start, end: arg.end });
      const start = parseISO(arg.startStr);
      const today = new Date();
      if (start < today) {
        // Alert he user that they have to select a date in the future
        setShowPastDateError (true)
        return;
      }
      setEventData({ start: arg.start, end: arg.end, type:"confirmed"});
      handleShow();
    },
    [handleShow]
  );

  const onSubmit: FormEventHandler = async (event) => {
    event.preventDefault();
    setLoading(true);

    await api.appointments.createTimeSlots({
      ...eventData!,
      tentative: (
        event.target as unknown as {
          slotType: HTMLInputElement;
        }
      ).slotType.checked
    });

    refreshTimeSlots();

    handleClose();
    setLoading(false);
  };

  const deleteSlots: MouseEventHandler = async (event) => {
    console.log("deleteSlots - " + JSON.stringify(eventData));
    event.preventDefault();
    setLoading(true);

    await api.appointments.deleteTimeSlotByRange({
      ...eventData!
    });

    refreshTimeSlots();

    handleClose();
    setLoading(false);
  };

  function toggleEventType() {
    if (eventData?.type == "tentative") {
      setEventData({ ...eventData, type: "confirmed" });
    } else if (eventData?.type == "confirmed") {
      setEventData({ ...eventData, type: "tentative" });
    }
  }

  return (
    <ErrorBoundary fallbackRender={fallbackRender}>
      <PageTitle
        breadCrumbItems={[{ label: "Availability", path: "availability" }]}
        title={"Manage Time Slots"}
      />

      {loading ? (
        <div className="text-center p-4 mt-4">
          <Spinner
            style={{ width: 100, height: 100 }}
            animation="border"
            variant="info"
          />
        </div>
      ) : null}

      <div id="calendar" className={clsx({ "d-none": loading })}>
        <div>
          <span>
            <b className={styles.leftPanel}>
              Your Profile Timezone: {timeZone}
            </b>
          </span>

          <span className={styles.rightPanel}>
            <Link
              to={downloadCalendar()}
              className="btn btn-primary rounded-pill"
              download={"my_skylerCal.ics"}
              target={"_blank"}
            >
              Download your Skyler Calendar
            </Link>
          </span>
        </div>

        <FullCalendar
          initialView="timeGridWeek"
          nowIndicator={true}
          eventClick={handleEventClick}
          plugins={[
            luxonPlugin,
            dayGridPlugin,
            interactionPlugin,
            timeGridPlugin,
            listPlugin,
            BootstrapTheme
          ]}
          handleWindowResize={true}
          themeSystem="bootstrap"
          buttonText={{
            today: "Today",
            month: "Month",
            week: "Week",
            day: "Day",
            list: "List",
            prev: "Prev",
            next: "Next"
          }}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth"
          }}
          dayMaxEventRows={1}
          selectable={true}
          events={timeSlots ?? []}
          droppable={false}
          select={handleSelect}
          timeZone={timeZone}
          // slotMinTime={"06:00:00"}
          slotMaxTime={"24:00:00"}
          height="auto"
          allDaySlot={false}
          initialDate={initialDate}
        />
        {show ? (
          <>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              centered
            >
              <Form onSubmit={onSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title as="h4">
                    Confirm or Delete your Availability
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  You are available from{" "}
                  <span className="fw-bold text-black">
                    {formatDate(eventData!.start)}
                  </span>{" "}
                  to{" "}
                  <span className="fw-bold text-black">
                    {formatDate(eventData!.end)}
                  </span>
                  <Form.Check
                    className="mt-2"
                    type="switch"
                    id="slotType"
                    label="Tentative"
                    name="slotType"
                    checked={eventData?.type === "tentative"}
                    onChange={() => {
                      toggleEventType();
                    }}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    Confirm
                  </Button>
                  <Button
                    type="submit"
                    variant="secondary"
                    onClick={deleteSlots}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
        ) : null}
        {showPastDateError ? (
          <>
            <Modal
              show={showPastDateError}
              onHide={()=>setShowPastDateError(false)}
              backdrop="static"
              keyboard={false}
              centered
            >
                <Modal.Header closeButton>
                  <Modal.Title as="h4">
                    Date Selection Error
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Please select a Future Date for providing your availability
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={()=>setShowPastDateError(false)}>
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>
          </>
        ) : null}
      </div>
    </ErrorBoundary>
  );
}
