import SelectWidget from "@/modules/react-jsonschema-form-bootstrap/SelectWidget";
import { WidgetProps } from "@rjsf/utils";
import { useRequest } from "ahooks";
import { useEffect } from "react";
import { InputGroup, Spinner } from "react-bootstrap";

type ListItem = { label: string; value: number | string };

export default function AsyncSelectWidget(props: WidgetProps) {
  const { request, requestRefreshDeps, requestCacheKey } = props.uiSchema ?? {};
  
  const { data = [], loading } = useRequest<ListItem[], any>(request, {
    refreshDeps: requestRefreshDeps,
    cacheKey: requestCacheKey
  });

  useEffect(() => {
    //console.debug ('Data - ' + JSON.stringify(data))
    //console.debug ('props.value - ' + JSON.stringify(props.value))
    if (data.length && props.uiSchema?.selectItemOnLoad) {
      if (props.value) {
        const item = data.find((item) => item.value === props.value);
        if (item) {
          return;
        }
      }

      props.onChange(data?.[0]?.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, request]);

  return loading ? (
    <InputGroup>
      <Spinner />
    </InputGroup>
  ) : (
    <SelectWidget {...props} options={{ enumOptions: data } as any} />
  );
}
