import PageTitle from "@/apps/common/components/PageTitle";
import { PaymentContext } from "@/apps/common/components/contexts/PaymentContextProvider";
import ReferralForm from "@/apps/common/components/referrals/ReferralForm";
import React, { useContext } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { useAuthStore } from "../../store/useAuthStore";
import { getPortalType } from "@/apps/common/helpers/index"
import { PortalTypes } from "@/constants/portals";
import { useCommonStore } from "@/apps/common/store/useCommonStore";
import { useRequest } from "ahooks";

export default function NewReferral() {
  const { hasPaymentAttached, requestPayment } = useContext(PaymentContext);
  const { fetchClinic } = useCommonStore();

  async function isSkylerClinic(): Promise<boolean> {
    {
      console.debug('isSkylerClinic')
      let isSkylerClinic = false;
      if (getPortalType() == PortalTypes.CLINICAL) {
        const user = await useAuthStore.getState().fetchUser();
        //console.debug ('user - ' + JSON.stringify(user))

        const b = await fetchClinic(user?.data?.id)
        const internalClinic = b?.data?.data[0]?.attributes?.clinic?.data?.attributes?.internalClinic
        console.debug('clinic ' + internalClinic)

        //console.debug ('clinicuser - ' + JSON.stringify(clinicUser[0]?.attributes?.clinic.data.attributes?.internalClinic))
        if (internalClinic === true)
          isSkylerClinic = true;
      }

      return new Promise((resolve) => {
        resolve(isSkylerClinic);
      }
      );
    }

  }

  const { data, loading } = useRequest(isSkylerClinic, {
    onSuccess: (result, params) => {
      if (result) {
        console.debug('onSunccess')
      }
    },
  });

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[{ label: "Referrals", path: "/referrals" }]}
        title={"New Referral"}
      />

      <Row>
        {!hasPaymentAttached ? (
          <Alert variant="warning">
            You don't have a card on file. If you wish to pay for this referral,{" "}
            <Button
              onClick={requestPayment}
              variant="link"
              className="p-0 fs-14"
            >
              <strong>add a card now</strong>
            </Button>
            .
          </Alert>
        ) : null}
      </Row>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body>
              <h4 className="header-title mt-0">Patient information</h4>
              <p className="sub-header">
                Please provide the following details to create a referral.
              </p>
              {!loading && (
                <ReferralForm mode="create" skylerClinic={data} />
              )
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
