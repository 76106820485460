import { api } from "@/apps/common/api-client";
import { getFullName } from "@/apps/common/helpers";
import { formatDateOfBirth } from "@/apps/common/helpers/date";
import { ReferralType } from "@/apps/common/types/appointment-types";
import { useRequest } from "ahooks";
import { useState } from "react";
import {
  Alert,
  Button,
  Col,
  FloatingLabel,
  Form,
  ListGroup,
  Modal,
  Row,
  Spinner
} from "react-bootstrap";
import { REFERRAL_STATUS } from "../../constants";
import clsx from "clsx";

type SearchExistingMemberProps = {
  show: boolean;
  onHide: () => void;
  onSelect: (member: ReferralType) => void;
};

export default function SearchExistingMember({
  show,
  onHide,
  onSelect
}: SearchExistingMemberProps) {
  const [query, setQuery] = useState<string>();
  const { data: members, loading } = useRequest(
    () =>
      api.referrals.findMany({
        query,
        filters: {
          status: query
            ? { $ne: REFERRAL_STATUS.ARCHIVED }
            : REFERRAL_STATUS.COMPLETE
        },
        populate: ["serviceType", "state", "spokenLanguage"]
      }),
    {
      refreshDeps: [query],
      debounceWait: 300
    }
  );

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Search existing member</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FloatingLabel
          controlId="floatingInput"
          label="Search by name or date of birth"
          className="mb-3"
        >
          <Form.Control
            type="email"
            placeholder="Search by name or date of birth"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
          />
        </FloatingLabel>

        {loading ? <Spinner animation="grow" /> : null}

        <ListGroup>
          {members?.map((referral) => (
            <ListGroup.Item
              className={clsx({
                "mb-2": referral.status !== "complete"
              })}
              key={referral.id}
              variant={referral.status === "complete" ? undefined : "warning"}
            >
              <Row>
                <Col>
                  <strong>{getFullName(referral)}</strong>
                  <div>{formatDateOfBirth(referral.dateOfBirth)}</div>
                </Col>
                <Col
                  md="auto"
                  className="d-flex align-items-center justify-content-center"
                >
                  {referral.status === "complete" ? (
                    <Button onClick={() => onSelect(referral)}>Select</Button>
                  ) : (
                    <span>
                      This member has a pending referral that has not been
                      completed yet. Please wait for this member to finish their
                      prior session before sending a new referral. Thank you
                    </span>
                  )}
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}
