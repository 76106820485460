import { AxiosInstance } from "axios";
import { BaseApiModule } from "./base.module";
import { unstrapiMany } from "../../helpers/strapi";
import { AdminNoteType } from "../../types/appointment-types";

export class AdminNotesApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  create(data: any) {
    return this.client
      .post("/api/admin-notes", { data })
      .then((res) => res.data);
  }

  async findAllNotes(userId: string) : Promise<AdminNoteType[]>{

    return await this.client
      .get(`api/admin-notes`, {
        params: {
          filters:{
          user: userId
          },
          sort: "createdAt:DESC"
        }
      })
      .then((res) =>        
        unstrapiMany<AdminNoteType>(res.data, "admin_note")
      );

  }

}
