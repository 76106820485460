import { AxiosInstance } from "axios";
import { unstrapiMany } from "../../helpers/strapi";
import { PaymentProfile } from "../../types/appointment-types";
import { BaseApiModule } from "./base.module";

export class PaymentsApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  async getPaymentProfile(userId: number | null = null) {

    let params;
    if (userId)
      params = {
        populate: "paymentMethods",
        filters: {
          user: userId
        },
      }
    else
      params = {
        populate: "paymentMethods",
      }

    //console.debug(JSON.stringify(params))
    const res = await this.client
      .get(`api/payment-profiles`, {
        params
      })
      .then(
        (res) => {
          //console.debug('paymentProfile - ' + JSON.stringify(res.data))
          return unstrapiMany<PaymentProfile>(res.data, "paymentProfile")?.[0]
          //console.log ('x' + JSON.stringify(x))
          //return x
        }

      );

    //console.debug ('res - ' + JSON.stringify(res))
    return res
  }

  async getPaymentMethods() {
    console.debug('getPaymentMethods')
    return await this.client.get(
      `api/payment-methods`, {
      params: {
        filters: {
          active: false
        }
      }
    }
    ).then(
      ((res) => {
        console.debug('payment-methods' + JSON.stringify(res.data))
        return res.data
      }),
      (
        (err) => {
          console.debug('payment-methods error' + JSON.stringify(err.data))
        }
      )
    )
  }

  // This is a soft delete. Setting active to false
  async deletePaymentMethod(id: string) {
    console.debug('deletePaymentMethod -' + id)
    const data = { active: false, default: false }
    return await this.client.put(
      //`api/payment-methods?filters[paymentProfile][id]=${id}`
      `api/payment-methods/${id}`, {
      data
    }
    ).then(
      ((res) => {
        console.debug('delete payment-methods' + JSON.stringify(res.data))
        return res.data
      }),
      (
        (err) => {
          console.debug('deletePaymentMethod error' + JSON.stringify(err.data))
        }
      )
    )
  }

  async updatePaymentMethod(id: string, data: any) {

    console.debug('updatePaymentMethod -' + id)

    return await this.client.put(
      //`api/payment-methods?filters[paymentProfile][id]=${id}`
      `api/payment-methods/${id}`, {
      data
    }
    )
  }

  async addPaymentMethod(data:any) {
    return await this.client.post(`api/payment-methods`, data);
  }
}
