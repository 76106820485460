export const menuItems = [
  {
    key: "appointments",
    icon: "calendar",
    label: "Appointments",
    isTitle: true,
    url: "/appointments"
  },
  {
    key: "availability",
    icon: "columns",
    label: "Availability",
    isTitle: true,
    url: "/availability"
  },
  {
    key: "billing",
    icon: "credit-card",
    label: "Billing",
    isTitle: true,
    url: "/billing"
  },
  {
    key: "referrals",
    icon: "columns",
    label: "My Members",
    isTitle: true,
    url: "/referrals"
  }
];
