import { AxiosInstance } from "axios";
import { BaseApiModule } from "./base.module";
import { unstrapiMany, unstrapiOne } from "../../helpers/strapi";
import { HealthInsuranceType } from "../../types/appointment-types";

export class HealthInsuranceApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  create(data: Partial<HealthInsuranceType>) {
    return this.client
      .post("/api/health-insurances", { data })
      .then((res) => res.data);
  }

  async find(user: number) {
    return await this.client
      .get(`/api/health-insurances`, {
        params: {
          filters: { user: {$eq:user}}
        }
      })
      .then((res) => 
        
        unstrapiMany<HealthInsuranceType>(res.data, "health_insurance")
      
      );
  }

  update(id: number, data: Partial<HealthInsuranceType>) {

  
    return this.client
      .put(`/api/health-insurances/${id}`, { data })
      .then((res) =>
        unstrapiOne<HealthInsuranceType>(res.data, "health_insurance")
      );
  }

}
