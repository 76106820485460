import { AxiosInstance } from "axios";
import { unstrapiMany, unstrapiOne } from "../../helpers/strapi";
import { AppointmentTranscriptType } from "../../types/appointment-types";
import { BaseApiModule } from "./base.module";

export class AppointmentTranscriptsApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api/appointment-transcripts");
  }

  async getByAppointment(appointment: number) {
    return this.client
      .get(this.buildPath(), {
        params: {
          filters: {
            appointment: {
              id: appointment
            }
          },
          sort: "createdAt:ASC",
          pagination: { pageSize: 100 }
        }
      })
      .then((res) =>
        unstrapiMany<AppointmentTranscriptType>(
          res.data,
          "appointment_transcript"
        )
      );
  }
}
