import config from "@/config";

export function getImageUrl(image?: string) {
  if (!image) {
    return "/src/assets/images/users/avatar-11.png";
  }

  if (image && !image.startsWith("http")) {
    image = config.API_URL + image;
  }

  return image;
}
