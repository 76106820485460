import { Button, Modal } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";


interface EditProfileModalProps {
  show: boolean;
  loading?: boolean;
  onClick: () => void;
  onHide?: () => void;
}

const EditProfileModal = ({
  show,
  onClick,
  onHide,
  loading,
}: EditProfileModalProps) => {
  return (
    <Modal className="edit-profile" show={show} centered onHide={onHide}>
      <Modal.Header closeButton={!!onHide}>
        <h4 className="modal-title">Update Profile</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <FeatherIcon size="50px" icon="settings" />
          <p className="text-muted mt-2">
          Please confirm if you wish to make the edits to the account information.
         </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
      <div className="text-md-end mb-0 mx-auto">
        <Button disabled={loading} onClick={onClick} variant="primary" className="btn me-1 btn-block" type="submit" style={{ minWidth: "80px" , maxWidth: "80px"}}>
          {loading ? "loading..." : "Save"}
        </Button>
        <Button variant="primary" className="btn btn-block" type="reset" onClick={onHide} style={{ minWidth: "80px" , maxWidth: "80px"}}>
          Cancel
        </Button>
      </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditProfileModal;
